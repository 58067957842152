import React, { useMemo } from "react";
import { Pie } from "react-chartjs-2";

const PieChart = ({ labels, count }) => {
  function convertCamelCaseToNormal(string) {
    const words = string.split(/(?=[A-Z])/).join(" ");
    return words.charAt(0).toUpperCase() + words.slice(1);
  }

  const transformedStatuses = useMemo(() => {
    // return labels?.map(transformStatus);
    return labels?.map(convertCamelCaseToNormal);
  }, [labels]);

  const generateRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const colors = Array.from({ length: count.length }, generateRandomColor);

  const data = {
    // labels: labels,
    labels: transformedStatuses,
    datasets: [
      {
        label: "Number of Developers",
        data: count,
        backgroundColor: colors,
      },
    ],
  };

  const options = {
    maintainAspectRatio: true,
    plugins: {
      legend: {
        position: "bottom", // Display legend at the bottom
      },
    },
  };

  return (
    <div className="w-full h-[60vh]">
      {labels.length > 0 && count.length > 0 && (
        <Pie data={data} options={options} />
      )}
    </div>
  );
};

export default PieChart;
