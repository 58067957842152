import React from "react";
import Allroutes from "./Routing";

function App() {
  return (
    <>
      <Allroutes />
    </>
  );
}
export default App;
