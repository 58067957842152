import React, { useEffect, useState } from "react";

import {
  Button,
  Grid,
  TextField,
  MenuItem,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  DialogContent,
} from "@mui/material";

import { getTechStacks } from "../../Redux/Slices/Lists/ListsSlice";

import { useDispatch, useSelector } from "react-redux";

import { makeStyles } from "@mui/styles";

import CloseIcon from "@mui/icons-material/Close";

import { get_filtered_client } from "../../Services/clientRequirementServices";

import { getAllManagers } from "../../Redux/Slices/IC/ICSlice";
import { setFilterParameters } from "../../Redux/Slices/ClientRequirement/ClientRequirementSlice";

const STATUS = ["OPEN", "HOT", "CLOSE", "COLD", "LOST"];

const ClientFilter = ({ setClients, open, onClose }) => {
  const useStyles = makeStyles({
    filterItem: {
      "& div": {
        borderRadius: "0.25rem",

        backgroundColor: "white",

        fontSize: "10px",
      },

      "& label": {
        fontSize: "12px !important",
      },
    },

    menuPaper: {
      height: 200,
    },
  });

  const classes = useStyles();

  const dispatch = useDispatch();

  const { searchFilters } = useSelector((state) => state?.clientRequirement);

  const { techStacks } = useSelector((store) => store?.lists);

  const { projectManagers } = useSelector((store) => store?.ICS);
  const [filterParams, setFilterParams] = useState({
    techStack: searchFilters.techStack || [],
    status: searchFilters.status || [],
    POC: searchFilters.POC || [],
    isDeleted: false, // Assuming this should always be set to false initially
  });

  useEffect(() => {
    dispatch(getTechStacks());
    dispatch(getAllManagers());
  }, []);

  const handleChangeTechStack = (event) => {
    setFilterParams({
      ...filterParams,
      techStack: event.target.value,
    });
  };

  const handleChangeStatus = (event) => {
    setFilterParams({
      ...filterParams,
      status: event.target.value,
    });
  };

  const handleChangeProjectsManager = (event) => {
    setFilterParams({
      ...filterParams,
      POC: event.target.value,
    });
  };

  const handleSubmit = async () => {
    onClose();

    const techStackParam = filterParams.techStack.join(",");
    const statusParam = filterParams.status.join(",");
    const POCParam = filterParams.POC.join(",");

    const data1 = {
      techStack: techStackParam,
      status: statusParam,
      POC: POCParam,
      isDeleted: false,
    };
    const filterParams1 = { ...filterParams, isDeleted: false };
    dispatch(setFilterParameters(filterParams1));

    const filteredClient = await get_filtered_client(data1);

    if (filteredClient?.resp?.status === 200) {
      setClients(filteredClient?.resp?.filterClient);
    } else if (!!filteredClient?.error) {
      setClients([]);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          borderRadius: "16px",
          minWidth: "25rem",
        },
      }}
    >
      <DialogTitle>
        <Grid
          container
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
            font: "Montserrat",
            fontSize: "24px",
            fontWeight: "500",
            lineHeight: "30px",
          }}
        >
          Filter
          <Button
            sx={{
              color: "black",
              fontSize: "1rem",
            }}
            onClick={onClose}
          >
            <CloseIcon sx={{ cursor: "pointer" }} onClick={onClose} />
          </Button>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              id="techStack-select"
              select
              label="Tech Stack"
              type={"text"}
              value={filterParams.techStack}
              onChange={handleChangeTechStack}
              SelectProps={{
                multiple: true,
                MenuProps: { className: classes.menuPaper },
              }}
              size="small"
              fullWidth
            >
              <MenuItem
                sx={{
                  fontSize: "12px",
                  paddingTop: "3px",
                  paddingBottom: "3px",
                }}
                key="NULL"
                value=""
              >
                Default
              </MenuItem>
              {techStacks?.map((techStack) => (
                <MenuItem
                  sx={{
                    fontSize: "12px",
                    paddingTop: "3px",
                    paddingBottom: "3px",
                  }}
                  key={techStack.name}
                  value={techStack._id}
                >
                  {techStack.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="outlined-required"
              select
              label="Status"
              type={"text"}
              value={filterParams.status}
              onChange={handleChangeStatus}
              size="small"
              SelectProps={{
                multiple: true,
                MenuProps: { className: classes.menuPaper },
              }}
              fullWidth
            >
              <MenuItem
                sx={{
                  fontSize: "12px",
                  paddingTop: "3px",
                  paddingBottom: "3px",
                }}
                key=""
                value=""
              >
                Default
              </MenuItem>
              {STATUS.map((status, index) => (
                <MenuItem
                  sx={{
                    fontSize: "12px",
                    paddingTop: "3px",
                    paddingBottom: "3px",
                  }}
                  key={index}
                  value={status}
                >
                  {status}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="outlined-required"
              select
              label="POC"
              type={"text"}
              value={filterParams.POC}
              onChange={handleChangeProjectsManager}
              size="small"
              SelectProps={{
                multiple: true,
                MenuProps: { className: classes.menuPaper },
              }}
              fullWidth
            >
              <MenuItem
                sx={{
                  fontSize: "10px",
                  paddingTop: "3px",
                  paddingBottom: "3px",
                }}
                key=""
                value=""
              >
                Default
              </MenuItem>
              {projectManagers?.map((pm) => (
                <MenuItem
                  sx={{
                    fontSize: "12px",
                    paddingTop: "3px",
                    paddingBottom: "3px",
                  }}
                  key={pm.fullname}
                  value={pm._id}
                >
                  {pm.fullName}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "space-around",
        }}
      >
        <Button
          onClick={onClose}
          color="primary"
          sx={{
            color: "#4E79FA",
            backgroundColor: "white",
            width: "130px",
            height: "36px",
            borderRadius: "8px",
            border: "3px solid #F0F0F0",
            padding: "0px 16px",
            transition: "background-color 0.3s, color 0.3s",
            "&:hover": {
              backgroundColor: "#4E79FA",
              color: "white",
            },
          }}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          color="primary"
          onClick={handleSubmit}
          sx={{
            color: "white",
            backgroundColor: "#4E79FA",
            width: "130px",
            height: "36px",
            borderRadius: "8px",
            padding: "0px 16px",
            transition: "background-color 0.3s, color 0.3s",
            "&:hover": {
              backgroundColor: "white",
              color: "#4E79FA",
              border: "3px solid #F0F0F0",
            },
          }}
        >
          Search
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ClientFilter;
