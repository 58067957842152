import React from 'react'
import "./style.css"

const Loader = () => {
  return (
    <div className='MainDiv' style={{display:"flex",justifyContent:"center",alignItems:"center" }}>


<div className="spinner"  ></div>
    </div>



  )
}

export default Loader