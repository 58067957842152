import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useForm } from "react-hook-form";
import React, { useEffect, useState } from "react";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from "react-redux";
import { addGroup, getGroups } from "../../Redux/Slices/Groups/GroupsSlice";
import { toast } from "react-toastify";
import { updateGroup } from "../../apis/GroupApi";
import { teamLead } from "../../apis/GroupApi";

const groupSchema = yup.object().shape({
  groupName: yup
    .string()
    .required("This Field is Required")
    .matches(/^([^\s]*[A-Za-z0-9]\s*)*$/, "Enter valid data "),
  user_id: yup.array().min(1, "This Field is Required"),
});

const AddGroup = ({ open, onClose, projectManagers, group, status }) => {
  const [teamlead, setTeamlead] = useState([]);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      groupName: group?.groupName,
      user_id: group?.assignedPm ? group?.assignedPm?.map((pm) => pm._id) : [],
      teamLead_id: teamlead ? teamlead?.map((data) => data?._id) : [],
    },
    resolver: yupResolver(groupSchema),
  });

  useEffect(() => {
    setValue("groupName", group?.groupName);
    setValue(
      "user_id",
      group?.assignedPm ? group?.assignedPm?.map((pm) => pm._id) : []
    );
    setValue("teamLead_id", teamlead ? teamlead?.map((data) => data?._id) : []);
    teamLead().then((data) => {
      setTeamlead(data?.data?.data);
    });
  }, [group]);

  const dispatch = useDispatch();

  const submitHandler = (data) => {
    if (group) {
      const obj = {
        ...data,
        id: group.id,
      };
      updateGroup(obj).then((res) => {
        toast("Group updated successfully");
        setTimeout(() => {
          reset();
          onClose();
          dispatch(getGroups(status));
        }, 1000);
      });
    } else {
      dispatch(addGroup(data)).then((response) => {
        if (!response?.error) {
          toast("Group created successfully");
          setTimeout(() => {
            reset();
            onClose();
            dispatch(getGroups(status));
          }, 1000);
        }
      });
    }
  };

  const closeHandler = () => {
    setValue("groupName", "");
    onClose();
  };

  return (
    <>
      <Box>
        <Dialog
          open={open}
          onClose={closeHandler}
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "400px",
              },
            },
          }}
        >
          <DialogTitle className="pt-4 pr-4 pb-1 pl-6">
            <Grid container className="justify-between text-center">
              {group ? "Edit Group" : "Add Group"}
              <Button className="font-[13px]" onClick={closeHandler}>
                <CloseIcon />
              </Button>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <Box>
              <div className="flex-col text-center mt-2">
                <TextField
                  label="Group Name"
                  name="groupName"
                  type={"text"}
                  size="small"
                  className="w-[100%] mt-4"
                  autoComplete="off"
                  {...register("groupName")}
                  defaultValue={group?.groupName || ""}
                  error={errors.groupName ? true : false}
                  helperText={errors.groupName?.message}
                />
                <Autocomplete
                  options={projectManagers || []}
                  getOptionLabel={(item) => item?.fullName}
                  size="small"
                  onChange={(event, value) =>
                    setValue(
                      "user_id",
                      value?.map((item) => item?._id)
                    )
                  }
                  defaultValue={group?.assignedPm ? group?.assignedPm : []}
                  disableCloseOnSelect={true}
                  filterSelectedOptions
                  multiple
                  className="w-[100%] mt-4"
                  isOptionEqualToValue={(option, compare) => {
                    return option._id === compare._id;
                  }}
                  renderInput={(params) => (
                    <div>
                      <TextField
                        fullWidth
                        required
                        {...params}
                        name="user_id"
                        label="Project Manager"
                        error={errors.user_id ? true : false}
                        helperText={errors?.user_id?.message}
                      ></TextField>
                    </div>
                  )}
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={option._id}>
                        {option?.fullName}
                      </li>
                    );
                  }}
                />
              </div>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              onClick={handleSubmit(submitHandler)}
              className="text-white !bg-[#4E79FA] mr-2"
            >
              {group ? "Save" : "Add"}
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  );
};

export default AddGroup;
