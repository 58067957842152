import React, { useState } from "react";
import { Box, Button, IconButton, Tooltip, Typography } from "@mui/material";
import "../../Assets/CSS/Project/Project.css";
import "../../Assets/CSS/IC/IC.css";

import ActionDialog from "./Components/ActionDialog";
import { useEffect } from "react";
import { DataGrid, GridMoreVertIcon } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import EditIC from "./Components/EditIC";
import { Link } from "react-router-dom";
import moment from "moment";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { makeStyles } from "@mui/styles";
import LoadingSpinner from "../../utils/LoadingSpinner";
import { getAllIC, removeIC } from "../../apis/ICApi";
import CreateICModal from "./Components/CreateICModal";
import ConfirmationDialog from "../../utils/ConfirmationDialog";
import { getAllIC as relaod } from "../../Redux/Slices/IC/ICSlice";
import IcFilter from "./Components/IcFilter";

const ICManagement = () => {
  const { error, isLoading } = useSelector((state) => state?.project);
  const [pageSize, setPageSize] = useState(10);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [user, setUser] = useState(null);
  const [openRemoveDialog, setOpenRemoveDialog] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState("");
  const [isLoadingg, setIsLoading] = useState(true);
  const [openActionModal, setOpenActionModal] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const useStyles = makeStyles({
    filterField: {
      backgroundColor: "white",
      width: "200px",
    },
    actionColumn: {
      left: "0",
      zIndex: 10,
      backgroundColor: "white",
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.8)",
    },

    actionHeader: {
      position: "sticky !important",
      left: "0",
      zIndex: 10,
      backgroundColor: "#FAFAFA",
      color: "black",
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.8)",
    },
  });

  const classes = useStyles();

  const dispatch = useDispatch();
  const [openEditIC, setopenEditIC] = useState(false);

  const [ICId, setICId] = useState("");

  const [developerData, setDeveloperData] = useState([]);

  const [filterData, setFilterData] = useState([]);
  const [payloadSelected, setpayloadSelected] = useState({});

  const [clientName, setClientName] = useState("");

  const findIC = async (id) => {
    try {
      const res = await getAllIC(payloadSelected);
      if (res) {
        setDeveloperData(res.data);
        if (
          Object.values(payloadSelected).length == 0 &&
          Object.keys(payloadSelected) == 0
        ) {
          setFilterData(res.data);
        }
        setIsLoading(false);
      }
    } catch (error) {
      // console.log("error", error);
    }
  };
  const formatedTime = (timeeeee) => {
    const date = new Date(timeeeee);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    let period = "AM";
    let formattedHours = hours;
    if (hours >= 12) {
      period = "PM";
      formattedHours = hours % 12;
      if (formattedHours === 0) {
        formattedHours = 12;
      }
    }
    const formattedTime = `${formattedHours
      .toString()
      .padStart(2, "0")}:${minutes.toString().padStart(2, "0")} ${period}`;
    return formattedTime;
  };

  const deleteHandler = () => {
    setOpenRemoveDialog(true);
    setConfirmMessage("Are you sure you want to remove ?");
    setIsSuccess(false);
  };

  const editHandler = async (e) => {
    e.stopPropagation(); // don't select this row after clicking

    setopenEditIC(true);
  };

  useEffect(() => {
    findIC();
  }, [dispatch, isSuccess]);

  const columns = [
    {
      field: "clientName",
      headerName: "Client name",
      headerAlign: "left",
      width: 200,
      renderCell: (params) => {
        const clientName =
          params?.row?.clientName === "" ? "N/A" : params?.row?.clientName;
        return (
          <Tooltip title={clientName} placement="right" arrow>
            <span
              style={{
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
                cursor: "context-menu",
              }}
            >
              {clientName}
            </span>
          </Tooltip>
        );
      },
    },
    {
      field: "developer",
      headerName: "Developer name",
      headerAlign: "left",
      width: 200,

      renderCell: (params) => {
        // console.log(params, "xyz");
        const developer =
          params?.row?.developer === "" ? "N/A" : params?.row?.developer;
        // return ""
        return (
          <Tooltip title={developer} placement="right" arrow>
            <span
              style={{
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
                cursor: "context-menu",
              }}
            >
              {developer}
            </span>
          </Tooltip>
        );
      },
    },
    {
      field: "techStack",
      headerName: "Tech Stack",
      headerAlign: "left",
      width: 200,
      renderCell: (params) => {
        const techStack =
          params?.row?.techStack === "" ? "N/A" : params?.row?.techStack;
        // return ""
        return (
          <Tooltip title={techStack} placement="right" arrow>
            <span
              style={{
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
                cursor: "context-menu",
              }}
            >
              {techStack}
            </span>
          </Tooltip>
        );
      },
    },

    {
      field: "scheduledBy",
      headerName: "Scheduled By",
      width: 150,
      renderCell: (params) => {
        const scheduledBy =
          params?.row?.scheduledBy === "" ? "N/A" : params?.row?.scheduledBy;
        // return ""
        return (
          <Tooltip title={scheduledBy} placement="right" arrow>
            <span
              style={{
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
                cursor: "context-menu",
              }}
            >
              {scheduledBy}
            </span>
          </Tooltip>
        );
      },
    },

    {
      field: "developerType",
      headerName: "Developer Type",
      width: 150,
      renderCell: (params) => {
        const developerType =
          params?.row?.developerType === ""
            ? "N/A"
            : params?.row?.developerType;
        // return ""
        return (
          <Tooltip title={developerType} placement="right" arrow>
            <span
              style={{
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
                cursor: "context-menu",
              }}
            >
              {developerType}
            </span>
          </Tooltip>
        );
      },
    },
    {
      headerName: "Action",
      width: 200,
      headerAlign: "center",
      align: "center",
      sortable: false,
      headerClassName: classes.actionHeader,
      cellClassName: classes.actionColumn,

      renderCell: (params) => {
        return (
          <div>
            <Tooltip title="Perform Action" placement="top">
              <IconButton
                onClick={() => {
                  setUser(params?.row?.key);
                  setICId(params?.row?.key);
                  setOpenActionModal(true);
                }}
                size="small"
                sx={{
                  marginLeft: "auto",
                }}
              >
                <GridMoreVertIcon />
              </IconButton>
            </Tooltip>
          </div>
        );
      },
    },
    {
      field: "iCSlot",
      headerName: "IC Slot",
      width: 150,
      renderCell: (params) => {
        const iCSlot =
          params?.row?.iCSlot === "" || params?.row?.iCSlot === undefined
            ? "N/A"
            : formatedTime(params?.row?.iCSlot);

        return (
          <Tooltip title={iCSlot} placement="right" arrow>
            <span
              style={{
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
                cursor: "context-menu",
              }}
            >
              {iCSlot}
            </span>
          </Tooltip>
        );
      },
    },

    {
      field: "iCDate",
      headerName: "IC Date",
      width: 150,
      renderCell: (params) => {
        const iCDate = params?.row?.iCDate === "" ? "N/A" : params?.row?.iCDate;
        return (
          <Tooltip title={iCDate} placement="right" arrow>
            <span
              style={{
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
                cursor: "context-menu",
              }}
            >
              {moment(iCDate).format("L")}
            </span>
          </Tooltip>
        );
      },
    },

    {
      field: "remarks",
      headerName: "Remarks",
      width: 150,
      renderCell: (params) => {
        const remarks =
          params?.row?.remarks === undefined ? "N/A" : params?.row?.remarks;
        // return ""
        return (
          <Tooltip title={remarks} placement="right" arrow>
            <span
              style={{
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
                cursor: "context-menu",
              }}
            >
              {remarks}
            </span>
          </Tooltip>
        );
      },
    },

    {
      field: "feedBack",
      headerName: "FeedBack",
      width: 150,
      renderCell: (params) => {
        const feedBack =
          params?.row?.feedBack == undefined
            ? "Pending"
            : params?.row?.feedBack;
        // return ""
        return (
          <Tooltip title={feedBack} placement="right" arrow>
            <span
              style={{
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
                cursor: "context-menu",
              }}
            >
              {feedBack}
            </span>
          </Tooltip>
        );
      },
    },
  ];

  const rows =
    developerData?.length > 0 &&
    developerData?.map((item) => {
      return {
        key: item?._id,
        developer: item?.developer,
        clientName: item?.clientName,
        scheduledBy: item?.scheduledBy,
        developerType: item?.developerType,
        iCSlot: item?.iCSlot,
        iCDate: item?.iCDate,
        remarks: item?.remarks,
        feedBack: item?.feedBack,
        techStack: item?.techStack,
      };
    });

  const handleCloseEditICDialog = () => {
    setopenEditIC(false);
  };

  const handleCloseRemoveDialog = () => {
    setOpenRemoveDialog(false);
  };

  const handleRemoveMember = async () => {
    try {
      const response = await removeIC(user);
      if (response) {
        toast("Member deleted successfully");
        dispatch(relaod());
        setIsSuccess(!isSuccess);
      }
    } catch (e) {
      toast(error.msg);
    }
  };

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <>
      <Box class="h-[85vh] bg-white py-2.5 px-8 rounded-[16px]">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Typography
              variant="h5"
              class="font-montserrat text-[32px] font-semibold"
            >
              IC Management
            </Typography>
          </div>
          <div class="flex gap-4 mr-8">
            <Link to="/dashboard/Icmanagement/reports">
              <Button variant="contained" className="report-button">
                Reports
              </Button>
            </Link>

            <Button
              variant="contained"
              className="report-button"
              onClick={handleOpenModal}
            >
              Create IC
            </Button>
          </div>
        </div>
        <div>
          <IcFilter
            developerData={developerData}
            setDeveloperData={setDeveloperData}
            filterData={filterData}
            setFilterData={setFilterData}
            setpayloadSelected={setpayloadSelected}
            setClientName={setClientName}
            clientName={clientName}
          />
        </div>

        {isLoadingg ? (
          <LoadingSpinner />
        ) : (
          <Box class="w-[100%] h-[80%]">
            <DataGrid
              rows={rows}
              getRowId={(row) => row?.key}
              columns={columns}
              pageSize={pageSize}
              rowHeight={40}
              pagination
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[10, 25, 50, 100]}
              experimentalFeatures={{ newEditingApi: true }}
              disableColumnMenu
              disableColumnFilter
              sx={{
                ".MuiDataGrid-columnSeparator": {
                  display: "none",
                },
                "&.MuiDataGrid-root": {
                  border: "none",
                },
                backgroundColor: "white",
                "& .MuiDataGrid-columnHeaders": {
                  background: "#FAFAFA",
                  color: "black",
                },
                "& .MuiDataGrid-sortIcon": {
                  color: "white",
                },
                "& 	.MuiDataGrid-menuIconButton": {
                  color: "white",
                },
                "& 	.MuiDataGrid-filterIcon": {
                  color: "white",
                },
              }}
              componentsProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
              filterModel={{
                items: [
                  {
                    columnField: "clientName",
                    operatorValue: "contains",
                    value: `${clientName}`,
                  },
                ],
              }}
            />
          </Box>
        )}
        {openEditIC && (
          <EditIC
            open={openEditIC}
            id={ICId}
            setIsSuccess={setIsSuccess}
            isSuccess={isSuccess}
            onClose={handleCloseEditICDialog}
          />
        )}
        {openRemoveDialog && (
          <ConfirmationDialog
            open={openRemoveDialog}
            message={confirmMessage}
            onClose={handleCloseRemoveDialog}
            onConfirm={handleRemoveMember}
            isSuccess={isSuccess}
          />
        )}
        {isModalOpen && (
          <CreateICModal
            open={isModalOpen}
            onClose={handleCloseModal}
            setIsSuccess={setIsSuccess}
            isSuccess={isSuccess}
          >
            {/* Content for the modal */}
            <div>Create IC Component</div>
          </CreateICModal>
        )}
        {openActionModal && (
          <ActionDialog
            open={openActionModal}
            onClose={() => setOpenActionModal(false)}
            editHandler={editHandler}
            deleteHandler={deleteHandler}
          />
        )}
      </Box>
    </>
  );
};
export default ICManagement;
