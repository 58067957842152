import {
  utilisationHistory,
  utilisationDownload,
} from "../../../Redux/Slices/Lists/ListsSlice";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "antd";
import { useEffect, useState } from "react";
import dayjs from "dayjs";

import { getGroups } from "../../../Redux/Slices/Lists/ListsSlice";
import Reports from "./Reports";
import { debounce } from "lodash";
import { toast } from "react-toastify";

const UtilizationModal = () => {
  const dispatch = useDispatch();
  const { utilisation, groups, searchFilters } = useSelector(
    (store) => store.lists
  );
  const [endDate, setEndDate] = useState(
    dayjs().endOf("week").add(1, "day").format("YYYY-MM-DD")
  );
  const [startDate, setStartDate] = useState(
    dayjs().endOf("week").subtract(54, "days").format("YYYY-MM-DD")
  );
  const [name, setName] = useState("");
  const showUtilisationFilter = true;

  const handleSearch = debounce((name) => {
    const formattedStartDate = dayjs(startDate).format("YYYY-MM-DD");
    const formattedStartISO = new Date(formattedStartDate).toISOString();
    const formattedEndDate = dayjs(endDate).format("YYYY-MM-DD");
    const formattedEndDateISO = new Date(formattedEndDate).toISOString();
    dispatch(
      utilisationHistory({
        startDate: formattedStartISO,
        endDate: formattedEndDateISO,
        name: name,
        group: searchFilters,
      })
    );
  }, 800);

  function convertCamelCaseToNormal(string) {
    const words = string.split(/(?=[A-Z])/).join(" ");
    return words.charAt(0).toUpperCase() + words.slice(1);
  }


  const handleDownload = () => {
    dispatch(utilisationDownload({ startDate, endDate, name })).then(
      (response) => {
        toast(response.payload.message);
      }
    );
    toast.success("Downloading CSV file");
  };

  useEffect(() => {
    const formattedStartDate = dayjs(startDate).format("YYYY-MM-DD");
    const formattedStartISO = new Date(formattedStartDate).toISOString();
    const formattedEndDate = dayjs(endDate).format("YYYY-MM-DD");
    const formattedEndDateISO = new Date(formattedEndDate).toISOString();

    dispatch(
      utilisationHistory({
        startDate: formattedStartISO,
        endDate: formattedEndDateISO,
        name: name,
      })
    );
    if (groups) {
      dispatch(getGroups());
    }
  }, [endDate, searchFilters]);

  const handleDateChange = (value) => {
    const endOfWeek = dayjs(value).endOf("week");
    const endOfSunday = endOfWeek.add(1, "day");

    setEndDate(endOfSunday.format("YYYY-MM-DD"));
    const startDate = endOfSunday
      .clone()
      .subtract(55, "days")
      .format("YYYY-MM-DD");

    setStartDate(startDate);
  };

  const rows =
    utilisation &&
    utilisation.map((user) => {
      return {
        fullName: user.fullName,
        group: user.group,
        status: user.isAvailable,
        resourceType: user.billingType,
        fullTimeProject: user.fullTimeProject,
        partTimeProjects: user.partTimeProjects,
        week1: user.bandwidthData[0],
        week2: user.bandwidthData[1],
        week3: user.bandwidthData[2],
        week4: user.bandwidthData[3],
        week5: user.bandwidthData[4],
        week6: user.bandwidthData[5],
        week7: user.bandwidthData[6],
        week8: user.bandwidthData[7],
        week9: user.bandwidthData[8],
      };
    });

  const color = (text) => {
    const common = {
      padding: "5px",
      borderRadius: "14px",
      font: "Lato",
      fontWeight: "500",
      fontSize: "16px",
      textAlign: "center",
      width: "9rem",
    };

    if (text === "0-25%") {
      return { color: "white", backgroundColor: "#FF8282", ...common };
    }

    if (text === "25-50%") {
      return { color: "white", backgroundColor: "#FFC284", ...common };
    }
    if (text === "50-75%") {
      return { color: "black", backgroundColor: "#FFF9C6", ...common };
    }
    if (text === "75-100%") {
      return { color: "white", backgroundColor: "#74D9A1", ...common };
    }
    if (text === "Above 100%") {
      return { color: "white", backgroundColor: "#800000", ...common };
    }
    if (text === "Joined this Week") {
      return { color: "white", backgroundColor: "#A2C4FF", ...common };
    }
    if (text === "Not Filled") {
      return { color: "white", backgroundColor: "#CCCCCC", ...common };
    }
    if (text === "On Leave") {
      return { color: "white", backgroundColor: "#00E600", ...common };
    }
  };

  const data =
    utilisation &&
    utilisation.length > 0 &&
    utilisation[0]?.bandwidthData.map((e, i) => {
      let startDate = new Date(e?.weekStartDate);
      let endDate = new Date(e?.weekEndDate);
      let week = dayjs(startDate).format("wo");

      let startDateTitle = startDate.toLocaleString("en-IN", {
        timeZone: "UTC",
        day: "2-digit",
        month: "short",
      });
      let endDateTitle = endDate.toLocaleString("en-IN", {
        timeZone: "UTC",
        day: "2-digit",
        month: "short",
      });

      return {
        title: `Week ${week} (${startDateTitle + " to " + endDateTitle})`,
        dataIndex: `week${i + 1}`,
        width: 215,
        render: (text, record) => {
          const startDate = dayjs(text?.weekStartDate).format("DD-MMM");
          const endDate = dayjs(text?.weekEndDate).format("DD-MMM");

          return (
            <Tooltip title={text?.bandwidth} placement="right" arrow>
              <p style={color(text?.bandwidth)}>{text?.bandwidth}</p>
            </Tooltip>
          );
        },
      };
    });

  let columns = [
    {
      title: "Full Name",
      dataIndex: "fullName",
      width: 150,
      ellipsis: true,
      render: (text) => (
        <Tooltip title={text} placement="right" arrow>
          <span>{text}</span>
        </Tooltip>
      ),
      fixed: "left",
    },
    {
      title: "Group",
      dataIndex: "group",
      width: 150,
      ellipsis: true,
      render: (text) => (
        <Tooltip title={text} placement="right" arrow>
          <span>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      width: 150,
      render: (text) => (
        <Tooltip title={convertCamelCaseToNormal(text)} placement="right" arrow>
          <span>{convertCamelCaseToNormal(text)}</span>
        </Tooltip>
      ),
    },
    {
      title: "Resource Type",
      dataIndex: "resourceType",
      width: 150,
      render: (text) => (
        <Tooltip title={text} placement="right" arrow>
          <span>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: "Full Time Projects",
      dataIndex: "fullTimeProject",
      width: 200,
      ellipsis: true,
      render: (text) => (
        <Tooltip title={text} placement="right" arrow>
          <span>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: "Part Time Projects",
      dataIndex: "partTimeProjects",
      width: 200,
      ellipsis: true,
      render: (text) => (
        <Tooltip title={text} placement="right" arrow>
          <span>{text}</span>
        </Tooltip>
      ),
    },
  ];

  if (data) {
    columns = [...columns, ...data];
  }

  return (
    <Reports
      handleDateChange={handleDateChange}
      columns={columns}
      rows={rows}
      handleSearch={handleSearch}
      title="Developer Utilisation"
      handleDownload={handleDownload}
      searchPlaceholder="Search Developer Name"
      picker="week"
      setName={setName}
      name={name}
      showUtilisationFilter={showUtilisationFilter}
    />
  );
};

export default UtilizationModal;
