import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { getTimesheetFilledDates } from "../../Redux/Slices/Timesheet/TimesheetSlice";
import "../../Assets/CSS/Timesheet/Timesheet.css";
import dayjs from "dayjs";

const CustomDatePicker = ({
  selectedDay,
  handleDateChange,
  userId,
  startDate,
  endDate,
  projectId,
  changeState,
}) => {
  const { getFilledDates } = useSelector((store) => store.timesheet);
  const dispatch = useDispatch();

  useEffect(() => {
    if (userId && projectId) {
      dispatch(
        getTimesheetFilledDates({
          userId,
          startDate,
          endDate,
          projectId,
        })
      );
    }
  }, [userId, projectId, changeState]);

  const greenCircleDates = getFilledDates?.filledDaysDates || [];
  const redCircleDates = getFilledDates?.missingDaysDates || [];
  const greyCircleDates = getFilledDates?.hoildaysWeekendsDates || [];

  const renderCustomDate = (date) => {
    const dateString = dayjs(date).format("YYYY-MM-DD");

    if (greenCircleDates.includes(dateString)) {
      return "green-circle";
    }

    if (redCircleDates.includes(dateString)) {
      return "red-circle";
    }

    if (greyCircleDates.includes(dateString)) {
      return "grey-circle";
    }

    return null;
  };

  return (
    <div className="self-center pr-3">
      <div className="relative">
        <DatePicker
          selected={selectedDay}
          onChange={(date) => {
            handleDateChange(date);
          }}
          dateFormat="yyyy-MM-dd"
          placeholderText="Select a date"
          className="rounded outline-red-400 cursor-pointer px-8 py-2 mt-3"
          renderDayContents={(day, date) => {
            const customClassName = renderCustomDate(date);
            return <div class={customClassName}>{day}</div>;
          }}
        />

        <FontAwesomeIcon
          icon={faCalendarAlt}
          className="text-gray-500 absolute top-1/2 right-3 transform -translate-y-1/2 pointer-events-none mt-1"
        />
      </div>
    </div>
  );
};

export default CustomDatePicker;
