import {
  getDepartedDevelopersDetails,
  downloadDepartedDevelopersDetails,
} from "../../../Redux/Slices/Lists/ListsSlice";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "antd";
import { useEffect, useState } from "react";

import { getGroups } from "../../../Redux/Slices/Lists/ListsSlice";
import Reports from "./Reports";
import { debounce } from "lodash";
import dayjs from "dayjs";
import { toast } from "react-toastify";

const DepartedDeveloperReport = () => {
  const dispatch = useDispatch();
  const { groups, searchFilters, departedDevelopersDetails } = useSelector(
    (store) => store.lists
  );
  const [name, setName] = useState("");
  const [showDepartedDeveloperFilter, setShowDepartedDeveloperFilter] =
    useState(true);

  const handleSearch = debounce((name) => {
    // console.log("handleSearch");
  }, 800);

  const handleDownload = () => {
    dispatch(downloadDepartedDevelopersDetails()).then((response) => {
      toast.success(response.payload.message);
    });
    toast.success("Downloading CSV file");
  };

  useEffect(() => {
    dispatch(getDepartedDevelopersDetails());
    if (groups) {
      dispatch(getGroups());
    }
  }, [searchFilters]);

  const rows =
    departedDevelopersDetails &&
    departedDevelopersDetails.map((user) => {
      let updatedDepartureOn = "NA";
      if (user.departureOn !== "") {
        updatedDepartureOn = dayjs(user.departureOn).format("DD-MM-YYYY");
      }
      let updatedjoiningDate = dayjs(user.joiningDate).format("DD-MM-YYYY");
      return {
        fullName: user?.fullName,
        joiningDate: updatedjoiningDate,
        departureOn: updatedDepartureOn,
        seniority: user?.seniority,
        location: user?.location,
        designation: user?.designation,
        email: user?.email,
      };
    });

  let columns = [
    {
      title: "Developer Name",
      dataIndex: "fullName",
      width: 150,
      align: "center",
      ellipsis: true,
      render: (text) => (
        <Tooltip title={text} placement="right" arrow>
          <span>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: "Joining Date",
      dataIndex: "joiningDate",
      width: 150,
      ellipsis: true,
      render: (text) => (
        <Tooltip title={text} placement="right" arrow>
          <span>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: "Departure Date",
      dataIndex: "departureOn",
      width: 150,
      ellipsis: true,
      render: (text) => (
        <Tooltip title={text} placement="right" arrow>
          <span>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: "Seniority",
      dataIndex: "seniority",
      width: 150,
      ellipsis: true,
      render: (text) => (
        <Tooltip title={text} placement="right" arrow>
          <span>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: "Location",
      dataIndex: "location",
      width: 150,
      ellipsis: true,
      render: (text) => (
        <Tooltip title={text} placement="right" arrow>
          <span>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: "Designation",
      dataIndex: "designation",
      width: 150,
      ellipsis: true,
      render: (text) => (
        <Tooltip title={text} placement="right" arrow>
          <span>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      width: 150,
      ellipsis: true,
      render: (text) => (
        <Tooltip title={text} placement="right" arrow>
          <span>{text}</span>
        </Tooltip>
      ),
    },
  ];

  return (
    <Reports
      columns={columns}
      rows={rows}
      handleSearch={handleSearch}
      title="Departed Developers Report"
      handleDownload={handleDownload}
      searchPlaceholder="Search Developer Name"
      setName={setName}
      name={name}
      showDepartedDeveloperFilter={showDepartedDeveloperFilter}
    />
  );
};

export default DepartedDeveloperReport;
