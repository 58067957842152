import React, { useEffect, useState } from "react";
import { Form, Input, DatePicker, Button, TimePicker, Modal } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import { editTaskSheet, createTimesheet } from "../../apis/TimesheetApi";
import dayjs from "dayjs";
import api from "../../apiclient";
import { useLocation } from "react-router-dom";
import { message } from "antd";
import { BASE_URL } from "../../constant";

const { RangePicker } = DatePicker;

const SliderModal = ({
  isFilled,
  setIsFilled,
  editMode,
  selectedProjectName,
  handleSidebarClose,
  addList,
  listTodo,
  setListTodo,
  saveTimeForTask,
  onTimeFieldChange,
  form,
  Todoinput,
  TodoList,
  deleteListItem,
  open,
  setOpen,
  editTasksData,
  addTaskByPm,
  setAddTaskByPm,
  developerId,
  setChangeState,
  setUpdateTimesheet,
  isTimesheetPm,
  taskSheet,
  setTaskSheet,
  timesheetFilledDirectly,
}) => {
  const [dateForTaskAdd, setdateForTaskAdd] = useState(
    `${new Date().getFullYear()}-${
      new Date().getMonth() + 1
    }-${new Date().getDate()}`
  );

  const [projectIdAddTask, setProjectIdAddTask] = useState("");

  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    const projectId = searchParams.get("projectId");

    setProjectIdAddTask(projectId);
  }, [location]);

  function extractDateFromUTC(date) {
    const offset = date.getTimezoneOffset() * 60000;

    const localDate = new Date(date - offset);

    const formattedDate = localDate.toISOString().split("T")[0];

    return formattedDate;
  }

  function extractData(a, b) {
    listTodo = [{ name: a, time: b }];

    handleSubmitTask();
  }

  async function handleSubmitTask() {
    const searchParams = new URLSearchParams(location.search);

    const projectId = searchParams.get("projectId");

    if (listTodo.length > 1 && listTodo[0]?.name == "On Leave") {
      listTodo.shift();
    } else if (listTodo.length < 1) {
      return;
    }

    const payload = listTodo
      ?.map((task) => {
        if (task.name !== "") {
          return {
            name: task.name.charAt(0).toUpperCase() + task.name.slice(1),
            time: task.time,
          };
        }
        return null; // or simply omit the return statement for elements you want to filter out
      })
      .filter(Boolean); // This filters out elements that are null or undefined

    let date = new Date(editTasksData?.date || dateForTaskAdd);

    const dateWithoutTimestamp = extractDateFromUTC(date);

    const finalPayload = {
      date: dateWithoutTimestamp,

      task: payload,
    };

    if (addTaskByPm) {
      const res = await api.post(
        `${BASE_URL}/api/v2/timesheet/createTimesheetByPm`,

        {
          userId: developerId,

          projectId: projectId,

          date: finalPayload.date,

          task: finalPayload?.task,
        }
      );

      // setUpdateTimesheet(true);

      setChangeState(true);

      setAddTaskByPm(false);
    } else {
      try {
        if (editTasksData?.id) {
          const editResponse = await editTaskSheet(
            editTasksData?.id,

            finalPayload
          );

          setUpdateTimesheet(true);
          setChangeState(true);
        } else {
          const editResponse = await createTimesheet({
            projectName: selectedProjectName,

            projectId: editTasksData.projectId,

            ...finalPayload,
          });

          setUpdateTimesheet(true);
          setChangeState(true);
          message.success("Timesheet edit successfully!");
        }
      } catch (error) {
        // console.log("SOME error white updating", error);
      }
    }

    if (isTimesheetPm === true) {
      message.success("Timesheet Edited Successfully!");

      setChangeState(true);
    }
    if (timesheetFilledDirectly) {
      setListTodo([]);
      message.success("Timesheet Filled Successfully!");
    }
    handleSidebarClose();
  }

  return (
    <div>
      <div
        style={{
          position: "fixed",
          top: 0,
          right: 0,
          height: "100%",
          backgroundColor: "white",
          zIndex: 999,
          boxShadow: "0 2px 5px rgba(0, 0, 0, 0.4)",
        }}
      >
        <div className="flex justify-between items-center bg-black">
          <div
            style={{
              backgroundColor: "#555555",

              height: "fit-content",
            }}
          >
            <p
              style={{
                color: "white",

                padding: "1rem 1rem",
              }}
            >
              {selectedProjectName.toUpperCase()}
            </p>
          </div>

          <div>
            <div
              className="pb-3 text-white pr-4"
              style={{ fontSize: "1.5rem" }}
            >
              <CloseCircleOutlined onClick={handleSidebarClose} />
            </div>
          </div>
        </div>

        <div style={{ padding: "20px 30px" }}>
          <Form layout="vertical" form={form}>
            <Form.Item label="Date" name="date">
              <DatePicker
                style={{
                  width: 350,

                  height: 40,

                  border: "1px solid rgb(161, 158, 158)",

                  backgroundColor: "white",

                  color: "black",
                }}
                defaultValue={
                  editTasksData?.date
                    ? dayjs(editTasksData?.date?.split("T")[0], "YYYY-MM-DD")
                    : dayjs(
                        new Date().toISOString().split("T")[0],
                        "YYYY-MM-DD"
                      )
                }
                placeholder="Date"
                value={dateForTaskAdd}
                disabled={editMode}
                onChange={(e) => {
                  setdateForTaskAdd(
                    `${e?.$d?.getFullYear()}-${
                      e?.$d?.getMonth() + 1
                    }-${e?.$d?.getDate()}`
                  );
                }}
              />
            </Form.Item>

            <div
              className="main-container"
              style={{ backgroundColor: "white" }}
            >
              <Form.Item name="taskName">
                <div className="center-container  ">
                  <Todoinput
                    addList={addList}
                    setIsFilled={setIsFilled}
                    id={editTasksData?.id || ""}
                    extract={extractData}
                  />

                  <hr />
                  <div style={{ maxHeight: "50vh", overflowY: "auto" }}>
                    {listTodo &&
                      listTodo?.map((listItem, index) => (
                        <TodoList
                          key={listItem.name}
                          item={listItem}
                          id={editTasksData?.id || ""}
                          deleteItem={deleteListItem}
                          className="bg-red-300"
                          index={index}
                          setTaskSheet={setTaskSheet}
                          taskSheet={taskSheet}
                        />
                      ))}
                  </div>
                </div>
              </Form.Item>

              <div className="flex justify-end" style={{ width: "25rem" }}>
                <Button
                  className="bg-black text-white"
                  size="large"
                  onClick={handleSubmitTask}
                  disabled={!isFilled}
                >
                  Submit
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </div>

      {open && (
        <Modal
          open={open}
          onOk={saveTimeForTask}
          onCancel={() => setOpen(false)}
        >
          <Input type="text" onChange={onTimeFieldChange} />
        </Modal>
      )}
    </div>
  );
};

export default SliderModal;
