import api from '../apiclient';

 

 

export const get_all_client = async (creds = {}) => {

    let endpoint = 'api/v2/clientRequirement/all';

    try {

        const { data } = await api.get(`${endpoint}`, { params: { ...creds } });

        return data;

    } catch (e) {

        return {

            error: "Something went wrong",

        };

    }

};

 

 

export const get_filtered_client = async (creds = {}) => {

    let endpoint = 'api/v2/clientRequirement/filter';

    try {

        const { data } = await api.get(`${endpoint}`, { params: { ...creds } });

        return data;

    } catch (e) {

        return {

            error: "Something went wrong",

        };

    }

};

 

export const search_client = async (creds = {}) => {

    let endpoint = 'api/v2/clientRequirement/searchAllClientNames';

    try {

        const { data } = await api.post(`${endpoint}`, { ...creds } );

        return data;

    } catch (e) {

        return {

           

            error: "Something went wrong",

        };

    }
};
export const get_summary = async (creds = {}) => {

    let endpoint = 'api/v2/clientRequirement/summary';

    try {

        const { data } = await api.get(`${endpoint}`, { params: { ...creds } });

        return data;

    } catch (e) {

        return {

            error: "Something went wrong",

        };

    }

};

// api.js

 

export const get_tech_stack = async (creds = {}) => {

    let endpoint = "api/v2/techstack/getAll";

    try {

        const { data } = await api.get( endpoint,{ params: { ...creds } });

        return data;

    } catch (e) {

        return {

            error: "Something went wrong",

        };

    }

};