import { Button, Modal, Select } from "antd";
import {
  getTechStacks,
  setFilterParameters,
} from "../../../Redux/Slices/Lists/ListsSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

const FilterDialogUtilisation = ({
  open,
  onClose,
  groups,
  POC,
  projectName,
  users,
  filterParams,
  setFilterParams,
  showProjectStatusFilters,
  showOnboardHistoryFilter,
  showUtilisationFilter,
  showInactiveDevelopersFilter,
}) => {
  const dispatch = useDispatch();
  const projectTypeData = [
    { label: "Fixed Scope Budget", value: "Fixed_Scope_Budget" },
    { label: "Fixed Scope Premium", value: "Fixed_Scope_Premium" },
    { label: "Developers On Demand", value: "Developers_On_Demand" },
    { label: "Dedicated Team Model", value: "Dedicated_Team_Model" },
    { label: "Shared Tech Team", value: "Shared_Tech_Team" },
    { label: "Monthly Maintenance", value: "Monthly_Maintenance" },
  ];


  useEffect(() => {
    dispatch(getTechStacks());
  }, []);
  const { techStacks } = useSelector((store) => store.lists);

  return (
    <Modal
      open={open}
      onCancel={onClose}
      footer={[
        <Button
          key="cancel"
          onClick={() => {
            onClose();
          }}
        >
          Cancel
        </Button>,
        <Button
          key="apply"
          type="primary"
          onClick={() => {
            dispatch(setFilterParameters(filterParams));
            onClose();
          }}
        >
          Apply
        </Button>,
      ]}
    >
      <div
        style={{
          font: "Montserrat",
          fontSize: "24px",
          fontWeight: "500",
          lineHeight: "30px",
        }}
      >
        Filter
      </div>
      <div className="mt-4 flex flex-col gap-2">
        {!showOnboardHistoryFilter && !showUtilisationFilter && (
          <Select
            size="large"
            style={{ width: 300 }}
            value={filterParams.selectedGroup}
            placeholder="Select Group"
            onChange={(value) =>
              setFilterParams({ ...filterParams, selectedGroup: value })
            }
            mode="multiple"
            showSearch={true}
            optionFilterProp="children"
          >
            <Select.Option value="NA">No Group Assigned</Select.Option>
            {groups?.map((group) => (
              <Select.Option key={group._id} value={group._id}>
                {group.group[0]?.groupName}
              </Select.Option>
            ))}
          </Select>
        )}
        {showUtilisationFilter && (
          <>
            <Select
              size="large"
              style={{ width: 300 }}
              value={filterParams.selectedGroup}
              placeholder="Select Group"
              onChange={(value) =>
                setFilterParams({ ...filterParams, selectedGroup: value })
              }
              mode="multiple"
              showSearch={true}
              optionFilterProp="children"
            >
              <Select.Option value="NA">No Group Assigned</Select.Option>
              {groups?.map((group) => (
                <Select.Option key={group._id} value={group._id}>
                  {group.group[0]?.groupName}
                </Select.Option>
              ))}
            </Select>
            <Select
              size="large"
              style={{ width: 300 }}
              placeholder="Select Billability"
              onChange={(value) =>
                setFilterParams({
                  ...filterParams,
                  selectedBillability: value,
                })
              }
              value={filterParams.selectedBillability}
              showSearch={true}
              optionFilterProp="children"
            >
              <Select.Option key="Billable" value="Billable">
                Billable
              </Select.Option>
              <Select.Option key="Non_Billable" value="Non_Billable">
                Non Billable
              </Select.Option>
              <Select.Option
                key="Fixed_Scope_Direct"
                value="Fixed_Scope_Direct"
              >
                Fixed Scope Direct
              </Select.Option>
              <Select.Option
                key="Fixed_Scope_Indirect"
                value="Fixed_Scope_Indirect"
              >
                Fixed Scope Indirect
              </Select.Option>
            </Select>
          </>
        )}
        {showProjectStatusFilters && (
          <>
            <Select
              size="large"
              style={{ width: 300 }}
              placeholder="Select Project Manager"
              onChange={(value) =>
                setFilterParams({ ...filterParams, selectedPm: value })
              }
              value={filterParams.selectedPm}
              mode="multiple"
              showSearch={true}
              optionFilterProp="children"
            >
              {POC?.map((projectManager) => (
                <Select.Option
                  key={projectManager.POC_id}
                  value={projectManager.POC_id}
                >
                  {projectManager?.POC}
                </Select.Option>
              ))}
            </Select>
            <Select
              size="large"
              style={{ width: 300 }}
              placeholder="Select Project Type"
              onChange={(value) =>
                setFilterParams({ ...filterParams, selectedProjectType: value })
              }
              value={filterParams.selectedProjectType}
              mode="multiple"
              showSearch={true}
              optionFilterProp="children"
            >
              {projectTypeData.map((projectType) => (
                <Select.Option
                  key={projectType.label}
                  value={projectType.value}
                >
                  {projectType.label}
                </Select.Option>
              ))}
            </Select>
          </>
        )}
        {showOnboardHistoryFilter && (
          <>
            <div className="flex gap-4 mb-2">
              <Select
                size="large"
                style={{ width: 300 }}
                placeholder="Select Project Manager"
                onChange={(value) =>
                  setFilterParams({ ...filterParams, selectedPm: value })
                }
                value={filterParams.selectedPm}
                mode="multiple"
                showSearch={true}
                optionFilterProp="children"
              >
                {POC?.map((projectManager) => (
                  <Select.Option
                    key={projectManager.POC_id}
                    value={projectManager.POC_id}
                  >
                    {projectManager?.POC}
                  </Select.Option>
                ))}
              </Select>
              <Select
                size="large"
                style={{ width: 300 }}
                placeholder="Select Project"
                onChange={(value) =>
                  setFilterParams({ ...filterParams, selectedProject: value })
                }
                value={filterParams.selectedProject}
                mode="multiple"
                showSearch={true}
                optionFilterProp="children"
              >
                {projectName?.map((project) => (
                  <Select.Option key={project._id} value={project?._id}>
                    {project?.projectName}
                  </Select.Option>
                ))}
              </Select>
            </div>
            <div className="flex gap-4 mb-2">
              <Select
                size="large"
                style={{ width: 300 }}
                placeholder="Select Status"
                onChange={(value) =>
                  setFilterParams({ ...filterParams, selectedStatus: value })
                }
                value={filterParams.selectedStatus}
                showSearch={true}
                optionFilterProp="children"
              >
                <Select.Option key="Onboarded" value="Onboarded">
                  Onboarded
                </Select.Option>
                <Select.Option key="Offboarded" value="Offboarded">
                  Offboarded
                </Select.Option>
                <Select.Option key="OnReplaced" value="OnReplaced">
                  OnReplaced
                </Select.Option>
                <Select.Option key="OffReplaced" value="OffReplaced">
                  OffReplaced
                </Select.Option>
                <Select.Option key="OnObservation" value="OnObservation">
                  OnObservation
                </Select.Option>
                <Select.Option key="OffObservation" value="OffObservation">
                  OffObservation
                </Select.Option>
              </Select>
              <Select
                size="large"
                style={{ width: 300 }}
                placeholder="Select Developer Name"
                onChange={(value) =>
                  setFilterParams({ ...filterParams, selectedDeveloper: value })
                }
                value={filterParams.selectedDeveloper}
                mode="multiple"
                showSearch={true}
                optionFilterProp="children"
              >
                {users?.map((user) => (
                  <Select.Option key={user?._id} value={user?._id}>
                    {user?.fullName}
                  </Select.Option>
                ))}
              </Select>
            </div>
            <div className="flex gap-4 mb-2">
              <Select
                size="large"
                style={{ width: 300 }}
                placeholder="Select Project Type"
                onChange={(value) =>
                  setFilterParams({
                    ...filterParams,
                    selectedProjectType: value,
                  })
                }
                value={filterParams.selectedProjectType}
                mode="multiple"
                showSearch={true}
                optionFilterProp="children"
              >
                {projectTypeData.map((projectType) => (
                  <Select.Option
                    key={projectType.label}
                    value={projectType.value}
                  >
                    {projectType.label}
                  </Select.Option>
                ))}
              </Select>
              <Select
                size="large"
                style={{ width: 300 }}
                placeholder="Select Billability"
                onChange={(value) =>
                  setFilterParams({
                    ...filterParams,
                    selectedBillability: value,
                  })
                }
                value={filterParams.selectedBillability}
                showSearch={true}
                optionFilterProp="children"
              >
                <Select.Option key="Billable" value="Billable">
                  Billable
                </Select.Option>
                <Select.Option key="Non_Billable" value="Non_Billable">
                  Non Billable
                </Select.Option>
                <Select.Option
                  key="Fixed_Scope_Direct"
                  value="Fixed_Scope_Direct"
                >
                  Fixed Scope Direct
                </Select.Option>
                <Select.Option
                  key="Fixed_Scope_Indirect"
                  value="Fixed_Scope_Indirect"
                >
                  Fixed Scope Indirect
                </Select.Option>
              </Select>
            </div>
          </>
        )}
        {showInactiveDevelopersFilter && (
          <>
            <Select
              size="large"
              style={{ width: 300 }}
              value={filterParams.selectedTechStack}
              placeholder="Select TechStack"
              onChange={(value) =>
                setFilterParams({ ...filterParams, selectedTechStack: value })
              }
              mode="multiple"
              showSearch={true}
              optionFilterProp="children"
            >
              {techStacks?.map((techStack) => (
                <Select.Option key={techStack._id} value={techStack._id}>
                  {techStack.name}
                </Select.Option>
              ))}
            </Select>
          </>
        )}
      </div>
    </Modal>
  );
};

export default FilterDialogUtilisation;
