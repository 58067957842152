import React from "react";
import { Bar } from "react-chartjs-2";

const truncateLabel = (label) => {
  // Map specific titles to truncated versions
  const titleMappings = {
    "Senior Software Quality Assurance Analyst": "Senior Software QA Analyst",
    "Software Quality Assurance Analyst": "Software QA Analyst",
    // Add more title mappings as needed
  };

  return titleMappings[label] || label;
};

const BarChart = ({ barLabels, barCount, labelName,onBarClick,barThickness }) => {
  const truncatedLabels = barLabels.map(truncateLabel);
  const barChartData = {
    labels: truncatedLabels,
    datasets: [
      {
        label: labelName,
        backgroundColor: "rgba(75,192,192,0.6)",
        borderColor: "rgba(75,192,192,1)",
        borderWidth: 1,
        hoverBackgroundColor: "rgba(75,192,192,0.4)",
        hoverBorderColor: "rgba(75,192,192,1)",
        data: barCount,
        barThickness: barThickness ? barThickness :25 ,
      },
    ],
  };

  const options = {
    scales: {
      x: {
        ticks: {
          maxRotation: 70,
          autoSkip: false,
        },
      },
    },
    indexAxis: "x", // Use 'y' for horizontal bar charts
    layout: {
      padding: 
      {
        left: 10, // Adjust padding as needed
        right: 10,
        top: 20,
        bottom: 10,
      },
    },
    maintainAspectRatio: false,
    barThickness: 25,

    
    onClick: (e, elements) => {
      if (elements.length > 0) {
        const index = elements[0].index;
        onBarClick(barLabels[index], barCount[index]);
      }

    }



  };
  return (
    <div className="w-full h-[60vh]">
      {barLabels.length > 0 && barCount.length > 0 && (
        <Bar data={barChartData} options={options} />
      )}
    </div>
  );
};

export default BarChart;






