import moment from 'moment';

export const calculateWorkingDays = (
    startDate,
    endDate,
) => {
    let start = moment(startDate);
    let end = moment(endDate);

    let workingDays = 0;

    while (start.isSameOrBefore(end)) {
        const dayOfWeek = start.isoWeekday();
        if (dayOfWeek !== 6 && dayOfWeek !== 7) {
            workingDays++;
        }
        start.add(1, 'days');
    }

    return workingDays;
};

export const calculateTotalWorkingDaysPastYear =
    () => {
        const today = moment();
        const oneYearAgo = moment().subtract(
            1,
            'year',
        );

        const totalWorkingDays =
            calculateWorkingDays(
                oneYearAgo,
                today,
            );
        return totalWorkingDays;
    };

export const calculateTotalDaysWorked = (
    projects,
) => {
    const today = moment();
    const oneYearAgo = moment().subtract(
        1,
        'year',
    );

    let totalDaysWorked = 0;

    projects?.forEach((project) => {
        project?.onboardProjectDetail?.forEach(
            (detail) => {
                const onboardDate = moment(
                    detail.onBoardingDate,
                );

                const offBoardingDetail =
                    project?.offBoard?.find(
                        (offBoardDetail) =>
                            offBoardDetail?.projectId ===
                            detail?.projectId,
                    );

                const offBoardDate =
                    offBoardingDetail
                        ? moment(
                              offBoardingDetail?.offBoardingDate,
                          )
                        : today;

                const effectiveStartDate =
                    moment.max(
                        oneYearAgo,
                        onboardDate,
                    );
                const effectiveEndDate =
                    moment.min(
                        today,
                        offBoardDate,
                    );

                if (
                    effectiveStartDate.isSameOrBefore(
                        effectiveEndDate,
                    )
                ) {
                    totalDaysWorked +=
                        calculateWorkingDays(
                            effectiveStartDate,
                            effectiveEndDate,
                        );
                }
            },
        );
    });

    return totalDaysWorked;
};
