import {
  getInactiveDevelopersDetails,
  downloadInactiveDevelopersDetails,
} from "../../../Redux/Slices/Lists/ListsSlice";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "antd";
import { useEffect, useState } from "react";

import { getGroups } from "../../../Redux/Slices/Lists/ListsSlice";
import Reports from "./Reports";
import { debounce } from "lodash";
import { toast } from "react-toastify";
import dayjs from "dayjs";

const InactiveDevelopersReport = () => {
  const dispatch = useDispatch();
  const { inactiveDevelopersDetails, searchFilters, groups } = useSelector(
    (store) => store.lists
  );
  const [name, setName] = useState("");
  const [showInactiveDevelopersFilter, setShowInactiveDevelopersFilter] =
    useState(true);

  const handleSearch = debounce((name) => {
    dispatch(
      getInactiveDevelopersDetails({
        name: name,
      })
    );
  }, 800);

  const handleDownload = () => {
    dispatch(downloadInactiveDevelopersDetails());
    toast.success("Downloading CSV file");
  };

  useEffect(() => {
    dispatch(getInactiveDevelopersDetails({ name: name }));
    if (groups) {
      dispatch(getGroups());
    }
  }, [searchFilters]);

  const rows =
    inactiveDevelopersDetails.length > 0 &&
    inactiveDevelopersDetails.map((user) => {
      let lastOffDate = "";
      if (user.lastOffboardingdate !== "NA") {
        lastOffDate = dayjs(user.lastOffboardingdate).format("DD-MMM-YYYY");
      } else {
        lastOffDate = "NA";
      }
      return {
        fullName: user?.fullName,
        techStack: user?.techStack,
        groupName: user?.groupName ? user?.groupName : "NA",
        projectName: user?.projectName ? user?.projectName : "NA",
        onbenchDays: user?.onbenchDays,
        lastOffboardingdate: lastOffDate,
        seniority: user?.seniority,
      };
    });

  let columns = [
    {
      title: "Developer Name",
      dataIndex: "fullName",
      width: 150,
      align: "center",
      ellipsis: true,
      render: (text) => (
        <Tooltip title={text} placement="right" arrow>
          <span>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: "Onbench duration (days)",
      dataIndex: "onbenchDays",
      width: 150,
      render: (text) => (
        <Tooltip title={text} placement="right" arrow>
          <span>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: "Last Project",
      dataIndex: "projectName",
      width: 150,
      ellipsis: true,
      render: (text) => (
        <Tooltip title={text} placement="right" arrow>
          <span>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: "Last Offboarding Date",
      dataIndex: "lastOffboardingdate",
      width: 150,
      render: (text) => (
        <Tooltip title={text} placement="right" arrow>
          <span>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: "Seniority",
      dataIndex: "seniority",
      width: 150,
      ellipsis: true,
      render: (text) => (
        <Tooltip title={text} placement="right" arrow>
          <span>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: "Techstack",
      dataIndex: "techStack",
      width: 150,
      ellipsis: true,
      render: (text) => (
        <Tooltip title={text} placement="right" arrow>
          <span>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: "Group",
      dataIndex: "groupName",
      width: 150,
      ellipsis: true,
      render: (text) => (
        <Tooltip title={text} placement="right" arrow>
          <span>{text}</span>
        </Tooltip>
      ),
    },
  ];

  return (
    <Reports
      columns={columns}
      rows={rows}
      handleSearch={handleSearch}
      title="Active/Inactive Developers Report"
      handleDownload={handleDownload}
      searchPlaceholder="Search Developer Name"
      setName={setName}
      name={name}
      showInactiveDevelopersFilter={showInactiveDevelopersFilter}
    />
  );
};

export default InactiveDevelopersReport;
