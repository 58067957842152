import React, { useEffect, useState } from "react";
import api from "../../../../src/apiclient";
import { Bar } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import LoadingSpinner from "../../../utils/LoadingSpinner";
import { Autocomplete, Grid, TextField } from "@mui/material";
Chart.register(...registerables);

const IcChart = () => {
  const [data, setData] = useState();
  const [chartData, setChartData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const years = [2019, 2020, 2021, 2022, 2023, 2024, 2025];
  const [currentYear, setCurrentYear] = useState(2023);
  console.log(data, "charttt");
  console.log(chartData, "chartddtt");
  useEffect(() => {
    const getReportsDeveloper = async () => {
      const res = await api.get(
        `/api/v2/ic/getIcCountAsPerWeek?year=${currentYear}`
      );
      console.log(res?.data?.data, "Chartssss");
      setData(res?.data?.data);
      setIsLoading(false);
    };
    getReportsDeveloper();
  }, [currentYear]);

  useEffect(() => {
    const settingData = () => {
      const formattedData = formatChartData(data);
      setChartData(formattedData);
    };
    settingData();
  }, [data]);

  console.log(chartData, "chartddtt");

  const formatChartData = (data) => {
    console.log(data, "dataaaa");
    const counts = data?.map((item) => item.count);
    const labels = data?.map((item) => item.week);

    return {
      labels: labels,
      datasets: [
        {
          label: "Count",
          data: counts,
          backgroundColor: "rgba(75, 192, 192, 0.6)", // Customize the bar color
        },
      ],
    };
  };
  return (
    <>
      <div>
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <>
            <div
              style={{
                width: "100%",
                height: "4.5rem",
                display: "flex",
                gap: "15px",
                justifyContent: "flex-end",
              }}
            >
              <div
                style={{
                  width: "9.5rem",
                  // margin:"5rem",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              >
                <Grid item xs={6}>
                  <Autocomplete
                    options={years?.map((option) => option)}
                    name="year"
                    filterSelectedOptions
                    onChange={(event, value) => setCurrentYear(value)}
                    value={currentYear}
                    size="small"
                    renderInput={(params) => (
                      <>
                        <TextField
                          required
                          {...params}
                          name="year"
                          label="Year"
                        />
                      </>
                    )}
                  />
                </Grid>
              </div>
            </div>

            <div
              style={{
                height: "80vh",
                width: "100%",
              }}
            >
              <Bar
                data={chartData}
                options={{
                  scales: {
                    y: {
                      beginAtZero: true,
                    },
                  },
                }}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default IcChart;
