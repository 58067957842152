import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useLocation } from "react-router-dom";
import { getTaskSheet } from "../../apis/TimesheetApi";
import SliderModal from "./SliderModal";
import { Form } from "antd";
import Todoinput from "./Todoinput";
import TodoList from "./TodoList";
import "react-datepicker/dist/react-datepicker.css";

import CustomDatePicker from "./CustomDatePicker ";
import { Link } from "react-router-dom";
import dayjs from "dayjs";

export default function ViewTimesheet({}) {
  const [form] = Form.useForm();
  const [getproject, setGetproject] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [projectsPerPage] = useState(9);
  const [openSidebar, setOpenSidebar] = useState(false);
  const [timesheetId, setTimesheetId] = useState(null);
  const [changeState, setChangeState] = useState(false);
  const totalPages = Math.ceil(getproject.length / projectsPerPage);
  const visiblePages = 3;
  const [visiblePageRange, setVisiblePageRange] = useState({
    start: 1,

    end: Math.min(visiblePages, totalPages),
  });
  const [listTodo, setListTodo] = useState([]);
  const [selectedProjectName, setSelectedProjectName] = useState("");
  const [selectedProjectId, setSelectedProjectId] = useState("");
  const [tasks, setTasks] = useState([]);
  const handleSidebarClose = async () => {
    setOpenSidebar(false);
  };
  const [open, setOpen] = useState(false);
  const [timeTaken, setTimeTaken] = useState("");
  const [selectedTaskId, setSelectedTaskId] = useState("");
  const [isFilled, setIsFilled] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedDay, setSelectedDay] = useState(new Date());
  const [selectedMonth, setSelectedMonth] = useState(
    new Date().toLocaleString("en-US", { month: "long" })
  );
  const [taskSheet, setTaskSheet] = useState(null);
  const [editTasksData, setEditTasksData] = useState();
  const [updateTimesheet, setUpdateTimesheet] = useState(false);
  const location = useLocation();
  const location_url = location?.search?.split("=")[2];
  const monthStartDate = dayjs(new Date())
    .startOf("month")
    .format("YYYY-MM-DD");

  const monthCurrentDate = dayjs().format("YYYY-MM-DD");
  const userId = localStorage.getItem("user_id");

  let addList = (inputText, inputTime) => {
    let taskObject = {
      name: inputText,
      time: inputTime,
    };
    if (listTodo?.length > 0) setListTodo([...listTodo, taskObject]);
    else setListTodo([taskObject]);
  };

  const deleteListItem = (key) => {
    setListTodo((prev) => prev.filter((item) => item.name !== key));
  };

  const saveTimeForTask = () => {
    const updatedTasks = tasks.map((task) =>
      task.id === selectedTaskId ? { ...task, timeTaken: timeTaken } : task
    );

    setTasks(updatedTasks);

    setOpen(false);
  };

  const onTimeFieldChange = (e) => {
    setTimeTaken(e.target.value);
  };

  const updateVisiblePageRange = (currentPage) => {
    const halfVisibleRange = Math.floor(visiblePages / 2);

    let startPage = currentPage - halfVisibleRange;

    let endPage = currentPage + halfVisibleRange;

    if (startPage <= 0) {
      startPage = 1;

      endPage = Math.min(visiblePages, totalPages);
    }

    if (endPage > totalPages) {
      endPage = totalPages;

      startPage = Math.max(1, endPage - visiblePages + 1);
    }

    setVisiblePageRange({ start: startPage, end: endPage });
  };
  const disabledDate = (current) => {
    return current && current.isAfter(new Date(), "day");
  };
  const handleDateChange = async (date) => {
    const { weekStartDate: startDate, nextSunday: endDate } =
      getWeekStartAndNextSunday(date);

    if (location_url) {
      let taskSheet = await getTaskSheet(startDate, endDate, location_url);
      setTaskSheet(taskSheet);
    } else {
      let taskSheet = await getTaskSheet(startDate, endDate);

      setTaskSheet(taskSheet);
    }
    setSelectedDay(date);
    setSelectedMonth(date.toLocaleString("en-US", { month: "long" }));
    setSelectedDate(startDate);
    setUpdateTimesheet(false);
  };

  function editTaskBtn(tasks, date) {
    let newDate = new Date(date);
    setIsFilled(tasks?.task.length ? true : false);

    const taskDataEdit = {
      tasks: tasks?.task,

      projectId: selectedProjectId,

      userId: tasks?.userId,

      id: tasks?._id,

      date: newDate.toISOString(),
    };

    setListTodo(tasks?.task);

    setEditTasksData(taskDataEdit);

    setOpenSidebar(true);
  }

  const renderPreviousDays = () => {
    const weekDays = [];

    const startOfWeek = new Date(selectedDate);

    const selectedDayOfWeek = startOfWeek.getDay();

    startOfWeek.setDate(startOfWeek.getDate() - selectedDayOfWeek + 1);

    for (let i = 0; i < 7; i++) {
      const currentDate = new Date(startOfWeek);

      currentDate.setDate(startOfWeek.getDate() + i);

      const day = currentDate.toLocaleString("en-US", { weekday: "long" });

      const dayNumber = currentDate.getDate();

      const month = currentDate.toLocaleString("en-US", { month: "long" });

      const tasksForDay = taskSheet.data.find(
        (task) =>
          new Date(task.date).toLocaleDateString() ===
          currentDate.toLocaleDateString()
      );

      const isWeekend =
        currentDate.getDay() === 0 || currentDate.getDay() === 6;

      weekDays.push(
        <div>
          <div
            style={{ borderBottom: "1px solid #ccc" }}
            className="border-b flex pt-2 pb-2 pl-2"
          >
            <div className="w-[10%]">
              <h3>{day}</h3>

              <span className="text-gray-500 text-lg">
                ({dayNumber} {month})
              </span>
            </div>
            <div className="w-[90%] flex justify-between">
              <div key={tasksForDay?.id} className="flex w-[90%]">
                <div>
                  {tasksForDay && tasksForDay.task.length > 0 ? (
                    tasksForDay.task.map((item) => {
                      return (
                        <div className="pl-16" key={item.name}>
                          <p className="text-black">
                            {item.name}{" "}
                            {item?.name == "On Leave" ? (
                              ""
                            ) : (
                              <span className="text-gray-400">
                                - {item.time}{" "}
                                <span className="text-gray-400">
                                  {item.time > 1 ? "hrs" : "hr"}
                                </span>
                              </span>
                            )}
                          </p>
                        </div>
                      );
                    })
                  ) : isWeekend ? (
                    <div className="pl-16">
                      <p className="">
                        <span className="text-green-500 uppercase">
                          Week-Off{" "}
                        </span>
                      </p>
                    </div>
                  ) : (
                    <div>
                      <div className="pl-16  ">
                        <p className="">
                          <span className="text-red-500 uppercase">
                            missing entry
                          </span>
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <Button
                className="bg-black text-white mt-4 mr-3"
                style={{
                  fontWeight: "500",
                }}
                onClick={() => editTaskBtn(tasksForDay, currentDate)}
              >
                Edit
              </Button>
            </div>
          </div>
        </div>
      );
    }

    return weekDays;
  };

  function getWeekStartAndNextSunday(selectedDate) {
    const selectedDay = new Date(selectedDate);
    const selectedDayOfWeek = selectedDay.getDay(); // 0 (Sunday) to 6 (Saturday)

    // Calculate the difference in days to find the week's start date (Monday)
    const daysToMonday = selectedDayOfWeek === 0 ? 6 : selectedDayOfWeek - 1;
    const weekStartDate = new Date(
      selectedDay.getTime() - daysToMonday * 24 * 60 * 60 * 1000
    );

    // Calculate the next Sunday by adding 6 days to the week's start date
    const nextSunday = new Date(
      weekStartDate.getTime() + 6 * 24 * 60 * 60 * 1000
    );

    // Format the dates to display with the month
    const options = { year: "numeric", month: "numeric", day: "numeric" };
    const formattedWeekStartDate = weekStartDate.toLocaleDateString(
      "sv-SE",
      options
    );
    const formattedNextSunday = nextSunday.toLocaleDateString("sv-SE", options);

    return {
      weekStartDate: formattedWeekStartDate,
      nextSunday: formattedNextSunday,
    };
  }

  useEffect(() => {
    updateVisiblePageRange(currentPage);
  }, [currentPage]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    const projectName = searchParams.get("projectName");

    const projectId = searchParams.get("projectId");

    setSelectedProjectName(projectName);

    setSelectedProjectId(projectId);
    handleDateChange(selectedDate);
  }, [location.search]);

  useEffect(() => {
    if (updateTimesheet) handleDateChange(selectedDay);
  }, [updateTimesheet]);

  return (
    <div>
      <div className="w-[100%] bg-black h-20 flex justify-start rounded-md">
        <div className="pt-5 ml-4">
          <Link to="/dashboard/timesheet">
            <ArrowLeftOutlined className="text-white  text-2xl" />
          </Link>
        </div>

        <div className=" ml-7 pt-6">
          <h1 className="text-white text-xl ">TimeSheet Entry</h1>
        </div>
      </div>

      <div className="bg-gray-100 w-full">
        <div className=" flex justify-between items-center py-3 px-4 my-3 border-b bg-white">
          <div>
            <h1 className="text-xl text-slate-600">
              {selectedProjectName.toUpperCase()}
            </h1>

            <p className="text-slate-400">See and Edit the missing entry</p>

            <div></div>
          </div>
        </div>

        <div className="flex justify-between bg-gray-100">
          <div
            className="h-[70%] w-full bg-white"
            style={{ borderRadius: "0.5rem" }}
          >
            <div
              className=" h-[13%] w-[100%] flex justify-between bg-red-200 "
              style={{
                backgroundColor: "#5555",
                borderRadius: "0.5rem 0.5rem 0 0",
              }}
            >
              <div className="flex justify-start">
                <div className="pt-1">
                  <Link to="/dashboard/timesheet">
                    <ArrowLeftOutlined className="text-black pt-4 pl-4 text-2xl" />
                  </Link>
                </div>

                <div className="ml-4 pt-5 pb-6 text-sm font-normal">
                  <h1>{selectedMonth}</h1>
                </div>
              </div>

              <div>
                <CustomDatePicker
                  selectedDay={selectedDay}
                  handleDateChange={handleDateChange}
                  userId={userId}
                  projectId={selectedProjectId}
                  startDate={monthStartDate}
                  endDate={monthCurrentDate}
                  changeState={changeState}
                />
              </div>
            </div>

            {taskSheet ? (
              renderPreviousDays()
            ) : (
              <p className="text-center">No Data</p>
            )}
          </div>
        </div>
      </div>

      {openSidebar && (
        <SliderModal
          isFilled={isFilled}
          setIsFilled={setIsFilled}
          timesheetId={timesheetId}
          openSidebar={openSidebar}
          editTasksData={editTasksData}
          selectedProjectName={selectedProjectName}
          handleSidebarClose={handleSidebarClose}
          disabledDate={disabledDate}
          addList={addList}
          listTodo={listTodo}
          saveTimeForTask={saveTimeForTask}
          onTimeFieldChange={onTimeFieldChange}
          form={form}
          Todoinput={Todoinput}
          TodoList={TodoList}
          deleteListItem={deleteListItem}
          open={open}
          setOpen={setOpen}
          setUpdateTimesheet={setUpdateTimesheet}
          setChangeState={setChangeState}
          isTimesheetPm={false}
          editMode={true}
          taskSheet={taskSheet}
          setTaskSheet={setTaskSheet}
        />
      )}
    </div>
  );
}
