import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";

import React, { useState, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import CircularProgress from "@mui/material/CircularProgress";
import {
  get_summary,
  get_tech_stack,
} from "../../../Services/clientRequirementServices";

function DynamicTable({ jsonData, techStackData }) {
  if (
    !jsonData ||
    jsonData.length === 0 ||
    !techStackData ||
    techStackData.length === 0
  ) {
    // return <p>No data available.</p>;
    return <CircularProgress />;
  }

  const grandTotalRow = jsonData.map((client) => {
    const rowTotal = techStackData.reduce((sum, tech) => {
      const devCount =
        client.techStack.find((d) => d.id === tech._id)?.devCount || 0;

      return sum + parseInt(devCount, 10);
    }, 0);

    return rowTotal;
  });

  const grandTotalCol = techStackData.map((tech, colIndex) => {
    const colTotal = jsonData.reduce((sum, client) => {
      const devCount =
        client.techStack.find((d) => d.id === tech._id)?.devCount || 0;

      return sum + parseInt(devCount, 10);
    }, 0);

    return (
      <td
        key={colIndex}
        style={{
          borderRight: "1px solid #ddd",
          borderTop: "1px solid #ddd",
          borderBottom: "1px solid #ddd",
          textAlign: "center",
        }}
      >
        {colTotal === 0 ? null : colTotal}
      </td>
    );
  });

  return (
    <div
      style={{
        overflowX: "auto",
        overflowY: "hidden",
        marginTop: "20px",
        display: "flex",
        flexDirection: "column-reverse", // Reverse the column order
      }}
    >
      <table style={{ width: "100%", borderCollapse: "collapse" }}>
        <thead>
          <tr>
            <th
              style={{
                position: "sticky",
                left: 0,
                zIndex: 2, // Higher zIndex to make it stay on top
                padding: "10px",
                textAlign: "left",
                fontWeight: "bold",
                backgroundColor: "black",
                color: "white",
              }}
            >
              Client Name
            </th>
            {techStackData.map((tech, index) => (
              <th
                key={index}
                style={{
                  position: "sticky",
                  top: 0,
                  zIndex: 1,
                  padding: "10px",
                  textAlign: "right",
                  backgroundColor: "black",
                  color: "white",
                  borderRight: "1px solid white",
                  borderTop: "1px solid white",
                  borderBottom: "1px solid white",
                }}
              >
                {tech?.name}
              </th>
            ))}
            <th
              style={{
                position: "sticky",
                top: 0,
                zIndex: 1,
                padding: "10px",
                textAlign: "left",
                fontWeight: "bold",
                backgroundColor: "black",
                color: "white",
              }}
            >
              Grand Total
            </th>
          </tr>
        </thead>

        <tbody>
          {jsonData.map((client, index) => (
            <tr key={index}>
              <td
                style={{
                  zIndex: 2, // Higher zIndex to make it stay on top
                  left: 0,
                  position: "sticky",
                  padding: "10px",
                  textAlign: "left",
                }}
              >
                {client.clientName}
              </td>
              {techStackData.map((data, index) => {
                let x = false;
                let devCount = "";

                client.techStack.map((d, i) => {
                  if (data._id === d.id) {
                    x = true;
                    devCount = d.devCount;
                  }
                });

                return (
                  <td
                    key={index}
                    style={{
                      borderRight: "1px solid #ddd",
                      borderTop: "1px solid #ddd",
                      borderBottom: "1px solid #ddd",
                      textAlign: "center",
                    }}
                  >
                    {x ? devCount : null}
                  </td>
                );
              })}
              <td
                style={{
                  zIndex: 1,
                  top: 0,
                  position: "sticky",
                  padding: "10px",
                  textAlign: "center",
                }}
              >
                {client.totalDevs}
              </td>
            </tr>
          ))}
          <tr>
            <td
              style={{
                background: "#fff",
                position: "sticky",
                left: 0,
                zIndex: 1,
                padding: "10px",
                textAlign: "left",
                fontWeight: "bold",
                backgroundColor: "black",
                color: "white",
              }}
            >
              Grand Total
            </td>
            {grandTotalCol}
            <td
              style={{
                position: "sticky",
                top: 0,
                zIndex: 1,
                background: "#fff",
                padding: "10px",
                textAlign: "center",
              }}
            >
              {grandTotalRow.reduce((sum, total) => sum + total, 0)}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

function Summary({ open, onClose }) {
  const [jsonData, setJsonData] = useState([]);
  const [allTechStack, setAllTechStack] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const getSummary = async () => {
    try {
      const resp = await get_summary();

      if (resp?.resp?.status == 200) {
        setJsonData(resp?.resp?.clientData[0]?.clientList);
      }
    } catch (error) {
      // console.log(error);
    } finally {
      setIsLoading(false); // Mark loading as complete
    }
  };

  const getTechStack = async () => {
    try {
      const res = await get_tech_stack();

      setAllTechStack(res?.data);
    } catch (error) {
      // console.log("Error fetching tech stack:", error);
    } finally {
      setIsLoading(false); // Mark loading as complete
    }
  };

  useEffect(() => {
    getSummary();
    getTechStack();
  }, []);

  return (
    <Box>
      <Dialog
        fullWidth
        maxWidth="lg"
        open={open}
        onClose={onClose}
        sx={{
          "& .MuiDialogActions-root": {
            padding: "0px !important",
          },
        }}
      >
        <DialogTitle>
          <Grid container justifyContent="space-between" alignItems="center">
            Summary
            <Button
              sx={{
                color: "black",

                fontSize: "1rem",
              }}
              onClick={onClose}
            >
              <CloseIcon sx={{ cursor: "pointer" }} onClick={onClose} />
            </Button>
          </Grid>
        </DialogTitle>

        <DialogContent>
          <Grid
            container
            sx={{
              "& .MuiAutocomplete-popper": {
                paddingBottom: "1rem",
              },

              "& .MuiFormHelperText-root": {
                color: "#D32F2F",
              },
            }}
            mt={2}
            spacing={2}
          >
            {isLoading ? (
              <CircularProgress /> // Display loading spinner when data is loading
            ) : (
              <DynamicTable jsonData={jsonData} techStackData={allTechStack} />
            )}
            {/* <DynamicTable jsonData={jsonData} techStackData={allTechStack} /> */}
          </Grid>
        </DialogContent>
      </Dialog>
    </Box>
  );
}

export default Summary;
