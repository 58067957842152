import React from "react";

import { DeleteOutlined } from "@ant-design/icons";

function Todolist(props) {
  const changeTodoHandler = (e) => {
    let newData = props.taskSheet.data;
    newData.map((x) => {
      if (x._id == props.id) {
        x.task[props.index].name = e.target.value;
      }
    });
  };

  const handleDeleteClick = () => {
    props.deleteItem(props.item.name);
  };

  return (
    <div className="flex gap-[1rem]">
      {props.item.name === "On Leave" ? (
        <div className="list-item" style={{ backgroundColor: "#fff" }}>
          <input
            type="text"
            className="list-item-content w-full outline-none"
            name="items"
            defaultValue={props.item.name}
            readOnly
            style={{
              border: "none", // Add this line to remove the border
            }}
          />

          {/* Add a delete button for "On Leave" */}

          <DeleteOutlined
            className="border mr-2"
            style={{ position: "absolute", top: "5px", right: "5px" }}
            onClick={handleDeleteClick}
          />
        </div>
      ) : (
        <>
          <div
            className="list-item"
            style={{
              backgroundColor: "#fff",
              width: "80%",
            }}
          >
            <input
              type="text"
              className="list-item-content w-full outline-none"
              name="items"
              defaultValue={props.item.name}
              onChange={(e) => changeTodoHandler(e)}
              autoFocus="autoFocus"
              readOnly={props.item.name === "On Leave" ? true : false}
              style={{
                border: "none", // Add this line to remove the border
              }}
            />
          </div>

          <div className="w-2 mt-2">
            <DeleteOutlined
              className="border mr-2"
              onClick={(e) => {
                props.deleteItem(props.item.name);
              }}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default Todolist;
