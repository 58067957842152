import React from 'react';
import './ThreeDotLoader.css';

const ThreeDotLoader = () => {
    return (
        <div className="loader">
            <span className="dot"></span>
            <span className="dot"></span>
            <span className="dot"></span>
        </div>
    );
};

export default ThreeDotLoader;
