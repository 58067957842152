import * as yup from "yup";

let RegDevSchema = yup.object().shape({
  fullName: yup
    .string()
    .required("First Name Required")
    .matches(
      /^[^-\s][a-zA-Z0-9_\s-]+$/,
      "Only alphabets are allowed for this field "
    )
    .max(30, "maximum 20 letters are allowed"),

  workingExperienceInYears: yup
    .number()
    .typeError("Experience(Years) Required")
    .required()
    .integer("This field only accept Integer")
    .moreThan(-1, "Value of Year Should be more than or equal to 0"),

  workingExperienceInMonths: yup
    .number()
    .typeError("Experience(Months) Required")
    .required()
    .integer("This field only accept Integer")
    .moreThan(-1, "Value of month Should be more than or equal to 0")
    .lessThan(13, "Value of month Should be less than 12"),

  email: yup.string().email().required("Email Required"),
  phoneNumber: yup
    .string()
    .required("Mobile Number Required")
    .matches(
      /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/,
      "Enter a valid mobile number"
    )
    .min(10, "please input valid number")
    .max(10, "please input valid number"),

  empId: yup.string().required("Employee ID Required"),

  // techStack: yup.string().required("Tech Stack Required"),
  password: yup.string().required("Password  Required"),
  designation: yup.string().required("Designation Required"),
  seniority: yup.string().required("Seniority Required"),
  location: yup.string().required("Base Location Required"),
  location: yup.string().required("Base Location Required"),
  workLocation: yup.string().required("Work Location Required"),
  workLocationCity: yup.string().when("workLocation", {
    is: (val) => val === "CS",
    then: yup.string().required("Work Location City Required"),
    otherwise: yup.string().notRequired(),
  }),
  joiningDate: yup.date().required("Date of Joining is Required"),
});

export default RegDevSchema;
