import React from "react";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Modal } from "antd";
import "../../../Assets/CSS/Groups/groups.css";

const ActionDialog = ({ open, onClose, deleteHandler, editHandler }) => {
  return (
    <Modal
      open={open}
      onCancel={onClose}
      footer={null}
      centered
      destroyOnClose
      width={300}
    >
      <div className="flex flex-col text-start justify-center gap-2">
        <Button
          className="action-button "
          onClick={() => {
            editHandler();
            onClose();
          }}
        >
          <EditOutlined />
          Edit
        </Button>

        <Button
          className="action-button "
          onClick={() => {
            deleteHandler();
            onClose();
          }}
        >
          <DeleteOutlined />
          Delete
        </Button>
      </div>
    </Modal>
  );
};

export default ActionDialog;
