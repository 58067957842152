import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Button } from "antd";
import LoadingSpinner from "../../utils/LoadingSpinner";
import { getaccessdetails } from "../../apis/TeamsApi";

const Accessdetails = () => {
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getaccessdetails();
        const data = response.data;
        const mappedData = data.map((item) => ({
          id: item._id, // Ensure that you include the 'id' field.
          activityBy: item.activityBy || "N/A",
          activityUser: item.activityUser || "N/A",
          activityDate: item.activityDate || "N/A",
          description: item.description || "N/A",
        }));

        setTableData(mappedData);
        setLoading(false);
      } catch (error) {
        // console.error("Error fetching data: ", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const columns = [
    { field: "activityBy", headerName: "Performed by", width: 150 },
    { field: "activityUser", headerName: "Performed to", width: 150 },
    // { field: "activityDate", headerName: "Date", width: 120 },
    {
      field: "activityDate",
      headerName: "Date",
      width: 120,
      valueGetter: (params) => {
        // Get the activityDate value and convert it to a Date object
        const activityDate = new Date(params.row.activityDate);

        // Format the date as YYYY-MM-DD
        const formattedDate = activityDate.toISOString().split("T")[0];

        return formattedDate;
      },
    },
    {
      field: "time",
      headerName: "Time",
      width: 120,
      valueGetter: (params) => {
        // Get the activityDate value and convert it to a Date object
        const activityDate = new Date(params.row.activityDate);

        // Format the time as HH:MM:SS
        const formattedTime = activityDate.toTimeString().split(" ")[0];

        return formattedTime;
      },
    },
    { field: "description", headerName: "Description", width: 600 },
  ];

  return (
    <div className="mt-2 bg-white rounded-md">
      <div className="flex justify-between items-center">
        <div className="font-semibold text-xl ml-4 pt-4">
          Access Management History
        </div>
      </div>
      <div style={{ height: "78vh", width: "95%" }} className="mt-4 ml-4 pb-2">
        {loading ? (
          <div>
            <LoadingSpinner />
          </div>
        ) : (
          <DataGrid
            rows={tableData}
            columns={columns}
            autoHeight={false}
            //   getRowId={(row) => row._id}
            sx={{
              backgroundColor: "white",
              "& .MuiDataGrid-columnHeaders": {
                background: "FAFAFA",
                color: "Black",
              },
              "& .MuiDataGrid-sortIcon": {
                color: "black",
              },
            }}
            rowHeight={40}
            hideFooterPagination
          />
        )}
      </div>
    </div>
  );
};

export default Accessdetails;
