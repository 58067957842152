import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  TextField,
  InputAdornment,
  IconButton,
  DialogActions,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { VisibilityOff, Visibility } from "@mui/icons-material";
import { getTeams } from "../../../Redux/Slices/Teams/TeamSlice";
import { getAllDesignation } from "../../../Redux/Slices/DesignationSlice";
import { yupResolver } from "@hookform/resolvers/yup";
import CloseIcon from "@mui/icons-material/Close";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";

import { addMember, editMember } from "../../../apis/TeamsApi";

const CreateEditTeam = ({ open, onClose, user }) => {
  const createSchema = yup.object().shape({
    fullName: yup.string().required("Name Required"),
    email: yup.string().email().required("Email Required"),
    designation: yup.string().required("Designation Required"),
    password: yup
      .string()
      .required("Password Required ")
      .matches(/^\S*$/, "Enter a valid password"),
  });



  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const editSchema = yup.object().shape({
    fullName: yup.string().required(),
    email: yup.string().email().required(),
    designation: yup.string().required(),
    password: yup.string().matches(/^\S*$/, "Enter a valid password"),
  });
  const designations = ["AVP", "VP", "PM", "SALES", "HR", "BA", "CSM"];

  const { error } = useSelector((store) => store?.designation);

  if (error) {
    toast(error);
  }

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: useMemo(() => {
      return {
        designation: user?.designationName,
        email: user?.email,
        fullName: user?.name,
      };
    }, [user]),
    resolver: yupResolver(user ? editSchema : createSchema),
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllDesignation());
  }, []);

  const onSubmit = (data) => {
    if (!data.password) {
      delete data.password;
    }
    if (user) {
      const obj = {
        ...data,
        id: user?.id,
      };
      editMember(obj)
        .then((response) => {
          toast("Team member successfully updated.");
          dispatch(getTeams());
          onClose();
        })
        .catch((error) => {
          toast(error?.response?.data?.msg);
        });
    } else {
      addMember(data)
        .then((response) => {
          toast("Team member successfully added.");
          dispatch(getTeams());
          onClose();
        })
        .catch((error) => {
          toast(error?.response?.data?.message);
        });
    }
  };
  return (
    <form>
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
        <DialogTitle>
          <Box
            sx={{
              font: "Montserrat",
              fontSize: "24px",
              fontWeight: "500",
              lineHeight: "30px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {user ? "Edit Team Member" : "Add Team Member"}

            <CloseIcon sx={{ cursor: "pointer" }} onClick={onClose} />
          </Box>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Box
            mb={2}
            sx={{
              "& .MuiFormHelperText-root": {
                color: "#D32F2F",
              },
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "0.5rem",
            }}
          >
            <TextField
              name="fullName"
              label="Name"
              required
              autoComplete="off"
              {...register("fullName")}
              helperText={errors && errors?.fullName?.message}
            />

            {user ? (
              <TextField
                required
                name="email"
                label="Email"
                {...register("email")}
                autoComplete="off"
                helperText={errors && errors?.email?.message}
                disabled
              />
            ) : (
              <TextField
                required
                name="email"
                label="Email"
                {...register("email")}
                autoComplete="off"
                helperText={errors && errors?.email?.message}
                // disabled
              />
            )}

            <Autocomplete
              options={designations || []}
              getOptionLabel={(role) => role}
              defaultValue={user?.designationName}
              onChange={(e, value) => {
                setValue("designation", value);
              }}
              renderInput={(params) => (
                <TextField
                  fullWidth
                  {...params}
                  required
                  name="designation"
                  label="Designation"
                  helperText={errors && errors?.designation?.message}
                />
              )}
            />

            <TextField
              name="password"
              required
              label="Password"
              type={showPassword ? "text" : "password"}
              autoComplete="new-password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              {...register("password")}
              helperText={errors && errors?.password?.message}
            />
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <Button
            onClick={onClose}
            size="medium"
            variant="contained"
            sx={{
              width: "40%",
              height: "3rem",
              borderRadius: "8px",
              color: "#4E79FA",
              backgroundColor: "white",
              transition: "background-color 0.3s, color 0.3s", // Add transition for smooth effect
              "&:hover": {
                backgroundColor: "#4E79FA",
                color: "white",
              },
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleSubmit(onSubmit)}
            sx={{
              width: "40%",
              height: "3rem",
              borderRadius: "8px",
              transition: "background-color 0.3s, color 0.3s", // Add transition for smooth effect
              backgroundColor: "#4E79FA",
              color: "white",
              "&:hover": {
                backgroundColor: "white",
                color: "#4E79FA",
              },
            }}
          >
            {user ? "Save" : "Add"}
          </Button>
        </DialogActions>
      </Dialog>
    </form>
  );
};
export default CreateEditTeam;
