import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import LoadingSpinner from "../../utils/LoadingSpinner";
import { GIT_URL } from "../../constant";
import { InputAdornment, TextField, Button } from "@mui/material";

function RepoDetails() {
  const { repoName } = useParams();
  const [repoData, setRepoData] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [loading, setLoading] = useState(true);

  const fetchRepoData = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${GIT_URL}/api/v1/git/get_commits_by_repo?repoName=${repoName}&page_no=${page}&per_page=${pageSize}`
      );
      const data = await response.json();
      if (data.success) {
        const rowsWithId = data.data.finaylArr.map((row, index) => ({
          ...row,
          id: index + 1,
        }));
        setRepoData(rowsWithId);
      } else {
        console.error("API request was not successful");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRepoData();
  }, [repoName, page, pageSize]); // Include 'page' and 'pageSize' in the dependency array

  
  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return date.toLocaleDateString(undefined, options);
  };

  const columns = [
    {
      field: "name",
      headerName: "Name",
      width: 220,
      renderCell: (params) => (
        <Link to={`/dashboard/githubDetails/${repoName}/${params.value}`} style={{ color: 'black' }}>
          {params.value}
        </Link>
      ),
    },
    {
      field: "date",
      headerName: "Date",
      width: 220,
      valueFormatter: (params) => formatDate(params.value),
    },
    {
      field: "message",
      headerName: "Message",
      width: 450,
    },
  ];

  const onPageChange = (newPage) => {
    setPage(newPage);
  };

  const startIndex = (page - 1) * pageSize;
  const endIndex = startIndex + pageSize;

  return (
    <div>
      <div className="mt-10 bg-white rounded-md w-[100%]">
        <div className="flex justify-between items-center">
          <div className="font-semibold text-xl ml-4 pt-4">
            Repository Details
          </div>
        </div>

        <div style={{ height: 400, width: "95%" }} className="mt-4 ml-4 pb-4">
          {loading ? (
            <LoadingSpinner />
          ) : (
            <>
              <DataGrid
                rows={repoData}
                columns={columns}
                autoHeight={false}
                sx={{
                  backgroundColor: "white",
                  "& .MuiDataGrid-columnHeaders": {
                    background: "FAFAFA",
                    color: "Black",
                  },
                  "& .MuiDataGrid-sortIcon": {
                    color: "black",
                  },
                }}
                rowHeight={40}
                hideFooterPagination
              />
            </>
          )}
        </div>
        <div className="pagination-controls ml-4">
                <Button onClick={() => onPageChange(page - 1)} disabled={page === 1}>
                  Previous
                </Button>
                <Button onClick={() => onPageChange(page + 1)}
                 >
                  Next
                </Button>
              </div>
      </div>
    </div>
  );
}

export default RepoDetails;
