import React, {
    useState,
    useEffect,
} from 'react';
import { Link } from 'react-router-dom';
import debounce from 'lodash/debounce';
import { useNavigate } from 'react-router-dom';
import {
    TextField,
    InputAdornment,
} from '@mui/material';
import LoadingSpinner from '../../utils/LoadingSpinner';
import SearchIcon from '@mui/icons-material/Search';
import { Form } from 'antd';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { getTimeSheet } from '../../apis/TimesheetApi';
import { CloseCircleOutlined } from '@ant-design/icons';
import TodoList from './TodoList';
import Todoinput from './Todoinput';
import './timesheet.css';
import SliderModal from './SliderModal';
import { Status } from '../../components';
import { PROJECT_ACTIVE_ENUM } from '../../enums';
import { useDebounce } from 'use-debounce';

const Timesheet = () => {
    const [form] = Form.useForm();
    const [getproject, setGetproject] = useState(
        [],
    );
    const [totalCount, setTotalCount] =
        useState(0);
    const [currentPage, setCurrentPage] =
        useState(0);
    const [projectsPerPage] = useState(9);
    const [editTasksData, setEditTasksData] =
        useState();

    const [listTodo, setListTodo] = useState([]);

    let addList = (inputText, inputTime) => {
        let taskObject = {
            name: inputText,
            time: inputTime,
        };
        setListTodo([...listTodo, taskObject]);
    };

    const deleteListItem = (key) => {
        setListTodo((prev) =>
            prev.filter(
                (item) => item.name !== key,
            ),
        );
    };
    // -------sider
    const [openSidebar, setOpenSidebar] =
        useState(false);
    const [selectedProject, setSelectedProject] =
        useState(false);

    const [
        selectedProjectName,
        setSelectedProjectName,
    ] = useState('');

    const [
        selectedProjectId,
        setSelectedProjectId,
    ] = useState('');
    const [tasks, setTasks] = useState([]);

    const [userInfo, setUserInfo] = useState();
    const [isFilled, setIsFilled] =
        useState(false);
    const [searchProject, setSearchProject] =
        useState('');
    const [searchValue] = useDebounce(
        searchProject,
        1000,
    );
    console.log(searchValue);
    const [updateTimesheet, setUpdateTimesheet] =
        useState(false);
    const [isLoading, setIsLoading] =
        useState(true);
    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    const timesheetFilledDirectly = true;

    const totalPages = Math.ceil(
        totalCount / projectsPerPage,
    );
    useEffect(() => {
        const username =
            localStorage.getItem('name');
        const role = localStorage.getItem(
            'designation',
        );

        const data = {
            username,
            role,
        };

        setUserInfo(data);
    }, []);

    const handleSidebarToggle = () => {
        setOpenSidebar(true);
        closeModal();
    };

    const handleSidebarClose = async () => {
        setOpenSidebar(false);
    };

    const getTimeFn = async () => {
        setIsLoading(true);
        try {
            const response = await getTimeSheet(
                searchValue,
                currentPage,
                projectsPerPage,
            );
            // const filteredProjects = response.data.filter(project =>
            //       project.projects.projectName.toLowerCase().includes(searchProject.toLowerCase())
            //     );
            console.log(response);
            setTotalCount(response.totalCount);
            // setCurrentPage(1);
            setGetproject(response.data);
        } catch (error) {
        } finally {
            setIsLoading(false); // Set loading state to false
        }
    };

    useEffect(() => {
        getTimeFn();
    }, [
        updateTimesheet,
        currentPage,
        searchValue,
    ]);

    // const debouncedSearch = debounce(
    //     async (searchTerm) => {
    //         setIsLoading(true);
    //         setSearchProject(searchTerm);
    //         try {
    //             const response =
    //                 await getTimeSheet(
    //                     searchTerm,
    //                 );
    //             // setCurrentPage(1);
    //             setGetproject(response.data);
    //         } catch (error) {
    //         } finally {
    //             setIsLoading(false);
    //         }
    //     },
    //     500,
    // );

    const indexOfLastProject =
        currentPage * projectsPerPage;
    const indexOfFirstProject =
        indexOfLastProject - projectsPerPage;
    const currentProjects = getproject.slice(
        indexOfFirstProject,
        indexOfLastProject,
    );

    const [open, setOpen] = useState(false);
    const [timeTaken, setTimeTaken] =
        useState('');
    const [selectedTaskId, setSelectedTaskId] =
        useState('');

    const saveTimeForTask = () => {
        const updatedTasks = tasks.map((task) =>
            task.id === selectedTaskId
                ? {
                      ...task,
                      timeTaken: timeTaken,
                  }
                : task,
        );
        setTasks(updatedTasks);
        setOpen(false);
    };

    const onTimeFieldChange = (e) => {
        setTimeTaken(e.target.value);
    };

    const openModal = (project) => {
        setSelectedProjectName(
            project.projectName,
        );
        setSelectedProjectId(project._id);
        setSelectedProject(true);

        const designation = localStorage.getItem(
            'designation',
        );
        if (designation === 'DEVELOPER') {
            setSelectedProject(true);
        } else {
            setSelectedProject(false);
        }
    };

    const closeModal = () => {
        setSelectedProject(null);
    };

    const navigate = useNavigate();

    const handleCardClick = (project) => {
        const taskDataEdit = {
            projectId: project.projects._id,
        };

        setEditTasksData(taskDataEdit);

        // Check the user's designation and open the modal only for developers
        const designation = localStorage.getItem(
            'designation',
        );
        if (designation === 'DEVELOPER') {
            openModal(project.projects);
        } else if (
            designation === 'AVP' ||
            designation === 'VP' ||
            designation === 'PM' ||
            designation === 'BA' ||
            designation === 'CSM' ||
            designation === 'CEO' ||
            designation === 'CTO' ||
            designation === 'COO'
        ) {
            // For other designations (AVP and VP), redirect to the appropriate URL
            const url = `/dashboard/ViewTimesheetPm?projectName=${project.projects.projectName}&projectId=${project.projects._id}`;
            navigate(url);
        }
    };

    const disabledDate = (current) => {
        return (
            current &&
            (current.day() === 0 ||
                current.day() === 6 ||
                current.isAfter(
                    new Date(),
                    'day',
                ))
        );
    };

    const designation = localStorage.getItem(
        'designation',
    );
    let url, urls;

    if (designation === 'DEVELOPER') {
        url = `/dashboard/viewtimesheet?projectName=${selectedProjectName}&projectId=${selectedProjectId}`;
    } else if (
        designation === 'PM' ||
        designation === 'AVP' ||
        designation === 'BA' ||
        designation === 'CSM' ||
        designation === 'CEO' ||
        designation === 'COO' ||
        designation === 'CTO' ||
        designation === 'VP'
    ) {
        urls = `/dashboard/ViewTimesheetPm?projectName=${selectedProjectName}&projectId=${selectedProjectId}`;
    }
    return (
        <div>
            <div
                style={{
                    backgroundColor: 'black',
                    color: 'white',
                    marginLeft: '1.8rem',
                    marginRight: '4rem',
                    marginTop: '1rem',
                    width: '98%',
                    height: '80px',
                    borderRadius: '0.375rem',
                    position: 'relative',
                }}
            >
                <div
                    style={{
                        background: 'black',
                        display: 'flex',
                        alignItems: 'center',
                        marginLeft: '20px',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection:
                                'column',
                        }}
                    >
                        <h1
                            style={{
                                color: 'white',
                                marginLeft:
                                    '10px',
                                marginTop: '9px',
                                fontSize:
                                    '1.125rem',
                            }}
                        >
                            {userInfo?.username}
                        </h1>
                        <h4
                            style={{
                                color: '#a0aec0',
                                marginLeft:
                                    '10px',
                                marginTop: '0px',
                            }}
                        >
                            {userInfo?.role}
                        </h4>
                    </div>
                    {/* searchbar  */}
                    <div
                        className="bg-white-300  "
                        style={{
                            position: 'absolute',
                            top: '5px',
                            right: '50px',
                        }}
                    >
                        <TextField
                            id="outlined-required"
                            name="keyword"
                            type={'text'}
                            sx={{
                                'bottom': '-6px',
                                'marginLeft':
                                    '6px',
                                'marginTop':
                                    '1px',
                                'height': '100%',
                                'width': '100%',
                                '& div': {
                                    height: '100%',
                                    backgroundColor:
                                        'white',
                                },
                            }}
                            size="small"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                            placeholder="Search Project/Developer Name"
                            onChange={(e) =>
                                setSearchProject(
                                    e.target
                                        .value,
                                )
                            }
                            autoComplete={'off'}
                        />
                    </div>
                </div>
            </div>
            {/* card */}
            {isLoading && <LoadingSpinner />}
            <div
                style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent:
                        'space-around',
                }}
            >
                {/* map over projects */}
                {getproject.map(
                    (project, index) => (
                        <div
                            key={index}
                            style={{
                                marginTop: '16px',
                                marginLeft:
                                    '27px',
                                borderRadius:
                                    '1rem',
                                width: '20rem',
                                height: '15rem',
                                top: '239px',
                                left: '93px',
                                cursor: 'pointer',
                                backgroundColor:
                                    'white',
                                boxShadow:
                                    'rgba(0, 0, 0, 0.16) 0px 3px 6px',
                            }}
                            onClick={() =>
                                handleCardClick(
                                    project,
                                )
                            }
                        >
                            <div
                                style={{
                                    backgroundColor:
                                        'black',
                                    color: 'white',
                                    paddingLeft:
                                        '1rem',
                                    paddingTop:
                                        '0.75rem',
                                    paddingBottom:
                                        '0.25rem',
                                    borderRadius:
                                        '1rem 1rem 0rem 0rem',
                                }}
                            >
                                <p
                                    variant="h5"
                                    sx={{
                                        font: 'Montserrat',
                                        fontSize:
                                            '32px',
                                        fontWeight:
                                            '500',
                                    }}
                                >
                                    {' '}
                                    {project.projects.projectName.toUpperCase()}
                                </p>
                                <p
                                    style={{
                                        fontSize:
                                            '14px',
                                    }}
                                >
                                    Client Name:{' '}
                                    {project.projects.clientName.toUpperCase()}
                                </p>

                                <p
                                    style={{
                                        fontSize:
                                            '14px',
                                    }}
                                >
                                    {
                                        <Status
                                            isActive={
                                                project?.status?.toLowerCase() ===
                                                PROJECT_ACTIVE_ENUM
                                            }
                                        />
                                    }
                                </p>
                            </div>

                            <div
                                style={{
                                    marginRight:
                                        '1rem',
                                    marginTop:
                                        '0.75rem',
                                    marginBottom:
                                        '0.75rem',
                                    marginLeft:
                                        '1rem',
                                    height: '10rem',
                                    overflowY:
                                        'auto',
                                }}
                            >
                                <h1
                                    style={{
                                        fontSize:
                                            '15px',
                                        marginBottom:
                                            '5px',
                                        fontWeight:
                                            'bold',
                                        borderBottom:
                                            '2px solid #F0F0F0',
                                    }}
                                >
                                    % Timesheet
                                    Filled
                                </h1>
                                {project.developers.map(
                                    (
                                        developer,
                                    ) => {
                                        const percentage =
                                            parseFloat(
                                                developer.percentage,
                                            );

                                        let color =
                                            'green';
                                        if (
                                            percentage <
                                            50
                                        ) {
                                            color =
                                                'red';
                                        } else if (
                                            percentage >=
                                                50 &&
                                            percentage <=
                                                80
                                        ) {
                                            color =
                                                'orange';
                                        }

                                        return (
                                            <div
                                                key={
                                                    developer._id
                                                }
                                                style={{
                                                    display:
                                                        'flex',
                                                    alignItems:
                                                        'center',
                                                    gap: '0.2rem',
                                                }}
                                            >
                                                <h2
                                                    style={{
                                                        fontSize:
                                                            '12px',
                                                        fontWeight:
                                                            'bold',
                                                        color: 'black',
                                                    }}
                                                >
                                                    {
                                                        developer.fullName
                                                    }

                                                    :
                                                </h2>
                                                <p
                                                    style={{
                                                        fontSize:
                                                            '12px',
                                                        color: color,
                                                    }}
                                                >
                                                    {
                                                        developer.percentage
                                                    }

                                                    %
                                                </p>
                                            </div>
                                        );
                                    },
                                )}
                            </div>
                        </div>
                    ),
                )}
            </div>

            {/* Pagination */}
            <div className="flex space-x-2 items-center justify-end mt-3">
                <button
                    onClick={() =>
                        paginate(currentPage - 1)
                    }
                    disabled={currentPage === 0}
                    className={`px-2 py-1 mb-2 bg-black text-white text-xs rounded ${
                        currentPage === 0
                            ? 'cursor-not-allowed'
                            : 'cursor-pointer'
                    }`}
                >
                    Prev
                </button>

                {/* Generate pagination buttons based on totalPages */}
                {[...Array(totalPages)].map(
                    (_, index) => {
                        const pageNumber =
                            index + 1; // Page numbers start from 1
                        return (
                            <button
                                key={pageNumber}
                                onClick={() =>
                                    paginate(
                                        pageNumber -
                                            1,
                                    )
                                } // Adjusting for zero-based index
                                className={`px-2 py-1 mb-2 bg-black text-white text-xs rounded ${
                                    currentPage ===
                                    pageNumber - 1
                                        ? 'bg-gray-500'
                                        : 'cursor-pointer'
                                }`}
                            >
                                {pageNumber}
                            </button>
                        );
                    },
                )}

                <button
                    onClick={() =>
                        paginate(currentPage + 1)
                    }
                    disabled={
                        currentPage ===
                        totalPages - 1
                    }
                    className={`px-2 py-1 mb-2 bg-black text-white text-xs rounded ${
                        currentPage ===
                        totalPages - 1
                            ? 'cursor-not-allowed'
                            : 'cursor-pointer'
                    }`}
                >
                    Next
                </button>
            </div>
            {/* <div className="flex space-x-2 items-center justify-end mt-3">
                <button
                    onClick={() =>
                        paginate(currentPage - 1)
                    }
                    disabled={currentPage === 1}
                    className={`px-2 py-1 mb-2 bg-black text-white text-xs rounded ${
                        currentPage === 1
                            ? 'cursor-not-allowed'
                            : 'cursor-pointer'
                    }`}
                >
                    Prev
                </button>
                {[...Array(totalPages)].map((_, index) => {
                    const pageNumber =
                        currentPage + index;
                    if (
                        pageNumber <= totalPages
                    ) {
                        return (
                            <button
                                key={pageNumber}
                                onClick={() =>
                                    paginate(
                                        pageNumber,
                                    )
                                }
                                className={`px-2 py-1 mb-2 bg-black text-white text-xs rounded ${
                                    currentPage ===
                                    pageNumber
                                        ? 'bg-black'
                                        : 'cursor-pointer'
                                }`}
                            >
                                {pageNumber}
                            </button>
                        );
                    }
                    return null;
                })}
                <button
                    onClick={() =>
                        paginate(currentPage + 1)
                    }
                    disabled={
                        currentPage === totalPages
                    }
                    className={`px-2 py-1 mb-2 bg-black text-white text-xs rounded ${
                        currentPage === totalPages
                            ? 'cursor-not-allowed'
                            : 'cursor-pointer'
                    }`}
                >
                    Next
                </button>
            </div> */}
            {/* modal */}

            {selectedProject && (
                <div
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor:
                            'rgba(0, 0, 0, 0.5)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 9999,
                        boxShadow:
                            '0 2px 5px rgba(0, 0, 0, 0.7)',
                    }}
                >
                    <div
                        className="rounded-md py-[2%]"
                        style={{
                            display: 'flex',
                            justifyContent:
                                'center',
                            alignItems: 'center',
                            backgroundColor:
                                'black',
                            height: '52vh',
                            width: '580px',
                            marginLeft: '190px',
                        }}
                    >
                        <div
                            style={{
                                backgroundColor:
                                    '',
                            }}
                        >
                            <div className="flex justify-end  bg-green-300  ml-[120%] ">
                                <div className="text-white  w-[50%] mt-7">
                                    <CloseCircleOutlined
                                        className="ml-30 text-xl mt-4 "
                                        onClick={
                                            closeModal
                                        }
                                    />
                                </div>
                            </div>
                            <div className=" ">
                                <div className=" mt-[10%]">
                                    <p
                                        className="pb-7 text-2xl  "
                                        style={{
                                            padding:
                                                '',
                                            color: 'white',
                                        }}
                                    >
                                        {selectedProjectName.toUpperCase()}
                                    </p>
                                </div>
                                <div
                                    className="mt-2 mb-4"
                                    style={{
                                        display:
                                            'flex',
                                        justifyContent:
                                            'center',
                                        alignItems:
                                            'center',
                                        backgroundColor:
                                            '',
                                        width: '350px',
                                        height: '200.63px',

                                        paddingBottom:
                                            '30%',
                                        gap: '135px',
                                    }}
                                >
                                    <div
                                        style={{
                                            display:
                                                'flex',
                                            justifyContent:
                                                'center',
                                            alignItems:
                                                'center',
                                            color: 'white',
                                        }}
                                    >
                                        {localStorage.getItem(
                                            'designation',
                                        ) ===
                                            'DEVELOPER' && (
                                            <div
                                                onClick={
                                                    handleSidebarToggle
                                                }
                                                style={{
                                                    cursor: 'pointer',
                                                }}
                                            >
                                                <CalendarTodayIcon
                                                    style={{
                                                        fontSize:
                                                            '70px',
                                                    }}
                                                />
                                                <h1 className="border-b pt-3"></h1>
                                                <p
                                                    className="pt-3"
                                                    style={{
                                                        fontSize:
                                                            '12px',
                                                    }}
                                                >
                                                    TimeSheet
                                                    Entry
                                                </p>
                                            </div>
                                        )}

                                        {/* end of condition */}
                                    </div>

                                    <div
                                        style={{
                                            display:
                                                'flex',
                                            justifyContent:
                                                'right',
                                            alignItems:
                                                'center',
                                            color: 'white',
                                        }}
                                    >
                                        <div>
                                            {/* <Link to="/dashboard/viewtimesheet"> */}
                                            {designation ===
                                                'DEVELOPER' && (
                                                <Link
                                                    to={
                                                        url
                                                    }
                                                >
                                                    <VisibilityIcon
                                                        projectName={
                                                            selectedProjectName
                                                        }
                                                        className=""
                                                        style={{
                                                            fontSize:
                                                                '70px',
                                                            color: 'white',
                                                        }}
                                                    />
                                                </Link>
                                            )}

                                            <h1 className="border-b pt-2"></h1>
                                            <p
                                                className="pt-3"
                                                style={{
                                                    fontSize:
                                                        '12px ',
                                                }}
                                            >
                                                View
                                                TimeSheet
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* siderr */}
                </div>
            )}
            {/* sidder */}
            {openSidebar && (
                <SliderModal
                    isFilled={isFilled}
                    setIsFilled={setIsFilled}
                    openSidebar={openSidebar}
                    selectedProjectName={
                        selectedProjectName
                    }
                    handleSidebarClose={
                        handleSidebarClose
                    }
                    disabledDate={disabledDate}
                    addList={addList}
                    listTodo={listTodo}
                    setListTodo={setListTodo}
                    saveTimeForTask={
                        saveTimeForTask
                    }
                    onTimeFieldChange={
                        onTimeFieldChange
                    }
                    form={form}
                    Todoinput={Todoinput}
                    TodoList={TodoList}
                    deleteListItem={
                        deleteListItem
                    }
                    open={open}
                    setOpen={setOpen}
                    editTasksData={editTasksData}
                    setUpdateTimesheet={
                        setUpdateTimesheet
                    }
                    timesheetFilledDirectly={
                        timesheetFilledDirectly
                    }
                />
            )}
        </div>
    );
};
export default Timesheet;
