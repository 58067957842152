import React, { useState, useEffect, useRef } from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  InputAdornment,
  TextField,
  Button,
} from "@mui/material";
import { Link } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import LoadingSpinner from "../../utils/LoadingSpinner";
import { GIT_URL } from "../../constant";

function Githubdetails() {
  const [selectedRepo, setSelectedRepo] = useState(null);
  const [reposData, setReposData] = useState([]);
  const [repoName, setRepoName] = useState("");
  const [loading, setLoading] = useState(true);
  const [filteredData, setFilteredData] = useState([]);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [page, setPage] = useState(1);
  const pageSize = 100; // You can adjust this based on your requirements

  const dataGridRef = useRef();

  useEffect(() => {
    // Filter the data based on repoName
    const filteredRepos = reposData.filter((repo) =>
      repo.name.toLowerCase().includes(repoName.toLowerCase())
    );
    setFilteredData(filteredRepos);
  }, [repoName, reposData]);

  const handleRepoChange = (selectedOption) => {
    setSelectedRepo(selectedOption);
  };

  const columns = [
    {
      field: "name",
      headerName: "Repo Name",
      width: 220,
      renderCell: (params) => (
        <Link to={`/dashboard/githubDetails/${params.value}`} style={{ color: 'black' }}>
          {params.value}
        </Link>
      ),
    },
    {
      field: "owner",
      headerName: "Owner",
      width: 220,
    },
    {
      field: "repo_url",
      headerName: "URL",
      width: 450,
      renderCell: (params) => (
        <a href={params.value} target="_blank" rel="noreferrer">
          {params.value}
        </a>
      )
    },
  ];

  const fetchMoreReposData = async () => {
    if (!hasMoreData) return;

    setLoading(true);
    try {
      const response = await fetch(
        `${GIT_URL}/api/v1/git/list_org_repos?page=${page}&per_page=${pageSize}`
      );
      const data = await response.json();
      if (data.success) {
        const newRepos = data.data.finalData.map((repo, index) => ({
          ...repo,
          id: reposData.length + index + 1,
        }));
        setReposData((prevData) => [...prevData, ...newRepos]);
        setPage(page + 1); // Increment the page number
        setHasMoreData(newRepos.length === pageSize); // Check if there's more data
      }
    } catch (error) {
      // console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMoreReposData();
  }, []); // Load initial data

  const handleScroll = () => {
    // Check if the user has scrolled to the bottom of the data grid
    const dataGridElement = dataGridRef.current;
    if (
      dataGridElement &&
      dataGridElement.clientHeight + dataGridElement.scrollTop >=
        dataGridElement.scrollHeight
    ) {
      fetchMoreReposData(); // Fetch more data when the user scrolls to the bottom
    }
  };

  return (
    <div>
      <div className="mt-1 bg-white rounded-md w-[100%] h-[85vh]">
        <div className="flex justify-between items-center">
          <div className="font-semibold text-xl ml-4 pt-4">GitHub details</div>
        </div>

        <div className="mt-4 ml-4">
          <TextField
            size="small"
            placeholder="Search Repo Name"
            value={repoName}
            onChange={(e) => setRepoName(e.target.value)}
            autoComplete={"off"}
            sx={{
              width: "25rem",
              borderRadius: "8px",
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </div>

        <div
          style={{ height: 400, width: "95%" }}
          className="mt-4 ml-4 pb-4"
          onScroll={handleScroll}
          ref={dataGridRef}
        >
          {loading ? (
            <LoadingSpinner />
          ) : (
            <>
              <DataGrid
                rows={filteredData}
                columns={columns}
                autoHeight={false}
                sx={{
                  backgroundColor: "white",
                  "& .MuiDataGrid-columnHeaders": {
                    background: "FAFAFA",
                    color: "Black",
                  },
                  "& .MuiDataGrid-sortIcon": {
                    color: "black",
                  },
                }}
                hideFooterPagination
                filterModel={{
                  items: [
                    {
                      columnField: "name",
                      operatorValue: "contains",
                      value: `${repoName}`,
                    },
                  ],
                }}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Githubdetails;
