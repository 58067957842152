import { BASE_URL } from '../constant';
import api from '../apiclient';

export const getTimeSheet = async (
    searchValue,
    currentPage,
    projectsPerPage,
) => {
    try {
        // const response = await api.get(
        //     `${BASE_URL}/api/v2/projects/viewall?name=${
        //         searchProject ? searchProject : ''
        //     }`,
        // );
        const response = await api.get(
            `${BASE_URL}/api/v2/projects/viewall?page=${currentPage}&limit=${projectsPerPage}&searchKey=projectName&searchValue=${
                searchValue ? searchValue : ''
            }`,
        );

        if (response.success) {
            return response.data.data;
        }

        return response.data;
    } catch (error) {
        // console.log(error);
    }
};

export const createTimesheet = async (
    timesheetData,
) => {
    try {
        const response = await api.post(
            `${BASE_URL}/api/v2/timesheet/createtimesheet`,
            timesheetData,
        );
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const getTaskSheet = async (
    startDate,
    endDate,
    projectId,
) => {
    try {
        const response = await api.get(
            `${BASE_URL}/api/v2/timesheet/doGetTimesheetbyUsers?startDate=${startDate}&endDate=${endDate}&projectId=${projectId}`,
        );

        if (response.success) {
            return response.data.data;
        }
        return response.data;
    } catch (error) {
        // console.log(error);
    }
};

export const editTaskSheet = async (
    timesheetId,
    payload,
) => {
    try {
        const response = await api.put(
            `${BASE_URL}/api/v2/timesheet/doEditTimeSheet?timesheetId=${timesheetId}`,
            payload,
        );

        // await getTaskSheet();
        if (response.success) {
            return response.data.data;
        }
        return response.data;
    } catch (error) {
        // console.log(error);
    }
};

export const getTaskSheetbyuser = async (
    startDate,
    endDate,
    projectId,
    userId,
) => {
    try {
        const response = await api.get(
            `${BASE_URL}/api/v2/timesheet/doGetAllTimesheet?userId=${userId}&startDate=${startDate}&endDate=${endDate}&projectId=${projectId}`,
        );
        if (response.success) {
            return response.data.data;
        }
        return response.data;
    } catch (error) {
        // console.log(error);
    }
};
