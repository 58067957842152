import { Button, DatePicker, Modal, Table, Tooltip } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import LoadingSpinner from "../../../utils/LoadingSpinner";
import dayjs from "dayjs";

const ConfirmAvailabilityModal = ({
  open,
  onClose,
  updateAvailabilityHandler,
  name,
  availability,
}) => {
  const { otherEngagements, isLoading } = useSelector(
    (store) => store.getUsers,
  );
  const [availableDate, setAvailableDate] = useState(null);
  const [error, setError] = useState(false);

  const rows =
    otherEngagements.length > 0 &&
    otherEngagements.map((engagement) => {
      return {
        engagementType: engagement.engagementType,
        projectName: engagement.projectName,
      };
    });

  const columns = [
    {
      title: "Project Name",
      dataIndex: "projectName",
      width: 80,
      align: "start",
      render: (text) => (
        <Tooltip title={text} placement="right" arrow>
          <span>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: "Engagement Type",
      dataIndex: "engagementType",
      width: 60,
      render: (text) => (
        <Tooltip title={text} placement="right" arrow>
          <span>{text}</span>
        </Tooltip>
      ),
    },
  ];

  const handleAvailabilityDate = (value) => {
    const date = dayjs(value).format("YYYY-MM-DD");
    setAvailableDate(date);
  };

  return (
    <Modal
      open={open}
      onCancel={onClose}
      width={500}
      bodyStyle={isLoading ? { maxHeight: "30vh" } : {}}
      footer={[
        <Button key="back" onClick={onClose}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={() => {
            if (availability === "Available" && !availableDate) {
              setError(true);
            } else {
              updateAvailabilityHandler({ availableDate });
              onClose();
            }
          }}
        >
          Confirm
        </Button>,
      ]}
    >
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          {(availability === "Available" ||
            availability === "mayBeAvailable") && (
            <div>
              <h4>Available From</h4>
              <DatePicker
                selected={availableDate}
                onChange={handleAvailabilityDate}
                placeholder="Select depart date"
              />
              {error && <div style={{ color: "red" }}>Please Select Date</div>}
            </div>
          )}
          {otherEngagements.length > 0 && (
            <>
              <h2 className="mb-[20px]">Other engagements</h2>
              <Table
                dataSource={rows}
                columns={columns}
                rowKey="id"
                size="middle"
                pagination={false}
              />
            </>
          )}
          <p style={{ marginTop: "20px" }}>
            Are you sure you want to change the availability of{" "}
            <span className="text-red-500 font-bold">{name}</span> ?
          </p>
        </>
      )}
    </Modal>
  );
};

export default ConfirmAvailabilityModal;
