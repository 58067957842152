import dayjs from "dayjs";
import api from "../apiclient";

export const getProject = async (id) => {
  const res = await api.get(`api/v2/projects/getDetails?projectId=${id}`);
  return res?.data;
};

export const onboardDeveloper = async (data) => {
  const formattedDate = dayjs(data.onBoardingDate).format("YYYY-MM-DD");
  const isoDate = new Date(formattedDate).toISOString();
  const data1 = { ...data, onBoardingDate: isoDate };
  const res = await api.post("/api/v2/projects/onboard", data1);
  return res?.data;
};

export const assignProjectManager = async (data) => {
  const res = await api.post("/api/v2/projects/onboard", data);
  return res?.data;
};
export const unAssignProjectManager = async (data) => {
  const res = await api.post("/api/v2/projects/offboard", data);
  return res?.data;
};

export const addProject = async (payload) => {
  const res = await api.post("api/v2/projects/project-add", payload);
  return res?.data;
};

export const editProject = async (payload) => {
  const res = await api.put("api/v2/projects/project-update", payload);
  return res?.data;
};

export const getProjectDetails = async (payload) => {
  const res = await api.get(
    `api/v2/projects/getProjectHistory?projectId=${payload}`
  );

  return res?.data;
};
