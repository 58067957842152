import * as React from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { Button, InputAdornment, TextField, Typography } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { IconButton } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  getGroups,
  removeDeveloper,
} from "../../Redux/Slices/Groups/GroupsSlice";
import SearchIcon from "@mui/icons-material/Search";
import { useDispatch, useSelector } from "react-redux";
import AddGroup from "./AddGroup";
import { useState } from "react";
import {
  getAllProjectManagers,
  getDevelopers,
} from "../../Redux/Slices/Lists/ListsSlice";
import AddRemoveDeveloper from "./AddRemoveDeveloper";
import ConfirmationDialog from "../../utils/ConfirmationDialog";
import TryingDeveloperModal from "../../utils/TryingDeveloperModal";
import { deleteGroup, getAvailableDevelopers } from "../../apis/GroupApi";
import { getGroupDevelopers } from "../../Redux/Slices/Groups/GroupsSlice";
import { toast } from "react-toastify";
import { AddCircleOutlineOutlined } from "@mui/icons-material";
import LoadingSpinner from "../../utils/LoadingSpinner";
import { useEffect } from "react";
import dayjs from "dayjs";
import ActionDialog from "./Components/ActionDialog";
import { makeStyles } from "@mui/styles";
import "../../Assets/CSS/Groups/groups.css";
import ProjectStatus from "./Components/ProjectStatus";
import { useNavigate } from "react-router-dom";

export default function Groups() {
  const dispatch = useDispatch();
  const [openAddGroup, setOpenAddGroup] = useState(false);
  const [openAddDeveloper, setOpenAddDeveloper] = useState(false);
  const { groups, isLoading } = useSelector((store) => store.groups);
  const { projectManagers } = useSelector((store) => store?.lists);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(10);
  const [developersList, setDevelopersList] = useState([]);
  const [developerDropdown, setDeveloperDropdown] = useState([]);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [groupId, setGroupId] = useState("");
  const [groupName, setGroupName] = useState("");
  const [selectedGroupName, setSelectedGroupName] = useState("");
  const [openDeveloperModal, setOpenDeveloperModal] = useState(false);
  const [status, setStatus] = useState(false);
  const [groupData, setGroupData] = useState(null);
  const [projectManager, setProjectManagers] = useState([]);
  const [openActionModalgrp, setOpenActionModalgrp] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const role = localStorage.getItem("designation");
  const navigate = useNavigate();

  const useStyles = makeStyles({
    actionColumn: {
      backgroundColor: "white",
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.8)",
    },

    actionHeader: {
      backgroundColor: "#FAFAFA",
      color: "black",
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.8)",
    },
  });

  const classes = useStyles();

  function capitalizeFirstLetter(string) {
    return string?.charAt(0)?.toUpperCase() + string.slice(1);
  }

  useEffect(() => {
    dispatch(getGroups(status));
    dispatch(getDevelopers());
    dispatch(getAllProjectManagers({ role: "PM" }));
  }, [status]);

  useEffect(() => {
    if (groupId) {
      const formattedSelectedDate = dayjs(selectedDate).format("YYYY-MM-DD");
      const formattedSelectedDateISO = new Date(
        formattedSelectedDate
      ).toISOString();
      dispatch(
        getGroupDevelopers({
          id: groupId,
          selectedDate: formattedSelectedDateISO,
        })
      ).then((data) => {
        const dev = data.payload.data;
        setDevelopersList(dev.developers);
        setLoading(false);
        setProjectManagers(dev.pms);
      });
    }

    if (!developerDropdown.length > 0) {
      getAvailableDevelopers().then((data) => {
        setDeveloperDropdown(data);
      });
    }
  }, [selectedDate, groupId]);

  const closeDeveloperHandler = () => {
    setOpenAddDeveloper(false);
    setGroupId("");
  };

  const handleCloseDeveloperModal = () => {
    setOpenDeveloperModal(false);
    setLoading(true);
    setGroupId("");
    setSelectedDate(new Date());
  };

  const removeGroup = () => {
    deleteGroup(groupId).then(() => {
      setIsSuccess(true);
      toast("Group Deleted Successfully");
      dispatch(getGroups(status));
    });
    setOpenActionModalgrp(false);
  };

  const editModalHandler = () => {
    setOpenAddGroup(true);
    setOpenActionModalgrp(false);
  };

  const handleDelete = (e) => {
    setIsSuccess(false);
    setOpenConfirmDialog(true);
    setGroupId(e.target.id);
  };

  const handleProjectStatus = () => {
    navigate(`${selectedGroupName}`, {
      state: { groupId: groupId, groupName: selectedGroupName },
    });
  };
  const columns = [
    {
      field: "groupName",
      headerName: "Group Name",
      width: 200,
      headerAlign: "left",
      align: "left",

      renderCell: (params) => {
        const capitalizedResponse = capitalizeFirstLetter(
          params?.formattedValue
        );
        return (
          <Tooltip title={params?.formattedValue} placement="right" arrow>
            <span>{capitalizedResponse}</span>
          </Tooltip>
        );
      },
    },
    {
      field: "assignedPm",
      headerName: "Assigned PM",
      width: 500,
      headerAlign: "left",
      sortable: true,
      valueGetter: (params) => {
        return params?.value
          ?.map((item) => (item?.lastname ? item.fullName : item?.fullName))
          .join(", ");
      },
      renderCell: (params) => {
        const assignedPm =
          params?.formattedValue?.length === 0 ? "N/A" : params?.formattedValue;
        return (
          <Tooltip title={assignedPm} placement="right" arrow>
            <span>{assignedPm}</span>
          </Tooltip>
        );
      },
    },

    {
      field: "developers",
      headerName: "Developers",
      width: 200,
      align: "center",
      headerAlign: "center",
      sortable: true,
      renderCell: (params) => {
        return params?.formattedValue?.length ? (
          <div>
            <Button
              onClick={() => {
                setGroupId(params?.row?.id);

                setOpenDeveloperModal(true);
              }}
              style={{
                textDecoration: "underline",
                cursor: "pointer",
              }}
            >
              {params?.formattedValue?.length}
            </Button>
          </div>
        ) : (
          <Typography component="p" sx={{ cursor: "context-menu" }}>
            {params?.formattedValue?.length}
          </Typography>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      headerAlign: "center",
      align: "center",
      minWidth: 200,
      flex: 0.5,
      headerClassName: classes.actionHeader,
      cellClassName: classes.actionColumn,

      renderCell: (params) => {
        return (
          <Tooltip title="Perform Action" placement="top">
            <IconButton
              aria-label="more"
              aria-controls="action-menu"
              aria-haspopup="true"
              onClick={(e) => {
                setGroupId(params?.row?.id);
                setGroupData(params.row);
                e.stopPropagation();
                setOpenActionModalgrp(true);
                setSelectedGroupName(params?.row?.groupName);
              }}
            >
              <MoreVertIcon />
            </IconButton>
          </Tooltip>
        );
      },
    },
  ];

  const rows = groups?.map((group) => {
    return {
      id: group?.group[0]?._id,
      groupName: group?.group[0]?.groupName,
      assignedPm: group?.pms,
      developers: group?.developers,
      teamLead: group?.tl ? group?.tl : "N/A",
    };
  });

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <Box className="h-[85vh] bg-white py-2.5 px-8 rounded-[16px]">
      <Box className="flex text-center justify-between">
        <Box>
          <Typography
            variant="h5"
            sx={{
              font: "Montserrat",
              fontSize: "32px",
              fontWeight: "500",
            }}
          >
            Groups
          </Typography>
        </Box>

        {role !== "PM" &&
          role !== "SALES" &&
          role !== "HR" &&
          role !== "BA" &&
          role !== "CSM" && (
            <Button
              variant="contained"
              sx={{}}
              onClick={() => {
                setGroupData(null);
                setOpenAddGroup(true);
              }}
              className="add-group-button"
            >
              <IconButton
                color="inherit"
                onClick={() => {
                  setGroupData(null);
                  setOpenAddGroup(true);
                }}
                className="mx-0.5"
              >
                <AddCircleOutlineOutlined />
              </IconButton>
              Add Group
            </Button>
          )}
      </Box>
      <div className="my-2.5">
        <TextField
          size="small"
          placeholder="Search Group Name"
          value={groupName}
          autoComplete={"off"}
          sx={{
            width: "25rem",
            borderRadius: "8px",
          }}
          onChange={(e) => {
            setGroupName(e.target.value);
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </div>
      <Box className="w-[100%] h-[80%]">
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={page}
          getRowId={(rows) => rows?.id}
          onPageSizeChange={(val) => setPage(val)}
          rowsPerPageOptions={[10, 25, 50, 100]}
          disableSelectionOnClick
          disableColumnMenu
          experimentalFeatures={{ newEditingApi: true }}
          sx={{
            ".MuiDataGrid-columnSeparator": {
              display: "none",
            },
            "&.MuiDataGrid-root": {
              border: "none",
            },
            backgroundColor: "white",
            "& .MuiDataGrid-columnHeaders": {
              background: "#FAFAFA",
              color: "black",
            },
            "& .MuiDataGrid-sortIcon": {
              color: "white",
            },
            "& 	.MuiDataGrid-menuIconButton": {
              color: "white",
            },
            "& 	.MuiDataGrid-filterIcon": {
              color: "white",
            },
          }}
          rowHeight={40}
          filterModel={{
            items: [
              {
                columnField: "groupName",
                operatorValue: "contains",
                value: `${groupName}`,
              },
            ],
          }}
        />
      </Box>

      {openConfirmDialog && (
        <ConfirmationDialog
          open={openConfirmDialog}
          message={"Are you sure you want to delete group ?"}
          onClose={() => setOpenConfirmDialog(false)}
          onConfirm={removeGroup}
          isSuccess={isSuccess}
        />
      )}

      {openDeveloperModal && (
        <TryingDeveloperModal
          open={openDeveloperModal}
          onClose={handleCloseDeveloperModal}
          data={developersList}
          pms={projectManager}
          id={groupId}
          isGroup={true}
          removeDeveloper={removeDeveloper}
          fetchAgain={getGroups}
          type="group"
          loading={loading}
          setLoading={setLoading}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
        />
      )}
      {openAddGroup && (
        <AddGroup
          open={openAddGroup}
          onClose={() => setOpenAddGroup(false)}
          projectManagers={projectManagers}
          group={groupData}
          status={status}
        />
      )}

      {openAddDeveloper && (
        <AddRemoveDeveloper
          open={openAddDeveloper}
          onClose={closeDeveloperHandler}
          developers={developerDropdown}
          groupId={groupId}
        />
      )}

      {openActionModalgrp && (
        <ActionDialog
          handleDelete={handleDelete}
          editModalHandler={editModalHandler}
          addDeveloper={() => setOpenAddDeveloper(true)}
          open={openActionModalgrp}
          onClose={() => setOpenActionModalgrp(false)}
          handleProjectStatus={handleProjectStatus}
        />
      )}
    </Box>
  );
}
