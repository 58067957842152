import React, { useEffect, useState } from 'react';
import { getProjectDetails } from "../../../apis/ProjectApi"; // Corrected function name
import { useLocation } from "react-router-dom";

import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import dayjs from "dayjs";
import { Box, Typography } from "@mui/material";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import CheckCircleIcon from "@mui/icons-material/CheckCircle"; // Material-UI checkmark icon
import "./style.css";
import LoadingSpinner from "../../../utils/LoadingSpinner";

function ProjectDetailsNewPage() {
  const [projectDetail, setProjectDetails] = useState([]);
  const [eventDetails, setEventsDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { state } = useLocation();
  const id = state.id;

  useEffect(() => {
    getProjectDetails(id)
      .then((res) => {
        const formattedData = res?.data?.projectHistory?.map((d) => ({
          ...d,
          date: dayjs(d.date).format("DD-MMM-YYYY"),
        }));
        const formattedEvent = res?.data?.eventData?.map((event) => ({
          ...event,
          estimatedEnddate: dayjs(event?.estimatedEnddate).format("DD-MM-YYYY"),
          completionDate: dayjs(event?.completionDate).format("DD-MM-YYYY"),
        }));
        const filterCompleteEvent = formattedEvent.filter(
          (event) => event.isCompleted === true
        );
        setProjectDetails(formattedData);
        setEventsDetails(filterCompleteEvent);
        setIsLoading(false);
      })
      .catch((error) => console.log(error?.response?.data?.message));
  }, [id]);

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <>
      <div className="bg-white rounded-xl h-[85vh] overflow-y-auto">
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "end",
            position: "sticky",
            top: "0",
            backgroundColor: "white",
            zIndex: "999",
            height: "2rem",
          }}
        >
          <h1
            style={{
              fontWeight: "500",
              fontSize: "1.2rem",
              marginBotton: "1rem",
            }}
          >
            Events Completion Details
          </h1>
          <h1
            style={{
              fontWeight: "500",
              fontSize: "1.2rem",
              marginBotton: "1rem",
            }}
          >
            Onboarding/Offboarding Details
          </h1>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Timeline
              style={{
                width: "35vw",
                maxHeight: "fitContent",
              }}
            >
              {eventDetails.length > 0 ? (
                eventDetails.map((event) => (
                  <TimelineItem position={event.days < 0 ? "left" : "right"}>
                    <TimelineSeparator>
                      <TimelineDot>
                        {event?.isCompleted && (
                          <CheckCircleIcon
                            sx={{ color: event?.days >= 0 ? "#000080" : "red" }}
                          />
                        )}
                      </TimelineDot>
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                      {event?.isCompleted && (
                        <Box
                          sx={{
                            textAlign: event?.days >= 0 ? "start" : "end",
                          }}
                        >
                          <Typography
                            className="headingOn"
                            sx={{
                              color: event?.days >= 0 ? "#000080" : "red",
                            }}
                          >
                            {event?.eventName}
                          </Typography>
                          <Typography
                            className="headingOff"
                            sx={{
                              color: event?.days >= 0 ? "#000080" : "red",
                            }}
                          >
                            {event?.eventValue}
                          </Typography>
                          <Typography
                            sx={{
                              color: event?.days >= 0 ? "#000080" : "red",
                            }}
                          >
                            Estimated: {event?.estimatedEnddate}
                          </Typography>
                          <Typography
                            sx={{
                              color: event?.days >= 0 ? "#000080" : "red",
                            }}
                          >
                            Completion: {event?.completionDate}
                          </Typography>
                          <Typography
                            sx={{
                              color: event?.days >= 0 ? "#000080" : "red",
                            }}
                          >
                            {event?.days >= 0
                              ? `Completed before ${event?.days} days from estimated date`
                              : `Completed after ${Math.abs(
                                  event?.days
                                )} days from estimated date`}
                          </Typography>
                        </Box>
                      )}
                    </TimelineContent>
                  </TimelineItem>
                ))
              ) : (
                <p className="text-center text-red-600">No Data</p>
              )}
            </Timeline>
          </Box>
          <Box>
            <Timeline
              style={{
                maxHeight: "fitContent",
                width: "45vw",
              }}
            >
              {projectDetail &&
                projectDetail?.map((d) => (
                  <TimelineItem
                    position={d.action.includes("On") ? "right" : "left"}
                    sx={{
                      display: "flex",
                      alignContent: "center",
                      justifyContent: "center",
                    }}
                  >
                    <TimelineSeparator>
                      {d.action.includes("On") ? (
                        <PersonAddAlt1Icon
                          sx={{
                            color:
                              d.action === "Onboarded" ? "#3AE81B" : "#08BDF4",
                          }}
                        />
                      ) : (
                        <PersonRemoveIcon
                          sx={{
                            color:
                              d.action === "Offboarded" ? "#DB2D43" : "orange",
                          }}
                        />
                      )}
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                      {d.action.includes("On") ? (
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "start",
                            wordWrap: "nowrap",
                          }}
                        >
                          <Typography
                            className="headingOn"
                            sx={{
                              color:
                                d.action === "Onboarded"
                                  ? "#3AE81B"
                                  : "#08BDF4",
                            }}
                          >
                            {d?.action}
                          </Typography>
                          <Typography
                            sx={{
                              color:
                                d.action === "Onboarded" ? "black" : "black",
                            }}
                          >
                            {d?.date}
                          </Typography>
                          <Typography
                            sx={{
                              color:
                                d.action === "Onboarded" ? "black" : "black",
                            }}
                          >
                            {d?.description}
                          </Typography>
                        </Box>
                      ) : (
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            textAlign: "end",
                            wordWrap: "break-word",
                          }}
                        >
                          <Box
                            sx={{
                              textAlign: "end",
                            }}
                          >
                            <Typography
                              className="headingOff"
                              sx={{
                                color:
                                  d.action === "Offboarded"
                                    ? "#DB2D43"
                                    : "orange",
                              }}
                            >
                              {d?.action}
                            </Typography>
                            <Typography
                              sx={{
                                color:
                                  d.action === "Offboarded" ? "black" : "black",
                              }}
                            >
                              {d?.date}
                            </Typography>
                          </Box>
                          <Typography
                            sx={{
                              color:
                                d.action === "Offboarded" ? "black" : "black",
                            }}
                          >
                            {d?.description}
                          </Typography>
                        </Box>
                      )}
                    </TimelineContent>
                  </TimelineItem>
                ))}
            </Timeline>
          </Box>
        </div>
      </div>
    </>
  );
}

export default ProjectDetailsNewPage;
