import dayjs from "dayjs";
import { debounce } from "lodash";
import React, { useEffect, useState } from "react";
import Reports from "./Reports";
import {
  getProjectStatusHistory,
  projectStatusDownload,
} from "../../../Redux/Slices/Lists/ListsSlice";
import { useDispatch, useSelector } from "react-redux";
import { getGroups } from "../../../Redux/Slices/Lists/ListsSlice";
import { Tooltip } from "antd";
import { toast } from "react-toastify";

const ProjectStatusReport = () => {
  const dispatch = useDispatch();
  const [endDate, setEndDate] = useState(
    dayjs().endOf("week").add(1, "day").format("YYYY-MM-DD")
  );
  const [startDate, setStartDate] = useState(
    dayjs().endOf("week").subtract(54, "days").format("YYYY-MM-DD")
  );

  const { projectStatus, groups, searchFilters } = useSelector(
    (store) => store.lists
  );
  const [name, setName] = useState("");
  const showProjectStatusFilters = true;

  const handleDateChange = (value) => {
    const endOfWeek = dayjs(value).endOf("week");
    const endOfSunday = endOfWeek.add(1, "day");

    setEndDate(endOfSunday.format());
    const startDate = endOfSunday.clone().subtract(55, "days").format();

    setStartDate(startDate);
  };

  const handleSearch = debounce((name) => {
    const formattedStartDate = dayjs(startDate).format("YYYY-MM-DD");
    const formattedStartISO = new Date(formattedStartDate).toISOString();
    const formattedEndDate = dayjs(endDate).format("YYYY-MM-DD");
    const formattedEndDateISO = new Date(formattedEndDate).toISOString();
    dispatch(
      getProjectStatusHistory({
        startDate: formattedStartISO,
        endDate: formattedEndDateISO,
        name: name,
      })
    );
  }, 800);

  const handleDownload = () => {
    const formattedStartDate = dayjs(startDate).format("YYYY-MM-DD");
    const formattedStartISO = new Date(formattedStartDate).toISOString();
    const formattedEndDate = dayjs(endDate).format("YYYY-MM-DD");
    const formattedEndDateISO = new Date(formattedEndDate).toISOString();
    dispatch(
      projectStatusDownload({ formattedStartISO, formattedEndDateISO, name })
    ).then((response) => {
      toast(response.payload.message);
    });
    toast.success("Downloading CSV file");
  };

  const color = (text) => {
    const common = {
      padding: "5px",
      borderRadius: "14px",
      font: "Lato",
      fontWeight: "500",
      fontSize: "16px",
      textAlign: "center",
      width: "fitContent",
    };

    if (text === "Need Improvement") {
      return { color: "white", backgroundColor: "#DEF779", ...common };
    }
    if (text === "All Good") {
      return { color: "white", backgroundColor: "#74D9A1", ...common };
    }
    if (text === "Critical") {
      return { color: "white", backgroundColor: "#800000", ...common };
    }
    if (text === "Not Filled") {
      return { color: "white", backgroundColor: "#CCCCCC", ...common };
    } else {
      return {
        color: "white",
        backgroundColor: "#997fd1",
        ...common,
        width: "fitContent",
      };
    }
  };

  useEffect(() => {
    const formattedStartDate = dayjs(startDate).format("YYYY-MM-DD");
    const formattedStartISO = new Date(formattedStartDate).toISOString();
    const formattedEndDate = dayjs(endDate).format("YYYY-MM-DD");
    const formattedEndDateISO = new Date(formattedEndDate).toISOString();

    dispatch(
      getProjectStatusHistory({
        startDate: formattedStartISO,
        endDate: formattedEndDateISO,
        name: name,
      })
    );
    if (groups) {
      dispatch(getGroups());
    }
  }, [endDate, searchFilters]);

  const rows =
    projectStatus?.length > 0 &&
    projectStatus?.map((project) => {
      return {
        projectName: project.projectName,
        group: project.group,
        typeOfProject: project.typeOfProject,
        projectManager: project.PM,
        week1: project.projectStatus[0],
        week2: project.projectStatus[1],
        week3: project.projectStatus[2],
        week4: project.projectStatus[3],
        week5: project.projectStatus[4],
        week6: project.projectStatus[5],
        week7: project.projectStatus[6],
        week8: project.projectStatus[7],
      };
    });

  const data1 =
    projectStatus &&
    projectStatus.length > 0 &&
    projectStatus[0]?.projectStatus.map((e, i) => {
      let startDate = new Date(e?.weekStartDate);
      let endDate = new Date(e?.weekEndDate);

      let startDateTitle = startDate.toLocaleString("en-IN", {
        timeZone: "UTC",
        day: "2-digit",
        month: "short",
      });
      let endDateTitle = endDate.toLocaleString("en-IN", {
        timeZone: "UTC",
        day: "2-digit",
        month: "short",
      });

      let week = dayjs(startDate).format("wo");

      return {
        title: `Week ${week}, Status (${
          startDateTitle + " to " + endDateTitle
        })`,
        dataIndex: `week${i + 1}`,
        width: 215,
        render: (text, record) => {
          return <p style={color(text.status)}>{text.status}</p>;
        },
      };
    });

  const data2 =
    projectStatus &&
    projectStatus.length > 0 &&
    projectStatus[0]?.projectStatus.map((e, i) => {
      let startDate = new Date(e?.weekStartDate);
      let endDate = new Date(e?.weekEndDate);
      let week = dayjs(startDate).format("wo");

      let startDateTitle = startDate.toLocaleString("en-IN", {
        timeZone: "UTC",
        day: "2-digit",
        month: "short",
      });
      let endDateTitle = endDate.toLocaleString("en-IN", {
        timeZone: "UTC",
        day: "2-digit",
        month: "short",
      });


      return {
        title: `Week ${week}, Remark (${
          startDateTitle + " to " + endDateTitle
        })`,
        dataIndex: `week${i + 1}`,
        width: 215,
        render: (text, record) => {
          return <p style={color(text.remark)}>{text?.remark}</p>;
        },
      };
    });

  let columns = [
    {
      title: "Project Name",
      dataIndex: "projectName",
      width: 150,
      ellipsis: true,
      render: (text) => (
        <Tooltip title={text} placement="right" arrow>
          <span>{text}</span>
        </Tooltip>
      ),
      fixed: "left",
    },
    {
      title: "Project Manager",
      dataIndex: "projectManager",
      width: 400,
      ellipsis: true,
      render: (text) => (
        <Tooltip title={text} placement="right" arrow>
          <span>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: "Group",
      dataIndex: "group",
      width: 150,
      ellipsis: true,
      render: (text) => (
        <Tooltip title={text} placement="right" arrow>
          <span>{text}</span>
        </Tooltip>
      ),
    },

    {
      title: "Type",
      dataIndex: "typeOfProject",
      width: 200,
      render: (text) => (
        <Tooltip title={text} placement="right" arrow>
          <span>{text}</span>
        </Tooltip>
      ),
    },
  ];

  if (data1 && data2) {
    for (let i = 0; i < data1.length; i++) {
      const statusColumn = data1[i];
      const remarksColumn = data2[i];

      // Create a column for status
      const statusColumnData = {
        title: statusColumn.title,
        dataIndex: `week${i + 1}`,
        width: 215,
        render: statusColumn.render,
      };

      // Create a column for remarks
      const remarksColumnData = {
        title: remarksColumn.title,
        dataIndex: `week${i + 1}`,
        width: 215,
        render: remarksColumn.render,
      };

      columns.push(statusColumnData, remarksColumnData);
    }
  }

  return (
    <Reports
      rows={rows}
      columns={columns}
      handleSearch={handleSearch}
      handleDateChange={handleDateChange}
      title="Project Status"
      picker="week"
      handleDownload={handleDownload}
      searchPlaceholder="Search Project Name"
      showProjectStatusFilters={showProjectStatusFilters}
      setName={setName}
      name={name}
    />
  );
};

export default ProjectStatusReport;
