import { Tooltip } from "@mui/material";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import OffboardingModal from "./OffboardingModal";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import enIN from "date-fns/locale/en-IN";
import { Button, Modal, Select, Table, DatePicker } from "antd";
import { postUtilization } from "../Redux/Slices/Groups/GroupsSlice";
import LoadingSpinner from "./LoadingSpinner";
import dayjs from "dayjs";

const DeveloperModal = ({
  open,
  onClose,
  data,
  id,
  removeDeveloper,
  fetchAgain,
  type,
  loading,
  setLoading,
  selectedDate,
  setSelectedDate,
}) => {
  const dispatch = useDispatch();
  const [isOffboardingToggle, setisOffboardingToggle] = useState(false);
  const [currentDeveloper, setCurrentDeveloper] = useState("");
  const [offBoardingDate, setOffBoardingDate] = useState("");
  const [userBandwidth, setUserBandwidth] = useState([]);
  const [userRating, setUserRating] = useState([]);
  const [month, setMonth] = useState(dayjs(new Date()).format("MMMM"));

  const handleUtilizationChange = (value, userId) => {
    // Check if the userId already exists in userBandwidth
    const userIndex = userBandwidth.findIndex((user) => user.userId === userId);

    if (userIndex !== -1) {
      // If the userId already exists, update the existing entry
      userBandwidth[userIndex] = { userId: userId, bandwidth: value };
    } else {
      // If the userId doesn't exist, add a new entry to the array
      userBandwidth.push({ userId: userId, bandwidth: value });
    }
  };

  const handleRatingChange = (value, userId) => {
    const userIndex = userRating.findIndex((user) => user.userId === userId);

    if (userIndex !== -1) {
      // If the userId already exists, update the existing entry
      userRating[userIndex] = { userId: userId, rating: value };
    } else {
      // If the userId doesn't exist, add a new entry to the array
      userRating.push({ userId: userId, rating: value });
    }
  };

  const handleDateChange = (value) => {
    setSelectedDate(value.format());
    setMonth(value.format("MMMM"));
    setLoading(true);
  };

  const handleSaveChanges = () => {
    if (selectedDate) {
      const formattedSelectedDate = dayjs(selectedDate).format("YYYY-MM-DD");
      const formattedSelectedDateISO = new Date(
        formattedSelectedDate
      ).toISOString();

      dispatch(
        postUtilization({
          userBandwidth: userBandwidth,
          date: formattedSelectedDateISO,
          userRating: userRating,
        })
      ).then((e) => {
        if (e.payload.success === true) {
          toast.success(e.payload.message);
          onClose();
        } else if (e.payload.success === false) {
          toast.error(e.payload.message);
        }
      });
    } else {
      toast("Please select the date first");
    }
  };

  const disabledFutureDate = (current) => {
    // Get the current date
    const today = moment();

    // Disable dates greater than today
    return current && current > today;
  };

  const columns = [
    {
      title: "Offboard",
      dataIndex: "OffBoardAction",
      width: 100,
      render: (text, record) => {
        const handleOffBoarding = (e) => {
          let obj;
          if (type === "group") {
            obj = { developerId: currentDeveloper, groupName: id };
          } else {
            obj = {
              developerId: currentDeveloper,

              projectId: id,

              offBoardingDate: offBoardingDate,
            };
          }

          if (type === "group") {
            dispatch(removeDeveloper(obj)).then((response) => {
              toast("Developers removed successfully");

              setTimeout(() => {
                dispatch(fetchAgain());
                onClose();
              }, 1000);
            });
          } else {
            dispatch(removeDeveloper(obj)).then((response) => {
              toast("Developers removed successfully");

              setTimeout(() => {
                dispatch(fetchAgain());

                onClose();
              }, 1000);
            });
          }
        };

        return (
          <div>
            <Button
              onClick={(e) => {
                setCurrentDeveloper(record.objId);
                setisOffboardingToggle(true);
              }}
              style={{
                backgroundColor: " #80808066",

                padding: "2px 3px",

                fontSize: "13px",

                minWidth: "55px",
              }}
            >
              Offboard
            </Button>

            {isOffboardingToggle && (
              <OffboardingModal
                handleOffBoarding={handleOffBoarding}
                isOffboardingToggle={isOffboardingToggle}
                setisOffboardingToggle={setisOffboardingToggle}
                setOffBoardingDate={setOffBoardingDate}
              />
            )}
          </div>
        );
      },
    },
    {
      title: "Full name",
      dataIndex: "fullName",
      width: 100,
      render: (text) => (
        <Tooltip title={text} placement="right" arrow>
          <span>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: "Availability",
      dataIndex: "isAvailable",
      width: 100,
      render: (text) => (
        <Tooltip title={text} placement="right" arrow>
          <span>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: "% Utilization",
      dataIndex: "utilization",
      width: 200,
      render: (text, record) => {
        return (
          <Select
            style={{ width: 120 }}
            onChange={(value) => {
              handleUtilizationChange(value, record.objId);
            }}
            defaultValue={text}
          >
            <Select.Option
              value="0-25%"
              style={{ backgroundColor: "#FFCFC9", marginBottom: "2px" }}
            >
              0-25%
            </Select.Option>
            <Select.Option
              value="25-50%"
              style={{ backgroundColor: "#FFC8AA", marginBottom: "2px" }}
            >
              25-50%
            </Select.Option>
            <Select.Option
              value="50-75%"
              style={{ backgroundColor: "#FFE5A0", marginBottom: "2px" }}
            >
              50-75%
            </Select.Option>
            <Select.Option
              value="75-100%"
              style={{ backgroundColor: "#11734B", marginBottom: "2px" }}
            >
              75-100%
            </Select.Option>
            <Select.Option
              value="Above 100%"
              style={{ backgroundColor: "#B10202", marginBottom: "2px" }}
            >
              Above 100%
            </Select.Option>
            <Select.Option
              value="Joined this Week"
              style={{ backgroundColor: "blue", marginBottom: "2px" }}
            >
              Joined this Week
            </Select.Option>
            <Select.Option
              value="On Leave"
              style={{ backgroundColor: "#00E600", marginBottom: "2px" }}
            >
              On Leave
            </Select.Option>
          </Select>
        );
      },
    },

    {
      title: `Rating (${month})`,
      dataIndex: "rating",
      width: 200,
      render: (text, record) => {
        return (
          <Select
            style={{ width: 150 }}
            onChange={(value) => handleRatingChange(value, record.objId)}
            defaultValue={text}
          >
            <Select.Option value="Outstanding">Outstanding-4</Select.Option>
            <Select.Option value="Strong">Strong-3</Select.Option>
            <Select.Option value="Capable">Capable-2</Select.Option>
            <Select.Option value="Weak">Weak-1</Select.Option>
          </Select>
        );
      },
    },
    {
      title: "Billability",
      dataIndex: "billability",
      width: 100,
      render: (text) => (
        <Tooltip title={text} placement="right" arrow>
          <span>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: "Projects",
      dataIndex: "projects",
      width: 100,
      render: (text) => (
        <Tooltip title={text} placement="right" arrow>
          <span>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: "Experience",
      dataIndex: "experience",
      width: 150,
      render: (text) => (
        <Tooltip title={text} placement="right" arrow>
          <span>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: "Seniority",
      dataIndex: "seniority",
      width: 100,
    },
    {
      title: "Tech Stack",
      dataIndex: "techStack",
      width: 100,
      render: (text) => (
        <Tooltip title={text} placement="right" arrow>
          <span>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: "Phone No.",
      dataIndex: "phoneNumber",
      width: 110,
    },
    {
      title: "Emergency Number",
      dataIndex: "emergencyContactNumber",
      width: 110,
    },
    {
      title: "Email",
      dataIndex: "email",
      width: 100,
      render: (text) => (
        <Tooltip title={text} placement="right" arrow>
          <span>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: "Joining Date",
      dataIndex: "joiningDate",
      width: 100,
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      width: 100,
      ellipsis: true,
      render: (text) => <span>{text ? text : "N/A"}</span>,
    },
  ];

  const rows =
    data &&
    data?.map((user) => {
      const joinDate = moment(
        user?.details?.joiningDate,
        "YYYY-MM-DDTHH:mm:ssZ"
      )?.format();
      const currentDate = moment().format("YYYY-MM-DDTHH:mm:ssZ");
      const experienceAfterJoining = Math.floor(
        moment(currentDate).diff(moment(joinDate), "months", true)
      );
      const totalExperience =
        user?.details?.workingExperienceInMonths + experienceAfterJoining;
      const years = Math.floor(totalExperience / 12);
      const months = totalExperience - years * 12;
      const yearString =
        years === 0 ? "" : years === 1 ? years + " Year " : years + " Years ";
      const monthString =
        months === 0
          ? years === 0
            ? "0 Month"
            : ""
          : months === 1
          ? months + " Month "
          : months + " Months ";
      return {
        empId: user?.empId,
        fullName: user?.details?.fullName,
        techStack: user?.details?.primaryTechStack,
        projects:
          user?.details?.projects?.length === 0
            ? "N/A"
            : user?.details?.projects
                ?.map((project) => project?.projectName)
                .join(","),
        workingExperienceInMonths: user?.details?.workingExperienceInMonths,
        experience: yearString + monthString,
        phoneNumber: user?.details?.phoneNumber,
        email: user?.details?.email,
        remarks: user?.details?.remarks ? user?.details?.remarks : "N/A",
        emergencyContactNumber: user?.details?.emergencyContactNumber,
        designation: user?.designation?.name,
        utilization: user?.details?.bandwidth
          ? user?.details?.bandwidth
          : "Not Filled",
        rating: user?.details?.rating ? user?.details?.rating : "NA",
        joiningDate: moment(user?.joiningDate)?.format("DD/MM/YYYY"),
        seniority: user?.details?.seniority,
        id: user?.email + "_" + user?._id, // for time being
        objId: user.userId ? user.userId : user?._id,
        isAvailable:
          user?.details?.isAvailable === null
            ? "N/A"
            : user?.details?.isAvailable,
        isDeleted: user?.details?.isDeleted,
        billability: user?.details?.billability,
      };
    });

  return (
    <Modal
      title="Developer"
      open={open}
      onCancel={onClose}
      width={800}
      bodyStyle={{ overflowX: "scroll" }}
      footer={[
        <Button key="back" onClick={onClose}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={handleSaveChanges}>
          Save Changes
        </Button>,
      ]}
    >
      <div
        style={{
          margin: "1rem 0px",
        }}
      >
        <DatePicker
          onChange={handleDateChange}
          disabledDate={disabledFutureDate}
          picker="week"
          allowClear={false}
          defaultValue={dayjs(dayjs(new Date()).format(), "YYYY-MM-DD")}
        />
      </div>
      <div>
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={enIN}>
          {loading ? (
            <LoadingSpinner />
          ) : (
            <Table
              dataSource={rows}
              columns={columns}
              loading={loading}
              pagination={{ showQuickJumper: true, showSizeChanger: true }}
              rowKey="id"
              size="middle"
              scroll={{ x: 1000, y: 300 }}
            />
          )}
        </LocalizationProvider>
      </div>
    </Modal>
  );
};

export default DeveloperModal;
