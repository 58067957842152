import React, { useState } from 'react';
import {
    Routes,
    Route,
    Navigate,
} from 'react-router-dom';
import Login from '../Pages/Auth/Login/Login';
import Pagenotfound from '../Pages/Auth/Pagenotfound';
import Dashboard from '../Pages/Dashboard/Dashboard';
import Projects from '../Pages/Projects/Projects';
import Teams from '../Pages/Team/Teams';
import Developer from '../Pages/Developer/Developer';
import DeveloperDetailsNewPage from '../Pages/Developer/DeveloperDetailsNewPage';
import RegisterTeamMember from '../Pages/Auth/Register/RegisterTeamMember';
import ProjectDetails from '../Pages/Projects/ProjectDetails';
import PrivateRoute from './PrivateRoute';
import Groups from '../Pages/Groups/Groups';
import ICManagement from '../Pages/ICManagement/ICManagement';
import ClientRequirement from '../Pages/ClientRequirement/ClientRequirement';
import Reports from '../Pages/ICManagement/Components/Reports';
import Timesheet from '../Pages/timesheet/Timesheet';
import ViewTimesheet from '../Pages/timesheet/ViewTimesheet';
import TimesheetPM from '../Pages/timesheet/TimesheetPM';
import Filter from '../Pages/Developer/Filter';
import ProjectDetailsNewPage from '../Pages/Projects/Components/ProjectDetailsNewPage';
import OnboardHistoryReport from '../Pages/Developer/Components/OnboardHistoryReport';
import Accessdetails from '../Pages/Team/Accessdetails';
import Githubdetails from '../Pages/github/Githubdetails';
import RepoDetails from '../Pages/github/RepoDetails';
import UserRepoDetails from '../Pages/github/UserRepoDetails';
import UtilizationModal from '../Pages/Developer/Components/UtilizationModal';
import Rating from '../Pages/Developer/Components/Rating';
import ProjectStatusReport from '../Pages/Developer/Components/ProjectStatusReport';
import DepartedDeveloperReport from '../Pages/Developer/Components/DepartedDeveloperReport';
import ProjectStatus from '../Pages/Groups/Components/ProjectStatus';
import InactiveDevelopersReport from '../Pages/Developer/Components/InactiveDevelopersReport';
import { useSelector } from 'react-redux';
import TimesheetQA from '../Pages/Chat/TimesheetQA';

function Allroutes() {
    const dashboardRoutesByDesignation = {
        AVP: '/dashboard',
        VP: '/dashboard',
        PM: '/dashboard',
        CSM: '/dashboard',
        BA: '/dashboard',
        CEO: '/dashboard',
        CTO: '/dashboard',
        COO: '/dashboard',
        HR: '/dashboard/developer',
        SALES: '/dashboard/developer',
        DEVELOPER: '/dashboard/timesheet',
    };
    const [userDesignation, setDesignation] =
        useState(
            localStorage.getItem('designation'),
        );
    const { token } = useSelector(
        (store) => store.login,
    );
    // console.log("token", token, dashboardRoutesByDesignation[userDesignation]);

    return (
        <Routes>
            <Route
                path="/"
                element={
                    token ? (
                        <Navigate
                            to={
                                dashboardRoutesByDesignation[
                                    userDesignation
                                ]
                            }
                            replace
                        />
                    ) : (
                        <Navigate
                            to="/login"
                            replace
                        />
                    )
                }
            />

            <Route
                path="/dashboard"
                element={
                    token ? (
                        <PrivateRoute>
                            <Dashboard />
                        </PrivateRoute>
                    ) : (
                        <Navigate
                            to="/login"
                            replace
                        />
                    )
                }
            >
                {token &&
                    userDesignation === 'AVP' && (
                        <>
                            <Route
                                path="teams"
                                element={
                                    <Teams />
                                }
                            />
                            <Route
                                path="teams/register_team_member"
                                element={
                                    <RegisterTeamMember />
                                }
                            />
                            <Route
                                path="teams/Accessdetails"
                                element={
                                    <Accessdetails />
                                }
                            />
                            <Route
                                path="developer"
                                element={
                                    <Developer />
                                }
                            />
                            <Route
                                path="developer/utilisation"
                                element={
                                    <UtilizationModal />
                                }
                            />
                            <Route
                                path="developer/rating"
                                element={
                                    <Rating />
                                }
                            />
                            <Route
                                path="developer/projectStatus"
                                element={
                                    <ProjectStatusReport />
                                }
                            />
                            <Route
                                path="developer/departedDevelopers"
                                element={
                                    <DepartedDeveloperReport />
                                }
                            />
                            <Route
                                path="developer/inactiveDevelopers"
                                element={
                                    <InactiveDevelopersReport />
                                }
                            />
                            <Route
                                path="developer/details"
                                element={
                                    <DeveloperDetailsNewPage />
                                }
                            />
                            <Route
                                path="developer/OnOffBoardHistory"
                                element={
                                    <OnboardHistoryReport />
                                }
                            />
                            <Route
                                path="projects"
                                element={
                                    <Projects />
                                }
                            />
                            <Route
                                path="projects/:id"
                                element={
                                    <ProjectDetailsNewPage />
                                }
                            />
                            <Route
                                path="project/:id"
                                element={
                                    <ProjectDetails />
                                }
                            />
                            <Route
                                path="groups"
                                element={
                                    <Groups />
                                }
                            />
                            <Route
                                path="groups/:groupName"
                                element={
                                    <ProjectStatus />
                                }
                            />
                            <Route
                                path="Icmanagement"
                                element={
                                    <ICManagement />
                                }
                            />
                            <Route
                                path="client"
                                element={
                                    <ClientRequirement />
                                }
                            />
                            <Route
                                path="IcManagement"
                                element={
                                    <ICManagement />
                                }
                            />
                            <Route
                                path="IcManagement/reports"
                                element={
                                    <Reports />
                                }
                            />
                            <Route
                                path="timesheet"
                                element={
                                    <Timesheet />
                                }
                            />
                            <Route
                                path="ViewTimesheetPm"
                                element={
                                    <TimesheetPM />
                                }
                            />
                            <Route
                                path="filter"
                                element={
                                    <Filter />
                                }
                            />
                            <Route
                                path="githubDetails"
                                element={
                                    <Githubdetails />
                                }
                            />
                            {/* chats */}
                            <Route
                                path="TimesheetQnA"
                                element={
                                    <TimesheetQA />
                                }
                            />
                            <Route
                                path="githubDetails/:repoName"
                                element={
                                    <RepoDetails />
                                }
                            ></Route>
                            <Route
                                path="githubDetails/:repoName/:name"
                                element={
                                    <UserRepoDetails />
                                }
                            />
                        </>
                    )}
                {token &&
                    userDesignation === 'CEO' && (
                        <>
                            <Route
                                path="teams"
                                element={
                                    <Teams />
                                }
                            />
                            <Route
                                path="teams/register_team_member"
                                element={
                                    <RegisterTeamMember />
                                }
                            />
                            <Route
                                path="teams/Accessdetails"
                                element={
                                    <Accessdetails />
                                }
                            />
                            <Route
                                path="developer"
                                element={
                                    <Developer />
                                }
                            />
                            <Route
                                path="developer/utilisation"
                                element={
                                    <UtilizationModal />
                                }
                            />
                            <Route
                                path="developer/rating"
                                element={
                                    <Rating />
                                }
                            />
                            <Route
                                path="developer/projectStatus"
                                element={
                                    <ProjectStatusReport />
                                }
                            />
                            <Route
                                path="developer/departedDevelopers"
                                element={
                                    <DepartedDeveloperReport />
                                }
                            />
                            <Route
                                path="developer/inactiveDevelopers"
                                element={
                                    <InactiveDevelopersReport />
                                }
                            />
                            <Route
                                path="developer/details"
                                element={
                                    <DeveloperDetailsNewPage />
                                }
                            />
                            <Route
                                path="developer/OnOffBoardHistory"
                                element={
                                    <OnboardHistoryReport />
                                }
                            />
                            <Route
                                path="projects"
                                element={
                                    <Projects />
                                }
                            />
                            <Route
                                path="projects/:id"
                                element={
                                    <ProjectDetailsNewPage />
                                }
                            />
                            <Route
                                path="project/:id"
                                element={
                                    <ProjectDetails />
                                }
                            />
                            <Route
                                path="groups"
                                element={
                                    <Groups />
                                }
                            />
                            <Route
                                path="groups/:groupName"
                                element={
                                    <ProjectStatus />
                                }
                            />
                            <Route
                                path="Icmanagement"
                                element={
                                    <ICManagement />
                                }
                            />
                            <Route
                                path="client"
                                element={
                                    <ClientRequirement />
                                }
                            />
                            <Route
                                path="IcManagement"
                                element={
                                    <ICManagement />
                                }
                            />
                            <Route
                                path="IcManagement/reports"
                                element={
                                    <Reports />
                                }
                            />
                            <Route
                                path="timesheet"
                                element={
                                    <Timesheet />
                                }
                            />
                            <Route
                                path="ViewTimesheetPm"
                                element={
                                    <TimesheetPM />
                                }
                            />
                            <Route
                                path="filter"
                                element={
                                    <Filter />
                                }
                            />
                            <Route
                                path="githubDetails"
                                element={
                                    <Githubdetails />
                                }
                            />
                            {/* chats */}
                            <Route
                                path="TimesheetQnA"
                                element={
                                    <TimesheetQA />
                                }
                            />
                            <Route
                                path="githubDetails/:repoName"
                                element={
                                    <RepoDetails />
                                }
                            ></Route>
                            <Route
                                path="githubDetails/:repoName/:name"
                                element={
                                    <UserRepoDetails />
                                }
                            />
                        </>
                    )}
                {token &&
                    userDesignation === 'CTO' && (
                        <>
                            <Route
                                path="teams"
                                element={
                                    <Teams />
                                }
                            />
                            <Route
                                path="teams/register_team_member"
                                element={
                                    <RegisterTeamMember />
                                }
                            />
                            <Route
                                path="teams/Accessdetails"
                                element={
                                    <Accessdetails />
                                }
                            />
                            <Route
                                path="developer"
                                element={
                                    <Developer />
                                }
                            />
                            <Route
                                path="developer/utilisation"
                                element={
                                    <UtilizationModal />
                                }
                            />
                            <Route
                                path="developer/rating"
                                element={
                                    <Rating />
                                }
                            />
                            <Route
                                path="developer/projectStatus"
                                element={
                                    <ProjectStatusReport />
                                }
                            />
                            <Route
                                path="developer/departedDevelopers"
                                element={
                                    <DepartedDeveloperReport />
                                }
                            />
                            <Route
                                path="developer/inactiveDevelopers"
                                element={
                                    <InactiveDevelopersReport />
                                }
                            />
                            <Route
                                path="developer/details"
                                element={
                                    <DeveloperDetailsNewPage />
                                }
                            />
                            <Route
                                path="developer/OnOffBoardHistory"
                                element={
                                    <OnboardHistoryReport />
                                }
                            />
                            <Route
                                path="projects"
                                element={
                                    <Projects />
                                }
                            />
                            <Route
                                path="projects/:id"
                                element={
                                    <ProjectDetailsNewPage />
                                }
                            />
                            <Route
                                path="project/:id"
                                element={
                                    <ProjectDetails />
                                }
                            />
                            <Route
                                path="groups"
                                element={
                                    <Groups />
                                }
                            />
                            <Route
                                path="groups/:groupName"
                                element={
                                    <ProjectStatus />
                                }
                            />
                            <Route
                                path="Icmanagement"
                                element={
                                    <ICManagement />
                                }
                            />
                            <Route
                                path="client"
                                element={
                                    <ClientRequirement />
                                }
                            />
                            <Route
                                path="IcManagement"
                                element={
                                    <ICManagement />
                                }
                            />
                            <Route
                                path="IcManagement/reports"
                                element={
                                    <Reports />
                                }
                            />
                            <Route
                                path="timesheet"
                                element={
                                    <Timesheet />
                                }
                            />
                            <Route
                                path="ViewTimesheetPm"
                                element={
                                    <TimesheetPM />
                                }
                            />
                            <Route
                                path="filter"
                                element={
                                    <Filter />
                                }
                            />
                            <Route
                                path="githubDetails"
                                element={
                                    <Githubdetails />
                                }
                            />
                            {/* chats */}
                            <Route
                                path="TimesheetQnA"
                                element={
                                    <TimesheetQA />
                                }
                            />
                            <Route
                                path="githubDetails/:repoName"
                                element={
                                    <RepoDetails />
                                }
                            ></Route>
                            <Route
                                path="githubDetails/:repoName/:name"
                                element={
                                    <UserRepoDetails />
                                }
                            />
                        </>
                    )}
                {token &&
                    userDesignation === 'COO' && (
                        <>
                            <Route
                                path="teams"
                                element={
                                    <Teams />
                                }
                            />
                            <Route
                                path="teams/register_team_member"
                                element={
                                    <RegisterTeamMember />
                                }
                            />
                            <Route
                                path="teams/Accessdetails"
                                element={
                                    <Accessdetails />
                                }
                            />
                            <Route
                                path="developer"
                                element={
                                    <Developer />
                                }
                            />
                            <Route
                                path="developer/utilisation"
                                element={
                                    <UtilizationModal />
                                }
                            />
                            <Route
                                path="developer/rating"
                                element={
                                    <Rating />
                                }
                            />
                            <Route
                                path="developer/projectStatus"
                                element={
                                    <ProjectStatusReport />
                                }
                            />
                            <Route
                                path="developer/departedDevelopers"
                                element={
                                    <DepartedDeveloperReport />
                                }
                            />
                            <Route
                                path="developer/inactiveDevelopers"
                                element={
                                    <InactiveDevelopersReport />
                                }
                            />
                            <Route
                                path="developer/details"
                                element={
                                    <DeveloperDetailsNewPage />
                                }
                            />
                            <Route
                                path="developer/OnOffBoardHistory"
                                element={
                                    <OnboardHistoryReport />
                                }
                            />
                            <Route
                                path="projects"
                                element={
                                    <Projects />
                                }
                            />
                            <Route
                                path="projects/:id"
                                element={
                                    <ProjectDetailsNewPage />
                                }
                            />
                            <Route
                                path="project/:id"
                                element={
                                    <ProjectDetails />
                                }
                            />
                            <Route
                                path="groups"
                                element={
                                    <Groups />
                                }
                            />
                            <Route
                                path="groups/:groupName"
                                element={
                                    <ProjectStatus />
                                }
                            />
                            <Route
                                path="Icmanagement"
                                element={
                                    <ICManagement />
                                }
                            />
                            <Route
                                path="client"
                                element={
                                    <ClientRequirement />
                                }
                            />
                            <Route
                                path="IcManagement"
                                element={
                                    <ICManagement />
                                }
                            />
                            <Route
                                path="IcManagement/reports"
                                element={
                                    <Reports />
                                }
                            />
                            <Route
                                path="timesheet"
                                element={
                                    <Timesheet />
                                }
                            />
                            <Route
                                path="ViewTimesheetPm"
                                element={
                                    <TimesheetPM />
                                }
                            />
                            <Route
                                path="filter"
                                element={
                                    <Filter />
                                }
                            />
                            <Route
                                path="githubDetails"
                                element={
                                    <Githubdetails />
                                }
                            />
                            {/* chats */}
                            <Route
                                path="TimesheetQnA"
                                element={
                                    <TimesheetQA />
                                }
                            />
                            <Route
                                path="githubDetails/:repoName"
                                element={
                                    <RepoDetails />
                                }
                            ></Route>
                            <Route
                                path="githubDetails/:repoName/:name"
                                element={
                                    <UserRepoDetails />
                                }
                            />
                        </>
                    )}
                {token &&
                    userDesignation === 'VP' && (
                        <>
                            <Route
                                path="teams"
                                element={
                                    <Teams />
                                }
                            />
                            <Route
                                path="teams/register_team_member"
                                element={
                                    <RegisterTeamMember />
                                }
                            />
                            <Route
                                path="teams/Accessdetails"
                                element={
                                    <Accessdetails />
                                }
                            />
                            <Route
                                path="developer"
                                element={
                                    <Developer />
                                }
                            />
                            <Route
                                path="developer/utilisation"
                                element={
                                    <UtilizationModal />
                                }
                            />
                            <Route
                                path="developer/rating"
                                element={
                                    <Rating />
                                }
                            />
                            <Route
                                path="developer/projectStatus"
                                element={
                                    <ProjectStatusReport />
                                }
                            />
                            <Route
                                path="developer/departedDevelopers"
                                element={
                                    <DepartedDeveloperReport />
                                }
                            />
                            <Route
                                path="developer/inactiveDevelopers"
                                element={
                                    <InactiveDevelopersReport />
                                }
                            />
                            <Route
                                path="developer/details"
                                element={
                                    <DeveloperDetailsNewPage />
                                }
                            />
                            <Route
                                path="developer/OnOffBoardHistory"
                                element={
                                    <OnboardHistoryReport />
                                }
                            />
                            <Route
                                path="projects"
                                element={
                                    <Projects />
                                }
                            />
                            <Route
                                path="projects/:id"
                                element={
                                    <ProjectDetailsNewPage />
                                }
                            />
                            <Route
                                path="project/:id"
                                element={
                                    <ProjectDetails />
                                }
                            />
                            <Route
                                path="groups"
                                element={
                                    <Groups />
                                }
                            />
                            <Route
                                path="groups/:groupName"
                                element={
                                    <ProjectStatus />
                                }
                            />
                            <Route
                                path="Icmanagement"
                                element={
                                    <ICManagement />
                                }
                            />
                            <Route
                                path="client"
                                element={
                                    <ClientRequirement />
                                }
                            />
                            <Route
                                path="IcManagement"
                                element={
                                    <ICManagement />
                                }
                            />
                            <Route
                                path="IcManagement/reports"
                                element={
                                    <Reports />
                                }
                            />
                            <Route
                                path="timesheet"
                                element={
                                    <Timesheet />
                                }
                            />
                            <Route
                                path="ViewTimesheetPm"
                                element={
                                    <TimesheetPM />
                                }
                            />
                            <Route
                                path="filter"
                                element={
                                    <Filter />
                                }
                            />
                            <Route
                                path="githubDetails"
                                element={
                                    <Githubdetails />
                                }
                            />
                            {/* chats */}
                            <Route
                                path="TimesheetQnA"
                                element={
                                    <TimesheetQA />
                                }
                            />
                            <Route
                                path="githubDetails/:repoName"
                                element={
                                    <RepoDetails />
                                }
                            ></Route>
                            <Route
                                path="githubDetails/:repoName/:name"
                                element={
                                    <UserRepoDetails />
                                }
                            />
                        </>
                    )}
                {token &&
                    userDesignation ===
                        'DEVELOPER' && (
                        <>
                            <Route
                                path="timesheet"
                                element={
                                    <Timesheet />
                                }
                            />
                            <Route
                                path="viewtimesheet"
                                element={
                                    <ViewTimesheet />
                                }
                            />
                        </>
                    )}
                {token &&
                    userDesignation === 'PM' && (
                        <>
                            <Route
                                path="projects"
                                element={
                                    <Projects />
                                }
                            />
                            <Route
                                path="projects/:id"
                                element={
                                    <ProjectDetailsNewPage />
                                }
                            />
                            <Route
                                path="project/:id"
                                element={
                                    <ProjectDetails />
                                }
                            />
                            <Route
                                path="developer"
                                element={
                                    <Developer />
                                }
                            />
                            <Route
                                path="developer/utilisation"
                                element={
                                    <UtilizationModal />
                                }
                            />
                            <Route
                                path="developer/rating"
                                element={
                                    <Rating />
                                }
                            />
                            <Route
                                path="developer/projectStatus"
                                element={
                                    <ProjectStatusReport />
                                }
                            />
                            <Route
                                path="developer/departedDevelopers"
                                element={
                                    <DepartedDeveloperReport />
                                }
                            />
                            <Route
                                path="developer/inactiveDevelopers"
                                element={
                                    <InactiveDevelopersReport />
                                }
                            />
                            <Route
                                path="developer/details"
                                element={
                                    <DeveloperDetailsNewPage />
                                }
                            />
                            <Route
                                path="developer/OnOffBoardHistory"
                                element={
                                    <OnboardHistoryReport />
                                }
                            />
                            <Route
                                path="groups"
                                element={
                                    <Groups />
                                }
                            />
                            <Route
                                path="groups/:groupName"
                                element={
                                    <ProjectStatus />
                                }
                            />

                            <Route
                                path="ViewTimesheetPm"
                                element={
                                    <TimesheetPM />
                                }
                            />
                            <Route
                                path="timesheet"
                                element={
                                    <Timesheet />
                                }
                            />
                            <Route
                                path="Icmanagement"
                                element={
                                    <ICManagement />
                                }
                            />
                            <Route
                                path="client"
                                element={
                                    <ClientRequirement />
                                }
                            />
                            <Route
                                path="IcManagement"
                                element={
                                    <ICManagement />
                                }
                            />
                            <Route
                                path="IcManagement/reports"
                                element={
                                    <Reports />
                                }
                            />
                            <Route
                                path="githubDetails"
                                element={
                                    <Githubdetails />
                                }
                            />
                            {/* chats */}
                            <Route
                                path="TimesheetQnA"
                                element={
                                    <TimesheetQA />
                                }
                            />
                            <Route
                                path="githubDetails/:repoName"
                                element={
                                    <RepoDetails />
                                }
                            ></Route>
                            <Route
                                path="githubDetails/:repoName/:name"
                                element={
                                    <UserRepoDetails />
                                }
                            />
                        </>
                    )}
                {token &&
                    userDesignation === 'BA' && (
                        <>
                            <Route
                                path="projects"
                                element={
                                    <Projects />
                                }
                            />
                            <Route
                                path="projects/:id"
                                element={
                                    <ProjectDetailsNewPage />
                                }
                            />
                            <Route
                                path="project/:id"
                                element={
                                    <ProjectDetails />
                                }
                            />
                            <Route
                                path="developer"
                                element={
                                    <Developer />
                                }
                            />
                            <Route
                                path="developer/utilisation"
                                element={
                                    <UtilizationModal />
                                }
                            />
                            <Route
                                path="developer/rating"
                                element={
                                    <Rating />
                                }
                            />
                            <Route
                                path="developer/projectStatus"
                                element={
                                    <ProjectStatusReport />
                                }
                            />
                            <Route
                                path="developer/departedDevelopers"
                                element={
                                    <DepartedDeveloperReport />
                                }
                            />
                            <Route
                                path="developer/inactiveDevelopers"
                                element={
                                    <InactiveDevelopersReport />
                                }
                            />
                            <Route
                                path="developer/details"
                                element={
                                    <DeveloperDetailsNewPage />
                                }
                            />
                            <Route
                                path="developer/OnOffBoardHistory"
                                element={
                                    <OnboardHistoryReport />
                                }
                            />
                            <Route
                                path="groups"
                                element={
                                    <Groups />
                                }
                            />
                            <Route
                                path="groups/:groupName"
                                element={
                                    <ProjectStatus />
                                }
                            />

                            <Route
                                path="ViewTimesheetPm"
                                element={
                                    <TimesheetPM />
                                }
                            />
                            <Route
                                path="timesheet"
                                element={
                                    <Timesheet />
                                }
                            />
                            <Route
                                path="Icmanagement"
                                element={
                                    <ICManagement />
                                }
                            />
                            <Route
                                path="client"
                                element={
                                    <ClientRequirement />
                                }
                            />
                            <Route
                                path="IcManagement"
                                element={
                                    <ICManagement />
                                }
                            />
                            <Route
                                path="IcManagement/reports"
                                element={
                                    <Reports />
                                }
                            />
                            {/* chats */}
                            <Route
                                path="TimesheetQnA"
                                element={
                                    <TimesheetQA />
                                }
                            />
                        </>
                    )}
                {token &&
                    userDesignation === 'CSM' && (
                        <>
                            <Route
                                path="projects"
                                element={
                                    <Projects />
                                }
                            />
                            <Route
                                path="projects/:id"
                                element={
                                    <ProjectDetailsNewPage />
                                }
                            />
                            <Route
                                path="project/:id"
                                element={
                                    <ProjectDetails />
                                }
                            />
                            <Route
                                path="developer"
                                element={
                                    <Developer />
                                }
                            />
                            <Route
                                path="developer/utilisation"
                                element={
                                    <UtilizationModal />
                                }
                            />
                            <Route
                                path="developer/rating"
                                element={
                                    <Rating />
                                }
                            />
                            <Route
                                path="developer/projectStatus"
                                element={
                                    <ProjectStatusReport />
                                }
                            />
                            <Route
                                path="developer/departedDevelopers"
                                element={
                                    <DepartedDeveloperReport />
                                }
                            />
                            <Route
                                path="developer/inactiveDevelopers"
                                element={
                                    <InactiveDevelopersReport />
                                }
                            />
                            <Route
                                path="developer/details"
                                element={
                                    <DeveloperDetailsNewPage />
                                }
                            />
                            <Route
                                path="developer/OnOffBoardHistory"
                                element={
                                    <OnboardHistoryReport />
                                }
                            />
                            <Route
                                path="groups"
                                element={
                                    <Groups />
                                }
                            />
                            <Route
                                path="groups/:groupName"
                                element={
                                    <ProjectStatus />
                                }
                            />

                            <Route
                                path="ViewTimesheetPm"
                                element={
                                    <TimesheetPM />
                                }
                            />
                            <Route
                                path="timesheet"
                                element={
                                    <Timesheet />
                                }
                            />
                            <Route
                                path="Icmanagement"
                                element={
                                    <ICManagement />
                                }
                            />
                            <Route
                                path="client"
                                element={
                                    <ClientRequirement />
                                }
                            />
                            <Route
                                path="IcManagement"
                                element={
                                    <ICManagement />
                                }
                            />
                            <Route
                                path="IcManagement/reports"
                                element={
                                    <Reports />
                                }
                            />
                            {/* chats */}
                            <Route
                                path="TimesheetQnA"
                                element={
                                    <TimesheetQA />
                                }
                            />
                        </>
                    )}
                {token &&
                    userDesignation ===
                        'SALES' && (
                        <>
                            <Route
                                path="developer"
                                element={
                                    <Developer />
                                }
                            />

                            <Route
                                path="developer/details"
                                element={
                                    <DeveloperDetailsNewPage />
                                }
                            />

                            <Route
                                path="Icmanagement"
                                element={
                                    <ICManagement />
                                }
                            />
                            <Route
                                path="client"
                                element={
                                    <ClientRequirement />
                                }
                            />
                            <Route
                                path="IcManagement"
                                element={
                                    <ICManagement />
                                }
                            />
                            <Route
                                path="IcManagement/reports"
                                element={
                                    <Reports />
                                }
                            />
                            {/* chats */}
                            <Route
                                path="TimesheetQnA"
                                element={
                                    <TimesheetQA />
                                }
                            />
                        </>
                    )}
                {token &&
                    userDesignation === 'HR' && (
                        <>
                            <Route
                                path="developer"
                                element={
                                    <Developer />
                                }
                            />

                            <Route
                                path="developer/details"
                                element={
                                    <DeveloperDetailsNewPage />
                                }
                            />
                            <Route
                                path="developer/utilisation"
                                element={
                                    <UtilizationModal />
                                }
                            />
                            <Route
                                path="developer/rating"
                                element={
                                    <Rating />
                                }
                            />
                            <Route
                                path="developer/departedDevelopers"
                                element={
                                    <DepartedDeveloperReport />
                                }
                            />
                            <Route
                                path="Icmanagement"
                                element={
                                    <ICManagement />
                                }
                            />
                            <Route
                                path="client"
                                element={
                                    <ClientRequirement />
                                }
                            />
                            <Route
                                path="IcManagement"
                                element={
                                    <ICManagement />
                                }
                            />
                            <Route
                                path="IcManagement/reports"
                                element={
                                    <Reports />
                                }
                            />
                        </>
                    )}
            </Route>

            <Route
                path="login"
                element={
                    token ? (
                        <Navigate
                            to={
                                dashboardRoutesByDesignation[
                                    userDesignation
                                ]
                            }
                            replace
                        />
                    ) : (
                        <Login
                            setDesignation={
                                setDesignation
                            }
                        />
                    )
                }
            />

            {/* Handle other unmatched routes */}
            <Route
                path="*"
                element={
                    <Navigate
                        replace
                        to="/login"
                    />
                }
            />
        </Routes>
    );
}

export default Allroutes;
