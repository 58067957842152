import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  Button,
  TextField,
  Box,
  Typography,
  Tooltip,
  IconButton,
  InputAdornment,
} from "@mui/material";
import "../../Assets/CSS/Developer/Developer.css";
import { useForm } from "react-hook-form";
import { DataGrid, GridMoreVertIcon } from "@mui/x-data-grid";
import FilterSchema from "../Auth/Register/FilterSchema";
import { makeStyles } from "@mui/styles";
import { getTeams } from "../../Redux/Slices/Teams/TeamSlice";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { removeTeam } from "../../apis/TeamsApi";
import LoadingSpinner from "../../utils/LoadingSpinner";
import ConfirmationDialog from "../../utils/ConfirmationDialog";
import { toast } from "react-toastify";
import CreateEditTeam from "./component/CreateEditTeam";
import AssignGroup from "./component/AssignGroup";
import UnassignGroup from "./component/UnassignGroup";
import { AddCircleOutlineOutlined } from "@mui/icons-material";
import ActionDialog from "./component/ActionDialog";

const Teams = () => {
  const [dataPerPage, setDataPerPage] = useState(10);
  const [openCreateTeam, setOpenCreateTeam] = useState(false);
  const [openAssignGroup, setOpenAssignGroup] = useState(false);
  const [userId, setUserId] = useState("");
  const [user, setUser] = useState(null);
  const [isSuccess, setIsSuccess] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState("");
  const [gpid, setGpid] = useState();
  const [gpName, setGpname] = useState();

  const useStyles = makeStyles({
    primaryBtn: {
      padding: "0px !important",
      "& button": {
        color: "white !important",
      },
    },
    filterField: {
      backgroundColor: "white",
      width: "200px",
    },
    customScrollbar: {
      scrollbarWidth: "thin", // For Firefox
      "&::-webkit-scrollbar": {
        width: "10px",
      },
      "&::-webkit-scrollbar-track": {
        background: "#f1f1f1",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#888",
        borderRadius: "5px",
      },
      "&::-webkit-scrollbar-thumb:hover": {
        background: "#555",
      },
    },
    actionColumn: {
      // position: "sticky",
      left: "0", // Adjust the distance from the left as needed
      zIndex: 10,
      backgroundColor: "white",
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.8)",
    },

    actionHeader: {
      position: "sticky !important",
      left: "0", // Adjust the distance from the left as needed
      zIndex: 10,
      backgroundColor: "#FAFAFA",
      color: "black",
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.8)",
    },
  });

  const classes = useStyles();

  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [openUnassignGroup, setOpenUnassignGroup] = useState(false);
  const [openRemoveDialog, setOpenRemoveDialog] = useState(false);
  const [status, setStatus] = useState(false);
  const [openActionModal, setOpenActionModal] = useState(false);

  const {
    register,
    handleSubmit,
    resetField,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(FilterSchema),
  });

  const { loading, teams, message } = useSelector((store) => store.team);

  useEffect(() => {
    dispatch(getTeams());
  }, []);

  const deleteHandler = () => {
    setOpenRemoveDialog(true);
    setConfirmMessage("Are you sure you want to remove ?");
    setIsSuccess(false);
  };

  const editHandler = () => {
    setOpenCreateTeam(true);
  };

  const handleAssignGroup = () => {
    setOpenAssignGroup(true);
  };

  const columns = [
    {
      field: "name",
      headerName: "Name",
      headerAlign: "start",
      align: "start",
      width: 160,
    },
    {
      field: "email",
      headerName: "Email",
      headerAlign: "center",
      align: "center",
      sortable: false,
      minWidth: 200,
      flex: 0.5,
      renderCell: (param) => (
        <Tooltip title={param.value} placement="right" arrow>
          <span
            style={{
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
              cursor: "context-menu",
            }}
          >
            {param.value}
          </span>
        </Tooltip>
      ),
    },
    {
      field: "designationName",
      headerName: "Designation",
      headerAlign: "center",
      sortable: false,
      width: 160,
      align: "center",
      renderCell: (param) => (
        <Tooltip title={param.value} placement="right" arrow>
          <span
            style={{
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
              cursor: "context-menu",
            }}
          >
            {param.value}
          </span>
        </Tooltip>
      ),
    },
    {
      field: "group",
      headerName: "Group",
      headerAlign: "start",
      sortable: false,
      flex: 1,
      minWidth: 250,
      renderCell: (params) => {
        let groupName = params.formattedValue;
        if (Array.isArray(groupName)) {
          groupName = params?.formattedValue
            ?.map((item) => item.groupName)
            .join(", ");
        }
        return (
          <Tooltip title={groupName} placement="right" arrow>
            <span
              style={{
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
                cursor: "context-menu",
              }}
            >
              {groupName}
            </span>
          </Tooltip>
        );
      },
    },
    {
      field: "actions",
      headerName: "Action",
      headerAlign: "center",
      align: "center",
      sortable: false,
      minWidth: 200,
      flex: 0.5,
      headerClassName: classes.actionHeader,
      cellClassName: classes.actionColumn,

      renderCell: (params) => {
        return (
          <div>
            <Tooltip title="Perform Action" placement="top">
              <IconButton
                size="small"
                sx={{
                  marginLeft: "auto",
                }}
                onClick={() => {
                  setOpenActionModal(true);
                  setUser(params?.row);
                  setUserId(params?.id);
                  if (params?.row?.group !== "N/A") {
                    setGpid(params?.row?.group[0]?._id);
                    setGpname(params?.row?.group);
                  }
                }}
              >
                <GridMoreVertIcon />
              </IconButton>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const rows = teams
    ?.filter((user) => user.isDeleted === status)
    ?.map((user) => {
      return {
        id: user?._id,
        email: user?.email,
        name: user?.lastName ? user?.fullName : user?.fullName,
        designationName: user?.role,
        group: user?.groupDetails?.length === 0 ? "N/A" : user?.groupDetails,
      };
    });

  const handleCloseCreateTeamDialog = () => {
    setOpenCreateTeam(false);
  };

  const handleCloseAssignGroupDialog = () => {
    setOpenAssignGroup(false);
  };

  const handleCloseRemoveDialog = () => {
    setOpenRemoveDialog(false);
  };

  const handleUnassignGroup = () => {
    setOpenUnassignGroup(true);
  };

  const handleCloseUnassignGroup = () => {
    setOpenUnassignGroup(false);
  };

  const handleRemoveMember = () => {
    const obj = {
      id: user?.id,
    };
    removeTeam(obj)
      .then((res) => {
        toast("Member deleted successfully");
        dispatch(getTeams());
        setIsSuccess(true);
      })
      .catch((error) => {
        // console.log(error);
        toast(error.msg);
      });
  };

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <Box
          sx={{
            height: "85vh",
            backgroundColor: "white",
            padding: "10px 32px 24px 32px",
            borderRadius: "16px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                font: "Montserrat",
                fontSize: "32px",
                fontWeight: "500",
              }}
            >
              Access Management
            </Typography>
            <Button
              variant="contained"
              sx={{
                marginRight: "2px",
                backgroundColor: "#4E79FA", // Set the button background color to blue
                color: "#FFFFFF", // Set the button text color to white
                "&:hover": {
                  backgroundColor: "#4E79FA", // Maintain blue background on hover
                },
                height: "56px",
              }}
              onClick={() => {
                setUser(null);
                setOpenCreateTeam(true);
              }}
            >
              <IconButton
                color="inherit" // Set the button color to blue
                onClick={() => {
                  setUser(null);
                  setOpenCreateTeam(true);
                }}
                sx={{
                  marginRight: "2px",
                }}
              >
                <AddCircleOutlineOutlined />
              </IconButton>
              Add Team Member
            </Button>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: "1rem",
              marginTop: "1rem",
              marginBottom: "10px",
            }}
          >
            <TextField
              size="small"
              className={classes.filterField}
              placeholder="Search user name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              autoComplete={"off"}
              sx={{
                width: "28rem",
                borderRadius: "8px",
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />

            <Box>
              <Link to="/dashboard/teams/Accessdetails">
                <Button
                  className=""
                  variant="contained"
                  sx={{
                    background: "#4E79FA",
                    color: "white",
                  }}
                  // onClick={() => {
                  //   setUser(null);
                  //   setOpenCreateTeam(true);
                  // }}
                >
                  History
                </Button>
              </Link>
            </Box>
          </Box>

          <Box style={{ height: "80%", width: "100%" }}>
            <DataGrid
              rows={rows}
              rowHeight={40}
              columns={columns}
              pageSize={dataPerPage}
              onPageSizeChange={(value) => setDataPerPage(value)}
              rowsPerPageOptions={[10, 25, 50, 100]}
              // disableSelectionOnClick
              pagination
              disableColumnMenu
              disableColumnFilter
              sx={{
                ".MuiDataGrid-columnSeparator": {
                  display: "none",
                },
                "&.MuiDataGrid-root": {
                  border: "none",
                },
                backgroundColor: "white",
                "& .MuiDataGrid-columnHeaders": {
                  // fontSize: "1rem",
                  background: "#FAFAFA",
                  color: "black",
                },
                "& .MuiDataGrid-sortIcon": {
                  color: "white",
                },
                "& 	.MuiDataGrid-menuIconButton": {
                  color: "white",
                },
                "& 	.MuiDataGrid-filterIcon": {
                  color: "white",
                },
                "& .MuiDataGrid-window": {
                  // Customize the entire grid window (both horizontal and vertical scrollbars)
                  // For example:
                  scrollbarWidth: "thin", // For Firefox
                  "&::-webkit-scrollbar": {
                    width: "10px", // Width of the scrollbar
                  },
                  "&::-webkit-scrollbar-track": {
                    background: "#f1f1f1", // Background color of the scrollbar track
                  },
                  "&::-webkit-scrollbar-thumb": {
                    background: "#888", // Color of the scrollbar thumb
                    borderRadius: "5px", // Border radius of the scrollbar thumb
                  },
                  "&::-webkit-scrollbar-thumb:hover": {
                    background: "#555", // Color of the scrollbar thumb on hover
                  },
                },
                "& .MuiDataGrid-virtualScroller": {
                  // Customize the vertical scrollbar container
                  // For example:
                  background: "white", // Background color of the vertical scrollbar container
                  width: "10px", // Width of the vertical scrollbar container
                },
                "& .MuiDataGrid-horizontalScroller": {
                  // Customize the horizontal scrollbar container
                  // For example:
                  background: "#f1f1f1", // Background color of the horizontal scrollbar container
                  height: "10px", // Height of the horizontal scrollbar container
                },
                "& .MuiDataGrid-scrollbar": {
                  // Styles for both vertical and horizontal scrollbar thumbs
                  // Customize the common styles for the scrollbar thumb
                  // For example:
                  background: "#888", // Color of the scrollbar thumb
                  borderRadius: "5px", // Border radius of the scrollbar thumb
                },
                "& .MuiDataGrid-scrollbar-thumb": {
                  // Styles for scrollbar thumb itself
                  // You can further customize the styles for the scrollbar thumb
                  // For example:
                  background: "#888", // Color of the scrollbar thumb
                  borderRadius: "5px", // Border radius of the scrollbar thumb
                  "&:hover": {
                    background: "red", // Color of the scrollbar thumb on hover
                  },
                },
              }}
              filterModel={{
                items: [
                  {
                    columnField: "name",
                    operatorValue: "contains",
                    value: `${name}`,
                  },
                ],
              }}
              // components={{ Pagination: CustomPagination }}
              // initialState={{
              //   sorting: {
              //     sortModel: [{field:"Deleted"  }],
              //   },
              // }}
            />
          </Box>
          {openCreateTeam && (
            <CreateEditTeam
              open={openCreateTeam}
              onClose={handleCloseCreateTeamDialog}
              user={user}
            />
          )}
          {openAssignGroup && (
            <AssignGroup
              open={openAssignGroup}
              userId={userId}
              onClose={handleCloseAssignGroupDialog}
            />
          )}
          {openUnassignGroup && (
            <UnassignGroup
              open={openUnassignGroup}
              onClose={handleCloseUnassignGroup}
              groupData={user}
              gpName={gpName}
              gpid={gpid}
            />
          )}
          {openRemoveDialog && (
            <ConfirmationDialog
              open={openRemoveDialog}
              message={confirmMessage}
              onClose={handleCloseRemoveDialog}
              onConfirm={handleRemoveMember}
              isSuccess={isSuccess}
              // user={user}
            />
          )}
          {openActionModal && (
            <ActionDialog
              open={openActionModal}
              onClose={() => setOpenActionModal(false)}
              deleteHandler={deleteHandler}
              editHandler={editHandler}
              handleUnassignGroup={handleUnassignGroup}
              handleAssignGroup={handleAssignGroup}
            />
          )}
        </Box>
      )}
    </>
  );
};

export default Teams;
