import React, {
    useEffect,
    useState,
    useRef,
} from 'react';
import axios from 'axios';
import {
    Box,
    TextField,
    List,
    ListItem,
    ListItemText,
    IconButton,
    Button,
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import RefreshIcon from '@mui/icons-material/Refresh';
import { CHAT_URL } from '../../constant';
import ThreeDotLoader from '../Chat/ThreeDotLoader';

const TimeSheetQA = () => {
    const [messages, setMessages] = useState([]);
    const [input, setInput] = useState('');
    const [showRefresh, setShowRefresh] =
        useState(false);
    const [loading, setLoading] = useState(false);
    const messageListRef = useRef(null);

    useEffect(() => {
        const role = localStorage.getItem(
            'designation',
        );
        if (role === 'VP' || role === 'AVP') {
            setShowRefresh(true);
        }
    }, []);

    useEffect(() => {
        if (messageListRef.current) {
            messageListRef.current.scrollTop =
                messageListRef.current.scrollHeight;
        }
    }, [messages]);

    const handleSendMessage = async () => {
        if (input.trim()) {
            const userMessage = {
                id: messages.length + 1,
                text: input,
                sender: 'sent',
            };
            setMessages((prev) => [
                ...prev,
                userMessage,
            ]);
            setInput('');
            setLoading(true);

            try {
                const response = await axios.post(
                    `${CHAT_URL}/ask`,
                    { text: input },
                );

                if (response.status === 200) {
                    const botMessage = {
                        id: userMessage.id + 1,
                        text: response.data
                            .answer,
                        sender: 'received',
                    };
                    setMessages((prev) => [
                        ...prev,
                        botMessage,
                    ]);
                } else {
                    console.error(
                        'Failed to fetch response from /ask',
                    );
                }
            } catch (error) {
                console.error(
                    'Error making request to /ask:',
                    error,
                );
            } finally {
                setLoading(false);
            }
        }
    };

    const handleRefresh = async () => {
        try {
            const response = await axios.post(
                `${CHAT_URL}/refresh`,
            );
            if (response.status === 200) {
                console.log('working');
            } else {
                console.error(
                    'Failed to refresh',
                );
            }
        } catch (error) {
            console.error(
                'Error making request to /refresh:',
                error,
            );
        }
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '90vh',
                padding: '16px',
                backgroundColor: '#f0f0f0',
            }}
        >
            {/* Header Section */}
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    marginBottom: '16px',
                }}
            >
                {showRefresh && (
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={
                            <RefreshIcon />
                        }
                        onClick={handleRefresh}
                    >
                        Refresh
                    </Button>
                )}
            </Box>

            {/* Message List Section */}
            <Box
                ref={messageListRef}
                sx={{
                    flex: 1,
                    overflowY: 'auto',
                    padding: '8px',
                    backgroundColor: 'white',
                    borderRadius: '8px',
                    marginBottom: '16px',
                    border: '2px solid black',
                }}
            >
                <List>
                    {messages.length === 0 && (
                        <ListItem
                            sx={{
                                justifyContent:
                                    'center',
                            }}
                        >
                            <Box
                                sx={{
                                    fontStyle:
                                        'times-roman',
                                    color: '#888',
                                    fontSize:
                                        '16px',
                                }}
                            >
                                I am your
                                Timesheet Chatbot.
                                How may I help
                                you?
                            </Box>
                        </ListItem>
                    )}

                    {messages.map((message) => (
                        <ListItem
                            key={message.id}
                            sx={{
                                justifyContent:
                                    message.sender ===
                                    'sent'
                                        ? 'flex-end'
                                        : 'flex-start',
                            }}
                        >
                            <Box
                                sx={{
                                    maxWidth:
                                        '60%',
                                    padding:
                                        '8px 12px',
                                    borderRadius:
                                        '12px',
                                    backgroundColor:
                                        message.sender ===
                                        'sent'
                                            ? 'black'
                                            : '#f0f0f0',
                                    color:
                                        message.sender ===
                                        'sent'
                                            ? 'white'
                                            : 'black',
                                }}
                            >
                                <ListItemText
                                    primary={
                                        message.sender ===
                                        'received' ? (
                                            <code>
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: message.text.replace(
                                                            /\n/g,
                                                            '<br />',
                                                        ),
                                                    }}
                                                ></div>
                                            </code>
                                        ) : (
                                            message.text
                                        )
                                    }
                                    sx={{
                                        wordWrap:
                                            'break-word',
                                    }}
                                />
                            </Box>
                        </ListItem>
                    ))}
                </List>

                {/* Show loader when waiting for response */}
                {loading && (
                    <ListItem
                        sx={{
                            justifyContent:
                                'flex-start',
                        }}
                    >
                        <ThreeDotLoader />
                    </ListItem>
                )}
            </Box>

            {/* Input Section */}
            {/* <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: '8px',
                    backgroundColor: 'white',
                    borderRadius: '8px',
                    border: '2px solid black',
                }}
            >
                <TextField
                    fullWidth
                    placeholder="Type a message"
                    value={input}
                    onChange={(e) =>
                        setInput(e.target.value)
                    }
                    onKeyDown={(e) => {
                        if (
                            e.keyCode === 13 ||
                            e.key === 'Enter'
                        ) {
                            e.preventDefault();
                            if (input.trim()) {
                                handleSendMessage();
                            }
                        }
                    }}
                    sx={{ marginRight: '8px' }}
                />
                <IconButton
                    color="primary"
                    onClick={handleSendMessage}
                    disabled={!input.trim()}
                >
                    <SendIcon />
                </IconButton>
            </Box> */}
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: '8px',
                    backgroundColor: 'white',
                    borderRadius: '8px',
                    border: '2px solid black',
                }}
            >
                <TextField
                    fullWidth
                    placeholder="Type a message"
                    value={input}
                    onChange={(e) =>
                        setInput(e.target.value)
                    }
                    onKeyDown={(e) => {
                        if (
                            e.keyCode === 13 ||
                            e.key === 'Enter'
                        ) {
                            e.preventDefault();
                            if (input.trim()) {
                                handleSendMessage();
                            }
                        }
                    }}
                    sx={{
                        'marginRight': '8px',
                        '& .MuiInputBase-root': {
                            border: 'none', // Remove border from the input field
                            backgroundColor:
                                'transparent', // Set transparent background
                        },
                        '& .MuiOutlinedInput-notchedOutline':
                            {
                                border: 'none', // Remove the outline
                            },
                    }}
                />
                <IconButton
                    color="primary"
                    onClick={handleSendMessage}
                    disabled={!input.trim()}
                >
                    <SendIcon />
                </IconButton>
            </Box>
        </Box>
    );
};

export default TimeSheetQA;
