import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import CloseIcon from "@mui/icons-material/Close";
import {
  DesktopDatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { makeStyles } from "@mui/styles";
import { useFormik } from "formik";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  getDeveloperNames,
  getAllIC,
  getClientname,
  getAllManagers,
} from "../../../Redux/Slices/IC/ICSlice";
import { getTechStacks } from "../../../Redux/Slices/Lists/ListsSlice";
import moment from "moment";
import api from "../../../apiclient";
import CreateIcSchema from "./CreateIcSchema";

import RegisterDeveloper from "../../Developer/RegisterDevModal";

const CreateICModal = ({ open, setIsSuccess, onClose, isSuccess }) => {
  const useStyles = makeStyles({
    container: {
      gap: "10px",
    },
    inputItem: {
      width: "100%",
    },
    paper: { minWidth: "500px" },
  });

  const classes = useStyles();
  const dispatch = useDispatch();

  const [openRegisterModal, setOpenRegisterModal] = useState(false);
  const { developerNames, clientList } = useSelector((store) => store.ICS);

  const { projectManagers } = useSelector((store) => store?.ICS);

  const techStacks = useSelector((store) => store?.lists?.techStacks);

  const closeRegisterModalHandler = () => {
    setOpenRegisterModal(false);
    localStorage.removeItem("openRegisterModal");
  };
  const openRegisterModalHandler = () => {
    setOpenRegisterModal(true);
    localStorage.setItem("openRegisterModal", "openRegisterModal");
  };
  const length = 24;

  useEffect(() => {
    dispatch(getTechStacks());
    // dispatch(addIc());
  }, []);

  useEffect(() => {
    dispatch(getAllIC());
  }, [isSuccess]);

  useEffect(() => {
    const fetchData = async () => {
      const dev = await getDeveloperNames();
      dispatch(getDeveloperNames());
    };
    const fetchClient = async () => {
      const dev = await getClientname();
      dispatch(getClientname());
    };

    fetchClient();

    fetchData();
  }, [dispatch]);

  useEffect(() => {
    const response = dispatch(getAllManagers());
  }, []);

  const handleClose = () => {
    onClose();
  };

  const handleSubmit = async (values) => {
    let obj = {
      developer: values?.developer,
      clientName: values?.clientName,
      iCDate: values?.iCDate,
      iCSlot: values?.iCSlot,
      developerType: values?.developerType,
      scheduledBy: values?.scheduledBy,
      techStack: values?.techStack,
    };

    try {
      const response = await api.post(`api/v2/ic/createIc `, obj);
      if (response?.status == 201) {
        dispatch(getAllIC());
        toast.success("IC created successfully");
        onClose();
        formik.handleReset();
        setIsSuccess(!isSuccess);
      }
    } catch (e) {
      // console.log("error");
    }
  };

  const formik = useFormik({
    initialValues: {
      developer: "",
      clientName: "",
      iCDate: "",
      iCSlot: "",
      developerType: "",
      techStack: "",
      scheduledBy: "",
    },

    validationSchema: CreateIcSchema,

    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const handlebtnsubmit = () => {
    formik.handleSubmit();
  };

  const [firstValue, setFirstValue] = useState(null);
  const [secondValue, setSecondValue] = useState(null);

  const renderOptionDeveloper = (props, option) => (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        padding: "8px",
      }}
      {...props}
      key={option.key}
    >
      <Typography>{option.fullName}</Typography>
      <Typography>({option.empId})</Typography>
    </Box>
  );

  return (
    <Box>
      <form onSubmit={handlebtnsubmit} autocomplete="off">
        <Dialog
          className={classes.paper}
          maxWidth={"md"}
          open={open}
          close={onClose}
          sx={{
            "& .MuiDialogActions-root": {
              padding: "0px !important",
            },
          }}
        >
          <DialogTitle>
            <Grid
              container
              sx={{
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              Create IC
              <Button
                sx={{
                  color: "black",
                  fontSize: "1rem",
                }}
                onClick={handleClose}
              >
                <CloseIcon sx={{ cursor: "pointer" }} onClick={onClose} />
              </Button>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <Grid
              container
              sx={{
                "&    .MuiAutocomplete-popper": {
                  paddingBottom: "1rem",
                },
                "& .MuiFormHelperText-root": {
                  color: "#D32F2F",
                },
              }}
              mt={1}
              spacing={2}
            >
              <Grid item xs={6}>
                <Autocomplete
                  className={classes.inputItem}
                  noOptionsText={
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <span>no user found</span>
                      <Button onClick={openRegisterModalHandler}>
                        Add User
                      </Button>
                    </Box>
                  }
                  options={developerNames || []}
                  getOptionLabel={(option) => `${option.fullName}`}
                  name="developer"
                  renderOption={renderOptionDeveloper}
                  filterSelectedOptions
                  onChange={(event, value) => {
                    formik.setFieldValue("developerType", value?.developerType);
                    formik.setFieldValue("developer", value?._id);
                  }}
                  onBlur={formik.handleBlur}
                  size="small"
                  renderInput={(params) => (
                    <>
                      <TextField
                        required
                        {...params}
                        name="developer"
                        label="Developer"
                        helperText={
                          formik.touched.developer
                            ? formik.errors.developer
                            : ""
                        }
                      />
                    </>
                  )}
                />
              </Grid>

              <Grid item xs={6}>
                <Autocomplete
                  className={classes.inputItem}
                  options={clientList || []}
                  getOptionLabel={(option) => option.clientName}
                  name="clientName"
                  filterSelectedOptions
                  onChange={(event, value) => {
                    formik.setFieldValue("clientName", value?._id);
                  }}
                  onBlur={formik.handleBlur}
                  size="small"
                  renderInput={(params) => (
                    <>
                      <TextField
                        required
                        {...params}
                        name="clientName"
                        label="Client Name"
                        // error={formik.errors.poc}
                        helperText={
                          formik.touched.clientName
                            ? formik.errors.clientName
                            : ""
                        }
                      />
                    </>
                  )}
                />
              </Grid>

              <Grid item xs={6}>
                <Autocomplete
                  className={classes.inputItem}
                  options={projectManagers || []}
                  getOptionLabel={(option) =>
                    `${option.fullName} (${option.role})`
                  }
                  name="scheduledBy"
                  // disablePortal={true}
                  filterSelectedOptions
                  onChange={(event, value) =>
                    formik.setFieldValue("scheduledBy", value._id)
                  }
                  onBlur={formik.handleBlur}
                  size="small"
                  renderInput={(params) => (
                    <>
                      <TextField
                        required
                        {...params}
                        name="scheduledBy"
                        label="Scheduled By"
                        helperText={
                          formik.touched.scheduledBy
                            ? formik.errors.scheduledBy
                            : ""
                        }
                      />
                    </>
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  sx={{ width: "100%", maxHeight: "40px !important" }}
                  size="small"
                  disabled
                  placeholder="Developer Type"
                  name="developerType"
                  value={formik?.values?.developerType}
                  helperText={
                    formik.touched.developerType
                      ? formik.errors.developerType
                      : ""
                  }
                />
              </Grid>

              <Grid item xs={6}>
                <Autocomplete
                  className={classes.inputItem}
                  freeSolo
                  options={techStacks || []}
                  getOptionLabel={(option) => option.name}
                  name="techStack"
                  onChange={(event, value) =>
                    formik.setFieldValue("techStack", value?._id)
                  }
                  onBlur={formik.handleBlur}
                  filterSelectedOptions
                  size="small"
                  renderInput={(params) => (
                    <>
                      <TextField
                        required
                        {...params}
                        name="techStack"
                        label="Tech Stack"
                        helperText={
                          formik.touched.techStack
                            ? formik.errors.techStack
                            : ""
                        }
                      />
                    </>
                  )}
                />
              </Grid>

              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Grid item xs={6}>
                  <DesktopDatePicker
                    sx={{ width: "100%" }}
                    slotProps={{ textField: { size: "small" } }}
                    inputFormat="MM-DD-YYYY"
                    label={
                      <div>
                        IC Date <span>*</span>
                      </div>
                    }
                    onChange={(newValue) => {
                      formik.setFieldValue(
                        "iCDate",
                        moment(newValue).format("MM-DD-YYYY")
                      );
                    }}
                    renderInput={(params) => (
                      <>
                        <TextField
                          size="small"
                          required
                          fullWidth
                          {...params}
                          name="iCDate"
                          onBlur={(e) => {
                            formik.setFieldTouched("iCDate", true);
                          }}
                          helperText={
                            formik.touched.iCDate ? formik.errors.iCDate : ""
                          }
                        />
                      </>
                    )}
                  />
                </Grid>
              </LocalizationProvider>
              <Grid item xs={6}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <TimePicker
                    label={
                      <div>
                        IC slot <span>*</span>
                      </div>
                    }
                    // ampm={false}
                    sx={{ width: "100%" }}
                    slotProps={{ textField: { size: "small" } }}
                    clearable
                    onChange={(event, value) =>
                      formik.setFieldValue("iCSlot", event)
                    }
                    required
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Box sx={{ margin: "8px 20px" }}>
              <Button
                onClick={handlebtnsubmit}
                sx={{ color: "white" }}
                variant="contained"
              >
                Submit IC
              </Button>
            </Box>
          </DialogActions>
        </Dialog>
      </form>
      <RegisterDeveloper
        open={openRegisterModal}
        onClose={closeRegisterModalHandler}
      />
    </Box>
  );
};

export default CreateICModal;
