import { idID } from "@mui/material/locale";
import api from "../apiclient";

export const getDevelopers = async () => {
  const res = await api.get("api/v2/users/search");
  return res?.data;
};
export const getDeveloperfullname = async (id) => {
  const res = await api.get(`api/v2/users/get-bilable?id=${id}`);
  return res?.data;
};



export const getDeveloperDetails = async (id) => {
  const res = await api.get(`/api/v2/users/getDeveloperDetails?id=${id}`);
  return res?.data;
};



export const getOnBoardDetails = async (id) => {
  const res = await api.get(`/api/v2/users/onboardHistory?userId=${id}`);
  return res?.data;
};

  

export const getFullTimeOnBoardDetails = async () => {
  const res = await api.get(`api/v2/users/list?engagement_type=FullTime`);
  return res?.data;
};



export const getPartTimeOnBoardDetails = async () => {
  const res = await api.get(`api/v2/users/list?engagement_type=PartTime`);
  return res?.data;
};

export const getdeveloperOnboardDetails = async () => {
  const res = await api.get(`api/v2/users/getOnboardingOffboardingData`);
  return res?.data;

};


export const getdeveloperOnboardDetailsFilter = async (searchString) => {
  const res = await api.get(`api/v2/users/getOnboardingOffboardingData?${searchString}`);
  return res?.data;

};


// export const downloadOnboardHistoryExcel = async () => {
//   try {
//     const response = await api.get("api/v2/users/exportToCSV");
//     // Assuming the API returns the Excel data
//     const excelData = response.data;

//     // You can create a Blob and download it
//     const blob = new Blob([excelData], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
//     const url = window.URL.createObjectURL(blob);
//     const a = document.createElement('a');
//     a.href = url;
//     a.download = 'onboard_history.xlsx'; // Specify the file name and extension
//     document.body.appendChild(a);
//     a.click();
//     window.URL.revokeObjectURL(url);
//   } catch (error) {
//     console.error("Error downloading Excel file: ", error);
//   }
// };

export const downloadOnboardHistoryExcel = async () => {
  try {
    const response = await api.get("api/v2/users/exportToCSV");

    // Check if the response status is OK
    if (response.status === 200) {
      const data = response.data;
      if (data && data.success === true && data.data) {
        const excelFileURL = data.data;
        
        // Open the Excel file in a new tab
        window.open(excelFileURL, '_blank');
      } else {
        console.error("Error downloading Excel file: Invalid response data");
      }
    } else {
      console.error("Error downloading Excel file: Invalid response status");
    }
  } catch (error) {
    console.error("Error downloading Excel file: ", error);
  }
};
