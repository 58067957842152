import { Bar } from "react-chartjs-2";

const StackedBarChart = ({ barLabels, barAvailable, barEngagedCount }) => {
  const barChartData = {
    labels: barLabels,
    datasets: [
      {
        label: "Engaged",
        backgroundColor: "rgba(75, 192, 192, 0.6)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
        hoverBackgroundColor: "rgba(75, 192, 192, 0.4)",
        hoverBorderColor: "rgba(75, 192, 192, 1)",
        data: barEngagedCount,
      },
      {
        label: "Available",
        backgroundColor: "rgba(255, 99, 132, 0.6)",
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 1,
        hoverBackgroundColor: "rgba(255, 99, 132, 0.4)",
        hoverBorderColor: "rgba(255, 99, 132, 1)",
        data: barAvailable,
      },
    ],
  };

  const options = {
    scales: {
      x: {
        stacked: true,
        ticks: {
          minRotation: 70,
          autoSkip: false, // Prevent automatic label skipping
          maxRotation: 90, // Rotate labels up to 90 degrees
          maxWidth: 200, // Set a maximum width for the labels
        },
      },
      y: {
        stacked: true,
      },
    },
    indexAxis: "x",
    layout: {
      padding: {
        left: 10,
        right: 10,
        top: 20,
        bottom: 10,
      },
    },
    maintainAspectRatio: false,
    barThickness: 15,
  };

  return (
    <div className="w-full h-[60vh]">
      {<Bar data={barChartData} options={options} />}
    </div>
  );
};

export default StackedBarChart;
