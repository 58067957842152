import { Box, Button, Dialog } from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";

import { EditOutlined, DeleteOutlined } from "@ant-design/icons";

const ActionModal = ({ open, onClose, handleDelete, editModalHandler }) => {
  const role = localStorage.getItem("designation");
  return (
    <Box>
      <Dialog open={open} onClose={onClose}>
        <div className="w-80 h-35 rounded-md pt-5 pl-4 pb-4 ">
          <Button
            onClick={onClose}
            sx={{
              position: "absolute ",
              top: "8px",
              left: "75%",
              color: "black",
              fontSize: "1rem",
              zIndex: 1,
            }}
          >
            <CloseIcon sx={{ cursor: "pointer", fontSize: "1rem" }} />
          </Button>

          <div className="flex flex-col gap-4 mt-2 mb-2 ">
            <div
              className="align-bottom flex "
              style={{ cursor: "pointer" }}
              onClick={editModalHandler}
            >
              <div>
                <EditOutlined className="" />
              </div>
              <div className="pl-3 pt-1">Edit</div>
            </div>
            {role !== "PM" && role !== "BA" && role !== "CSM" && (
              <div
                className="flex"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  handleDelete();
                  onClose();
                }}
              >
                <div>
                  <DeleteOutlined />
                </div>
                <div className="pl-3 pt-1"> Delete Developer</div>
              </div>
            )}
          </div>
        </div>
      </Dialog>
    </Box>
  );
};

export default ActionModal;
