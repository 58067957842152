import * as yup from "yup";

const CreateIcSchema = yup.object({
    developer: yup
    .string()
    .required("Developer name is required")
    .matches(/^([^\s]*[A-Za-z0-9]\s*)*$/, "Enter valid data "),
  clientName: yup.string().required("Client Name is required"),
  scheduledBy: yup.string().required("Scheduled by is required"),
  developerType: yup.string().required("Developer Type  is required"),
  techStack: yup.string().required("Tech Stack  is required"),
  // iCDate: yup.string().required("ic date  is required"),
  // iCSlot: yup.string().required("ic slot  is required"),

  // iCDate: yup.string().required("IC date is required"),
iCSlot: yup.string().required("IC slot is required"),

  
});


export default CreateIcSchema;
