import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useParams } from "react-router-dom";
import LoadingSpinner from "../../utils/LoadingSpinner";
import { GIT_URL } from "../../constant";
import { InputAdornment, TextField, Button } from "@mui/material";

function UserRepoDetails() {
  const { name, repoName } = useParams();
  const [totalCount, setTotalCount] = useState(0);
  const [userEvents, setUserEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedPage, setSelectedPage] = useState(1);
  const [selectedPageSize, setSelectedPageSize] = useState(10);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `${GIT_URL}/api/v1/git/user_events?userName=${name}&repoName=${repoName}&page=${selectedPage}&per_page=${selectedPageSize}`
        );
        const data = await response.json();
        if (data.success) {
          const total = data.data.finalData.totalCount;
          setTotalCount(total);

          const userEventsData = data.data.finalData.tempArr.map(
            (event, index) => ({
              ...event,
              id: index + 1,
            })
          );
          setUserEvents(userEventsData);
        } else {
          console.error("API request was not successful");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [name, repoName, selectedPage, selectedPageSize]);

  const columns = [
    {
      field: "name",
      headerName: "Name",
      width: 220,
    },
    {
      field: "date",
      headerName: "Date",
      width: 220,
      valueFormatter: (params) => {
        const date = new Date(params.value);
        const options = { year: "numeric", month: "2-digit", day: "2-digit" };
        return date.toLocaleDateString(undefined, options);
      },
    },
    {
      field: "message",
      headerName: "Message",
      width: 450,
    },
  ];

  const onPageChange = (newPage) => {
    if (newPage >= 1) {
      setSelectedPage(newPage);
    }
  };

  const onPageSizeChange = (newPageSize) => {
    setSelectedPageSize(newPageSize);
    setSelectedPage(1);
  };

  const startIndex = (selectedPage - 1) * selectedPageSize;
  const endIndex = startIndex + selectedPageSize;
  const paginatedUserEvents = userEvents.slice(startIndex, endIndex);

  return (
    <div>
      <div className="mt-10 bg-white rounded-md w-[100%]">
        <div className="flex justify between items-center">
          <div className="font-semibold text-xl ml-4 pt-4">
            Commit details of {name} ({totalCount} commits)
          </div>
        </div>

        <div style={{ height: 400, width: "95%" }} className="mt-4 ml-4 pb-4">
          {loading ? (
            <LoadingSpinner />
          ) : (
            <>
              <DataGrid
                rows={userEvents}
                columns={columns}
                autoHeight={false}
                sx={{
                  backgroundColor: "white",
                  "& .MuiDataGrid-columnHeaders": {
                    background: "FAFAFA",
                    color: "Black",
                  },
                  "& .MuiDataGrid-sortIcon": {
                    color: "black",
                  },
                }}
                rowHeight={40}
                hideFooterPagination
              />
              {/* <div className="pagination-controls">
                <Button onClick={() => onPageChange(selectedPage - 1)} disabled={selectedPage === 1}>
                  Previous
                </Button>
                <Button
                  onClick={() => onPageChange(selectedPage + 1)}
                  // disabled={paginatedUserEvents.length < selectedPageSize}
                >
                  Next
                </Button>
              </div> */}
            </>
          )}
        </div>
        <div className="pagination-controls">
          <Button
            onClick={() => onPageChange(selectedPage - 1)}
            disabled={selectedPage === 1}
          >
            Previous
          </Button>
          <Button
            onClick={() => onPageChange(selectedPage + 1)}
            // disabled={paginatedUserEvents.length < selectedPageSize}
          >
            Next
          </Button>
        </div>
      </div>
    </div>
  );
}

export default UserRepoDetails;
