import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@mui/material";

import { useForm } from "react-hook-form";
import React from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from "react-redux";
import {
  addDevelopers,
  getGroups,
  removeDeveloper,
} from "../../Redux/Slices/Groups/GroupsSlice";
import { toast } from "react-toastify";

const groupSchema = yup.object().shape({
  developers: yup.array(),
});

const AddRemoveDeveloper = ({
  open,
  onClose,
  developers,
  groupId,
  remove,
}) => {
  
  const {
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      developers: [],
      developerId: "",
    },
    resolver: yupResolver(groupSchema),
  });
  const dispatch = useDispatch();

  const isOptionDisabled = (option) => !!option.groupName;

  const optionRenderer = (props, option, state) => {
    const { selected } = state;
    const isDisabled = isOptionDisabled(option);

    return (
      <li
        {...props}
        key={option._id}
        style={{
          cursor: isDisabled ? "not-allowed" : "pointer",
          backgroundColor: selected ? (isDisabled ? "#ccc" : "#f0f0f0") : "white",
          fontWeight: selected ? "bold" : "normal",
        }}
        onClick={isDisabled ? null : props.onClick} // Disable onClick for disabled options
      >
        {option?.isDeleted
          ? ""
          : `${option?.fullName} ${
              option?.groupName ? `(${option?.groupName})` : ""
            }`}
      </li>
    );
  };

  const submitHandler = (data) => {
    const requiredData = { groupId, ...data };
    if (remove == true) {
      delete requiredData.developers;
      if (requiredData.developerId === "") {
        toast("Add Developer");
      } else {
        dispatch(removeDeveloper(requiredData)).then((response) => {
          if (response.payload.status === 404) {
            toast("Error");
          } else {
            setTimeout(() => {
              reset();
              dispatch(getGroups());
              onClose();
            }, 1000);
          }
        });
      }
    } else {
      if (requiredData.userId.length === 0) {
        toast("Add Developers");
      } else {
        dispatch(addDevelopers(requiredData)).then((response) => {
          if (response?.error) {
            toast(response.payload.data.message);
          } else {
            toast("Developers added successfully");
            reset();
            dispatch(getGroups());
            onClose();
          }
        });
      }
    }
  };

  return (
    <>
      <Box>
        <Dialog
          open={open}
          close={onClose}
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "400px",
              },
            },
          }}
        >
          <DialogTitle sx={{ padding: "16px 16px 6px 24px" }}>
            <Grid
              container
              sx={{
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {remove == true ? "Remove Developer" : "Add Developer"}
              <Button onClick={onClose}>X</Button>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <Box>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "1rem",
                  flexDirection: "column",
                }}
              >
                {remove == true ? (
                  <Autocomplete
                    options={developers || []}
                    getOptionLabel={(item) =>
                      `${item?.fullName} ${
                        item?.groupName ? `(${item?.groupName})` : ""
                      }`
                    }
                    onChange={(e, value) => setValue("developerId", value?._id)}
                    filterSelectedOptions
                    sx={{ width: "100%", marginTop: "1rem" }}
                    isOptionDisabled={isOptionDisabled}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        name="developerId"
                        label="Developer"
                        helperText={errors && errors?.developers?.message}
                      />
                    )}
                    renderOption={optionRenderer}
                  />
                ) : (
                  <Autocomplete
                    id="outlined-required"
                    options={developers || []}
                    getOptionLabel={(item) =>
                      `${item?.fullName} ${
                        item?.groupName ? `(${item?.groupName})` : ""
                      }`
                    }
                    size="small"
                    onChange={(event, value) =>
                      setValue(
                        "userId",
                        value?.map((item) => item?._id)
                      )
                    }
                    disableCloseOnSelect={true}
                    filterSelectedOptions
                    multiple
                    sx={{
                      width: "100%",
                      marginTop: "1rem",
                    }}
                    isOptionDisabled={isOptionDisabled}
                    renderInput={(params) => (
                      <div>
                        <TextField
                          fullWidth
                          required
                          {...params}
                          name="userId"
                          label="Developers"
                          helperText={errors && errors?.developers?.message}
                        ></TextField>
                      </div>
                    )}
                    renderOption={optionRenderer}
                  />
                )}
              </div>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleSubmit(submitHandler)}
              variant="contained"
              sx={{ color: "white" }}
            >
              {remove == true ? "Remove" : "Add"}
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  );
};

export default AddRemoveDeveloper;
