import dayjs from "dayjs";
import { debounce } from "lodash";
import React, { useEffect, useState } from "react";
import Reports from "./Reports";
import {
  ratingHistory,
  ratingDownload,
} from "../../../Redux/Slices/Lists/ListsSlice";
import { useDispatch, useSelector } from "react-redux";
import { getGroups } from "../../../Redux/Slices/Lists/ListsSlice";
import { Tooltip } from "antd";
import { toast } from "react-toastify";

const Rating = () => {
  const dispatch = useDispatch();
  const [endDate, setEndDate] = useState(dayjs().format("YYYY-MM-DD"));
  const [startDate, setStartDate] = useState(
    dayjs().subtract(5, "months").format("YYYY-MM-DD")
  );

  const { rating, groups, searchFilters } = useSelector((store) => store.lists);
  const [name, setName] = useState("");

  const handleDateChange = (value) => {
    setEndDate(value.format());
    const startDate = value.clone().subtract(5, "months").format();
    setStartDate(startDate);
  };

  const handleSearch = debounce((name) => {
    const formattedStartDate = dayjs(startDate).format("YYYY-MM-DD");
    const formattedStartISO = new Date(formattedStartDate).toISOString();
    const formattedEndDate = dayjs(endDate).format("YYYY-MM-DD");
    const formattedEndDateISO = new Date(formattedEndDate).toISOString();
    dispatch(
      ratingHistory({
        startDate: formattedStartISO,
        endDate: formattedEndDateISO,
        name: name,
      })
    );
  }, 800);

  function convertCamelCaseToNormal(string) {
    const words = string.split(/(?=[A-Z])/).join(" ");
    return words.charAt(0).toUpperCase() + words.slice(1);
  }

  const handleDownload = () => {
    dispatch(ratingDownload({ startDate, endDate, name })).then((response) => {
      toast.success(response.payload.message);
    });
    toast.success("Downloading CSV file");
  };

  useEffect(() => {
    const formattedStartDate = dayjs(startDate).format("YYYY-MM-DD");
    const formattedStartISO = new Date(formattedStartDate).toISOString();
    const formattedEndDate = dayjs(endDate).format("YYYY-MM-DD");
    const formattedEndDateISO = new Date(formattedEndDate).toISOString();

    dispatch(
      ratingHistory({
        startDate: formattedStartISO,
        endDate: formattedEndDateISO,
        name: name,
      })
    );
    if (groups) {
      dispatch(getGroups());
    }
  }, [endDate, searchFilters]);

  const rows =
    rating?.length > 0 &&
    rating?.map((user) => {
      return {
        fullName: user.fullName,
        group: user.group,
        status: user.isAvailable,
        resourceType: user.resourceType,
        fullTimeProject: user.fullTimeProject,
        partTimeProjects: user.partTimeProjects,
        month1: user?.ratingData ? user?.ratingData[0] : "",
        month2: user?.ratingData ? user?.ratingData[1] : "",
        month3: user?.ratingData ? user?.ratingData[2] : "",
        month4: user?.ratingData ? user?.ratingData[3] : "",
        month5: user?.ratingData ? user?.ratingData[4] : "",
        month6: user?.ratingData ? user?.ratingData[5] : "",
      };
    });

  const data =
    rating &&
    rating.length > 0 &&
    rating[0]?.ratingData.map((e, i) => {
      let month = dayjs(e?.ratingMonthAndYear).format("MMMM, YY");

      return {
        title: `${month}`,
        dataIndex: `month${i + 1}`,
        width: 215,
        render: (text, record) => {
          return <p>{text?.rating}</p>;
        },
      };
    });

  let columns = [
    {
      title: "Full Name",
      dataIndex: "fullName",
      width: 150,
      ellipsis: true,
      render: (text) => (
        <Tooltip title={text} placement="right" arrow>
          <span>{text}</span>
        </Tooltip>
      ),
      fixed: "left",
    },
    {
      title: "Group",
      dataIndex: "group",
      width: 150,
      ellipsis: true,
      render: (text) => (
        <Tooltip title={text} placement="right" arrow>
          <span>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      width: 150,
      render: (text) => (
        <Tooltip title={convertCamelCaseToNormal(text)} placement="right" arrow>
          <span>{convertCamelCaseToNormal(text)}</span>
        </Tooltip>
      ),
    },
    {
      title: "Resource Type",
      dataIndex: "resourceType",
      width: 150,
      render: (text) => (
        <Tooltip title={text} placement="right" arrow>
          <span>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: "Full Time Projects",
      dataIndex: "fullTimeProject",
      width: 200,
      ellipsis: true,
      render: (text) => (
        <Tooltip title={text} placement="right" arrow>
          <span>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: "Part Time Projects",
      dataIndex: "partTimeProjects",
      width: 200,
      ellipsis: true,
      render: (text) => (
        <Tooltip title={text} placement="right" arrow>
          <span>{text}</span>
        </Tooltip>
      ),
    },
  ];

  if (data) {
    columns = [...columns, ...data];
  }

  return (
    <Reports
      rows={rows}
      columns={columns}
      handleSearch={handleSearch}
      handleDateChange={handleDateChange}
      title="Developer Rating"
      picker="month"
      handleDownload={handleDownload}
      searchPlaceholder="Search Developer Name"
      name={name}
      setName={setName}
    />
  );
};

export default Rating;
