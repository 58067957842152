import * as React from "react";
import { styled } from "@mui/material/styles";
import "../../Assets/CSS/Dashboard/dashboard.css";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Breadcrumb from "../../utils/Breadcrumb";
import { useNavigate, Outlet, useLocation } from "react-router-dom";
import {
  teamLeadListItems,
  projectManagerListItems,
  developerListItems,
  salesListItems,
  hrListItems,
} from "./consts/drawerListItems";
import antino_logo from "../../Assets/Images/antino_logo.png";
import DashboardItem from "./DashboardItem";
import TimesheetQA from "../Chat/TimesheetQA";

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    // padding: `0px ${theme.spacing(3)} 0px ${theme.spacing(3)}`,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),

    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,

        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",

  alignItems: "center",

  padding: theme.spacing(0, 1),

  // necessary for content to be below app bar

  ...theme.mixins.toolbar,

  justifyContent: "flex-end",
}));

export default function Dashboard() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(true);
  const [selected, setSelected] = React.useState("");

  React.useEffect(() => {
    setSelected(pathname);
  }, [pathname]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const userDesignation = localStorage.getItem("designation");

  const shouldAllowDashboardAccess = userDesignation !== "DEVELOPER";

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />

        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
              color: "black",
              background: "white",
            },
          }}
          variant="persistent"
          anchor="left"
          open={open}
        >
          <DrawerHeader
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "8px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flex: "1",
              }}
            >
              <img
                src={antino_logo}
                style={{
                  maxWidth: "110%",
                  maxHeight: "110%",
                  marginLeft: "-10px", // Adjust the margin as needed
                }}
                alt="Antino Logo"
              />
            </div>
            <KeyboardArrowRightIcon
              onClick={handleDrawerClose}
              sx={{ cursor: "pointer" }}
            />
          </DrawerHeader>

          <Divider />

          <List>
            {localStorage.getItem("designation") === "AVP" ||
            localStorage.getItem("designation") === "VP" ||
            localStorage.getItem("designation") === "CEO" ||
            localStorage.getItem("designation") === "COO" ||
            localStorage.getItem("designation") === "CTO"
              ? teamLeadListItems.map((items, index) => (
                  <ListItemButton
                    key={items.id}
                    onClick={() => {
                      navigate(items.route);
                      setSelected(items.route);
                    }}
                    sx={{
                      "&:hover": {
                        background: selected === items?.route && "#E5F3FF",
                      },
                      background: selected === items?.route && "#E5F3FF",
                      borderRight:
                        selected === items?.route
                          ? "3px solid #4E79FA"
                          : "none",
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        color:
                          selected === items?.route
                            ? items.selectedIconColor
                            : items.unselectedIconColor,
                        paddingLeft: "10px",
                      }}
                    >
                      {items.icon}
                    </ListItemIcon>

                    <ListItemText
                      primary={items.label}
                      sx={{
                        color:
                          selected === items?.route ? "#4E79FA" : "#5C5C5C",
                      }}
                    />
                  </ListItemButton>
                ))
              : localStorage.getItem("designation") === "DEVELOPER"
              ? developerListItems.map((items, index) => (
                  <ListItemButton
                    key={items.id}
                    defau
                    sx={{
                      "&:hover": {
                        background: selected === items?.route && "#E5F3FF",
                      },
                      background: selected === items?.route && "#E5F3FF",
                      borderRight:
                        selected === items?.route
                          ? "3px solid #4E79FA"
                          : "none",
                    }}
                    onClick={() => {
                      navigate(items.route);
                      setSelected(items?.route);
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        color:
                          selected === items?.route
                            ? items.selectedIconColor
                            : items.unselectedIconColor,
                        paddingLeft: "10px",
                      }}
                    >
                      {items.icon}
                    </ListItemIcon>

                    <ListItemText
                      primary={items.label}
                      sx={{
                        color:
                          selected === items?.route ? "#4E79FA" : "#5C5C5C",
                      }}
                    />
                  </ListItemButton>
                ))
              : localStorage.getItem("designation") === "SALES"
              ? salesListItems.map((items, index) => (
                  <ListItemButton
                    key={items.id}
                    sx={{
                      "&:hover": {
                        background: selected === items?.route && "#E5F3FF",
                      },
                      background: selected === items?.route && "#E5F3FF",
                      borderRight:
                        selected === items?.route
                          ? "3px solid #4E79FA"
                          : "none",
                    }}
                    onClick={() => {
                      navigate(items.route);
                      setSelected(items?.route);
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        color:
                          selected === items?.route
                            ? items.selectedIconColor
                            : items.unselectedIconColor,
                        paddingLeft: "10px",
                      }}
                    >
                      {items.icon}
                    </ListItemIcon>

                    <ListItemText
                      primary={items.label}
                      sx={{
                        color:
                          selected === items?.route ? "#4E79FA" : "#5C5C5C",
                      }}
                    />
                  </ListItemButton>
                ))
              : localStorage.getItem("designation") === "HR"
              ? hrListItems.map((items, index) => (
                  <ListItemButton
                    key={items.id}
                    sx={{
                      "&:hover": {
                        background: selected === items?.route && "#E5F3FF",
                      },
                      background: selected === items?.route && "#E5F3FF",
                      borderRight:
                        selected === items?.route
                          ? "3px solid #4E79FA"
                          : "none",
                    }}
                    onClick={() => {
                      navigate(items.route);
                      setSelected(items?.route);
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        color:
                          selected === items?.route
                            ? items.selectedIconColor
                            : items.unselectedIconColor,
                        paddingLeft: "10px",
                      }}
                    >
                      {items.icon}
                    </ListItemIcon>

                    <ListItemText
                      primary={items.label}
                      sx={{
                        color:
                          selected === items?.route ? "#4E79FA" : "#5C5C5C",
                      }}
                    />
                  </ListItemButton>
                ))
              : localStorage.getItem("designation") === "BA" ||
                localStorage.getItem("designation") === "CSM" ||
                localStorage.getItem("designation") === "PM"
              ? projectManagerListItems.map((items, index) => (
                  <ListItemButton
                    key={items.id}
                    sx={{
                      "&:hover": {
                        background: selected === items?.route && "#E5F3FF",
                      },
                      background: selected === items?.route && "#E5F3FF",
                      borderRight:
                        selected === items?.route
                          ? "3px solid #4E79FA"
                          : "none",
                    }}
                    onClick={() => {
                      navigate(items.route);
                      setSelected(items?.route);
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        color:
                          selected === items?.route
                            ? items.selectedIconColor
                            : items.unselectedIconColor,
                        paddingLeft: "10px",
                      }}
                    >
                      {items.icon}
                    </ListItemIcon>

                    <ListItemText
                      primary={items.label}
                      sx={{
                        color:
                          selected === items?.route ? "#4E79FA" : "#5C5C5C",
                      }}
                    />
                  </ListItemButton>
                ))
              : null}
          </List>

          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 400,
              position: "absolute",
              bottom: 0,
              display: "flex",
              color: "rgba(128, 128, 128, 0.9)",
              justifyContent: "center",
              width: "100%",
            }}
          >
            Version 3.0
          </Typography>
        </Drawer>

        <Main open={open}>
          <Breadcrumb handleDrawerOpen={handleDrawerOpen} open={open} />

          {shouldAllowDashboardAccess
            ? pathname === "/dashboard" && <DashboardItem />
            : pathname === "/dashboard" && (
                <navigate
                  to={
                    userDesignation === "SALES" || userDesignation === "HR"
                      ? "/dashboard/client"
                      : "/dashboard/timesheet"
                  }
                  replace
                />
              )}
          <div className="pl-[24px] pr-[24px]">
            <Outlet />
          </div>
        </Main>
      </Box>
    </>
  );
}
